import regexPattern from "../utils/regexPattern";

const AuthRules: any = {
  username: {
    required: "Username required",
    minLength: {
      value: 3,
      message: "Username should be between 3 and 24 characters",
    },
    maxLength: {
      value: 24,
      message: "Username should be between 3 and 24 characters",
    },
    pattern: {
      value: regexPattern.LettersAndSpacesAndDashesAndNumbers,
      message:
        "Username should contains only letters, numbers, spaces and dashes",
    },
  },
  password: {
    required: "Password required",
    minLength: {
      value: 6,
      message: "Password should not be less than 6 characters",
    },
    pattern: {
      value: regexPattern.PASSWORD,
      message:
        "Use at least 6 character, one uppercase,at least one lower case letter, one number in password",
    },
  },
  email: {
    required: "Email address is required",
    pattern: {
      value: regexPattern.Email,
      message: "Invalid Email",
    },
    maxLength: {
      value: 320,
      message: "Email address should not exceed 80 characters",
    },
  },
  verification: {
    required: "otp required",
    pattern: {
      value: regexPattern.Numbers,
      message: "Invalid Number",
    },
    minLength: {
      value: 6,
      message: "Verification code must contain 6 digits",
    },
    maxLength: {
      value: 6,
      message: "Verification code must contain 6 digits",
    },
  },

  phone_number: {
    required: "Mobile Number required",
    pattern: {
      value: regexPattern.Numbers,
      message: "Digits only",
    },
  },

  address: {
    required: "Address is required",
    minLength: {
      value: 3,
      message: "Address should be at least 3 characters",
    },
  },

  city: {
    required: "city is required",
    minLength: {
      value: 3,
      message: "city should be at least 3 characters",
    },
    pattern: {
      value: regexPattern.LettersAndSpacesAndDashesAndNumbers,
      message: "city should contains only letters, numbers, spaces and dashes",
    },
  },

  district: {
    required: "district is required",
    minLength: {
      value: 3,
      message: "district should be at least 3 characters",
    },
    pattern: {
      value: regexPattern.LettersAndSpacesAndDashesAndNumbers,
      message:
        "district should contains only letters, numbers, spaces and dashes",
    },
  },
  state: {
    required: "state is required",
    minLength: {
      value: 3,
      message: "state should be at least 3 characters",
    },
    pattern: {
      value: regexPattern.LettersAndSpacesAndDashesAndNumbers,
      message: "state should contains only letters, numbers, spaces and dashes",
    },
  },

  otp: {
    required: "OTP is required",
    pattern: {
      value: regexPattern.Numbers,
      message: "Invalid OTP",
    },
    minLength: {
      value: 6,
      message: "OTP must contain 6 digits",
    },
    maxLength: {
      value: 6,
      message: "OTP must contain 6 digits",
    },
  },

  fleetnumber: {
    required: "required",
    pattern: {
      value: regexPattern.Numbers,
      message: "Invalid Number of fleets ",
    },
  },

  businessname: {
    required: "required",
    pattern: {
      value: regexPattern.LettersAndSpacesAndDashesAndNumbers,
      message:
        "Business Name should contains only letters, numbers, spaces and dashes",
    },
  },

  entityname: {
    required: "required",
    pattern: {
      value: regexPattern.LettersAndSpacesAndDashes,
      message: "Invalid entity name",
    },
  },

  tr_number: {
    required: "TRN number required",
    pattern: {
      value: regexPattern.TRN,
      message: "Invalid TRN number required",
    },

    maxLength: {
      value: 15,
      message: "TRN number should not exceed 15 digits",
    },
  },

  trade_license_number: {
    required: "Trade License Number required",

    maxLength: {
      value: 6,
      message: "Invalid Trade License Number",
    },

    minLength: {
      value: 6,
      message: "Invalid Trade License Number",
    },

    pattern: {
      value: regexPattern.Numbers,
      message: "Invalid Trade License Number",
    },
  },

  license_number: {
    required: "License Number required",

    maxLength: {
      value: 6,
      message: "Invalid  License Number",
    },

    pattern: {
      value: regexPattern.LettersAndNumbers,
      message: "Invalid Trade License Number",
    },
  },

  date: {
    required: " Expiry Date required",
    pattern: {
      value: regexPattern.Date,
      message: "Invalid Expiry Date",
    },
  },
  bank_name: {
    required: "required",
    pattern: {
      value: regexPattern.LettersAndSpaces,
    },
  },
  iban_number: {
    required: "IBAN Number required",

    maxLength: {
      value: 23,
      message: "IBAN Number should not exceed 23 characters ",
    },

    pattern: {
      value: regexPattern.IBAN,
      message: "Invalid IBAN Number",
    },
  },
  account_name: {
    required: "required",
    pattern: {
      // value: regexPattern.LettersAndNumbersAndSpaces,
    },
  },
  engine_number: {
    required: "required",
    pattern: {
      value: regexPattern.ENGINE_NUMBER,
      message: "invalid format",
    },
  },
  registration_number: {
    required: "required",
    // pattern: {
    //   value: regexPattern.REGISTRATION_NUMBER,
    //   message: "",
    // },
  },
  plate_number: {
    // pattern: {
    //   value: regexPattern.REGISTRATION_NUMBER,
    //   message: "",
    // },
  },

  productinfo: {
    required: "Required",
    pattern: {
      value: regexPattern.LettersAndSpaces,
      message: "Invalid",
    },
  },

  productDescription: {
    required: "required",
  },

  productNumber: {
    required: "required",
    pattern: {
      value: regexPattern.PRODUCT_NUMBER,
      message: "digits only",
    },
    validate: {
      startsWithZero: (value: string) => {
        if (value.startsWith("0")) {
          return "starting with 0 is invalid";
        }
        return true;
      },
    },
  },

  productNumberForInsur: {
    pattern: {
      value: regexPattern.Numbers,
      message: "digits only",
    },
  },

  companyAddress: {
    required: "required",
  },
};

export default AuthRules;

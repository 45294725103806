import React from "react";
import MerchantDashboardReviews from "../MerchantDashboardReviews";
import MerchantDashboardCarLocationList from "../MerchantDashboardCarLocationList";
import MerchantDashboardCarUpload from "../MerchantDashboardCarUpload";
import MerchantDashboardMyIncome from "../MerchantDashboardMyIncome";
import MerchantDashboardCarListings from "../MerchantDashboardCarListings";
import MerchantDashboardHireAndCancel from "../MerchantDashboardHireAndCancel";
import "./MerchantDashboardFullComponents.css";

const MerchantDashboardFullComponents = () => {
  return (
    <div className="col-md-12  fullcomponent_height">
      <div className="container-fluid">
        <div className="row g-5 animate-by-scroll">
          <MerchantDashboardCarListings />
          <MerchantDashboardMyIncome />
        </div>
        <div className="row g-5 pt-4 mb-4 animate-by-scroll">
          <MerchantDashboardCarUpload />
          <MerchantDashboardHireAndCancel />
        </div>
        <div className="row g-5 animate-by-scroll">
          <MerchantDashboardCarLocationList />
        </div>
        <div className="row pt-4 animate-by-scroll">
          <MerchantDashboardReviews />
        </div>
      </div>
    </div>
  );
};

export default MerchantDashboardFullComponents;

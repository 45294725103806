// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-vi {
  /* width: 51.7rem; */
  /* height: 66.7vh; */

  /* radius: 6px; */
  border: 1px;
  background: linear-gradient(0deg, var(--radial-background-theme-1)),
    linear-gradient(0deg, var(--radial-background-theme-5));
  border: 1px solid var(--border-color-theme-12);
}

.vehicle_inspection_text {
  font-family: "PT Sans Narrow";
  font-size: 25px;
  color: var(--font-color-theme-2);
}
.radio_button_text {
  color: var(--font-color-primary);
  font-size: 13px;
  font-family: "Poppins";
}
.width-radio-label {
  width: 50px;
}
@media screen and (max-width: 768px) {
  .radio_button_text {
    width: 100%;
  }
}
.descripton_vi {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--font-colour-theme-16);
}

.button-footer-text {
  font-family: PT Sans Narrow;
  color: var(--font-color-theme-18);
  width: Fixed (300px);
  border-radius: 8px;
  background: linear-gradient(180deg, var(--button-background-radial));
  font-size: 12px;
  font-weight: 700;
  line-height: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomerDashBoard/VehicleInspection/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;;EAEpB,iBAAiB;EACjB,WAAW;EACX;2DACyD;EACzD,8CAA8C;AAChD;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,gCAAgC;AAClC;AACA;EACE,gCAAgC;EAChC,eAAe;EACf,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,2BAA2B;EAC3B,iCAAiC;EACjC,oBAAoB;EACpB,kBAAkB;EAClB,oEAAoE;EACpE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".main-vi {\n  /* width: 51.7rem; */\n  /* height: 66.7vh; */\n\n  /* radius: 6px; */\n  border: 1px;\n  background: linear-gradient(0deg, var(--radial-background-theme-1)),\n    linear-gradient(0deg, var(--radial-background-theme-5));\n  border: 1px solid var(--border-color-theme-12);\n}\n\n.vehicle_inspection_text {\n  font-family: \"PT Sans Narrow\";\n  font-size: 25px;\n  color: var(--font-color-theme-2);\n}\n.radio_button_text {\n  color: var(--font-color-primary);\n  font-size: 13px;\n  font-family: \"Poppins\";\n}\n.width-radio-label {\n  width: 50px;\n}\n@media screen and (max-width: 768px) {\n  .radio_button_text {\n    width: 100%;\n  }\n}\n.descripton_vi {\n  font-family: Poppins;\n  font-size: 10px;\n  font-weight: 500;\n  line-height: 18px;\n  letter-spacing: 0.01em;\n  color: var(--font-colour-theme-16);\n}\n\n.button-footer-text {\n  font-family: PT Sans Narrow;\n  color: var(--font-color-theme-18);\n  width: Fixed (300px);\n  border-radius: 8px;\n  background: linear-gradient(180deg, var(--button-background-radial));\n  font-size: 12px;\n  font-weight: 700;\n  line-height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";

import CustomerInfo from "../../../components/LeftComponent";
import "./SignUpScreen.css";
import CustomerSignUpForm from "../../../components/RightComponent";

const SignUpScreen: React.FC = () => {
  return (
    <div className="box">
      <div className="container">
        <div className="left-half">
          <CustomerInfo />
        </div>
        <div className="right-half">
          <CustomerSignUpForm />
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;

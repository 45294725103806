import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { GarageNavBar, GarageSideBar } from "../../../components";
import "./style.css";

const GarageDashBoard = () => {
  const [currentPage, setCurrentPage] = useState("dashboard");

  return (
    <div className="col-md-12 col-xl-12 col-sm-12 container-fluid dashboardcontainer  ">
      <div className="row flex-nowrap ">
        <GarageSideBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <div className="col dashboardright garage-bg d-flex justify-content-center ">
          <div className="dashboardright-width">
            <GarageNavBar currentPage={currentPage} />
            <div className="dashboardright-scroll">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GarageDashBoard;

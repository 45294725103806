// WelcomePage.tsx

import React from "react";
import { car_home } from "../assets/images";
import "./welcome.css";
import { useNavigate } from "react-router-dom";

const Welcome: React.FC<any> = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("./signin");
  };
  return (
    <div className="welcome-page">
      <div className="welcome-message">
        <h1>Welcome</h1>
        <p>Explore the Cars</p>
      </div>
      <div className="image-container">
        <img src={car_home} className="car-img" alt="image"></img>
      </div>
    </div>
  );
};

export default Welcome;

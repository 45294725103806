import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  dashboard_logout,
  dashboard_icon,
  dashboard_saved_details_icon,
  dashboard_booking_calendar,
  sidebar_collapse_icon,
  dashboard_logo_kirkos_collapse,
  dashboard_insurance_member_icon,
  kirkos_logo_white_yellow,
} from "../../../assets/images";
import { log_out } from "../../../services/axios.instance";
import { clearBookingValues } from "../../../Redux/BookingData/bookingDataSlice";
import { useDispatch } from "react-redux";

const CustomerDashboardSidebar = () => {
  const [selectedNav, setSelectedNav] = useState("dashboard");
  const [selectedNavToggle, setSelectedNavToggle] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setSelectedNavToggle(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSetActiveNavigation = (nav: string) => {
    setSelectedNav(nav);
  };
  const dispatch = useDispatch();
  const handleSetActiveNavigationLogout = (nav: string) => {
    log_out();
    dispatch(clearBookingValues());
  };

  const handleSetNavToggle = () => {
    setSelectedNavToggle(!selectedNavToggle);
  };

  const dataNavTop = [
    {
      label: "Dashboard",
      icon: dashboard_icon,
      value: "dashboard",
      navlink: "/customer/myaccount",
    },
    {
      label: "Saved Deals",
      icon: dashboard_saved_details_icon,
      value: "saveddetails",
      navlink: "/customer/rentdeals",
    },
    {
      label: (
        <>
          <span className="insurance-member-style-side-bar dashboard-poppins-font-style moving-bg">
            Insurance Member
          </span>
        </>
      ),
      icon: dashboard_insurance_member_icon,
      value: "insurencemember",
      navlink: "/customer/bookingprocess",
    },
    {
      label: "Booking Calendar",
      icon: dashboard_booking_calendar,
      value: "BookingCalender",
      navlink: "/customer/bookingcalender",
    },
  ];
  // const dataNavBottom = [
  //   {
  //     label: "Money in Wallet",
  //     icon: dashboard_money_in_wallet,
  //     value: "MoneyinWallet",
  //     navlink: "/Customer/MyAccount",
  //   },
  //   {
  //     label: "Money Transaction History ",
  //     icon: dashboard_money_history_icon,
  //     value: "MoneyTransactionHistory ",
  //     navlink: "/Customer/MyAccount",
  //   },
  //   {
  //     label: "Upgrade to Premium",
  //     icon: dashboard_upgrade,
  //     value: "UpgradetoPremium",
  //     navlink: "/Customer/MyAccount",
  //   },
  // ];

  return (
    <div
      className={`px-0 bg-black align-items-center sidebar-min-width ${
        selectedNavToggle
          ? "col-1"
          : "col-auto col-md-3 col-xl-2 responsive-sidebar-ab"
      }`}
    >
      {/* <div className="row">
            <div className="col-md-12  text-end toggle-sidebar-btn-div">
                <button className="toggle-sidebar-btn" onClick={handleSetNavToggle}>
                    <img src={sidebar_collapse_icon} alt="" className="toggle-sidebar-btn-icon" />
                </button>
            </div>
        </div> */}
      <div className="sidebar-dash d-flex flex-column align-items-center  px-3 pt-2 text-white min-vh-100 ">
        <div className="  text-end toggle-sidebar-btn-div">
          <button className="toggle-sidebar-btn" onClick={handleSetNavToggle}>
            <img
              src={sidebar_collapse_icon}
              alt=""
              className="toggle-sidebar-btn-icon"
            />
          </button>
        </div>
        <div className="text-center mb-3 mt-2 div-logo-sidebar">
          <Link
            to="#"
            className={` align-items-center ${
              selectedNavToggle
                ? "sidebar-kirkos-icon-toggle"
                : "sidebar-kirkos-icon"
            }`}
          >
            <img
              // src={dashboard_logo_kirkos}
              src={
                selectedNavToggle
                  ? dashboard_logo_kirkos_collapse
                  : kirkos_logo_white_yellow
              }
              width="80%"
              height="80%"
              alt="Logo"
              className="img-fluid ps-2 img-logo-dashboard"
            />
          </Link>
        </div>
        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style pt-2"
          id="side-bar-menu"
        >
          {dataNavTop.map((item) => (
            <li
              className={`nav-item sidebar-list ${
                item.value === "insurencemember"
                  ? " insurencemember-bounce"
                  : ""
              }`}
            >
              <div className="dashboard-side-bar-link-div">
                <Link
                  to={item.navlink}
                  onClick={() => handleSetActiveNavigation(item.value)}
                  className={`nav-link align-middle px-1 dashboard-side-bar-link   ${
                    selectedNav === item.value
                      ? "active-class"
                      : item.value === "insurencemember"
                      ? ""
                      : ""
                  }`}
                >
                  <i className="">
                    <img
                      alt="iconImage"
                      src={item.icon}
                      width="15"
                      height="15"
                    />
                  </i>
                  <span
                    className={`ms-1  label-sidebar label-sidebar-customer ${
                      selectedNavToggle ? "d-none-custom" : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </div>
            </li>
          ))}
        </ul>

        {/* <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style"
          id="menu1"
        >
          <div className=" align-middle px-0 reporthead">
            <i className=""></i>
            <span
              className={`ms-1  ${selectedNavToggle ? "d-none-custom" : ""}`}
            >
              Reports
            </span>
          </div>
          {dataNavBottom.map((item) => (
            <li className="nav-item sidebar-list ">
              <div className="dashboard-side-bar-link-div">
                <Link
                  to={item.navlink}
                  onClick={() => handleSetActiveNavigation(item.value)}
                  className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                    selectedNav === item.value ? "active-class" : ""
                  }`}
                >
                  <i className="">
                    <img src={item.icon} width="15" height="15" />
                  </i>
                  <span
                    className={`ms-1  label-sidebar label-sidebar-customer ${
                      selectedNavToggle ? "d-none-custom" : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </div>
            </li>
          ))}
        </ul> */}
        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style"
          id="menu2"
        >
          <li className="nav-item p-1 sidebar-list">
            <div className="dashboard-side-bar-link-div">
              <Link
                to="/"
                onClick={() => handleSetActiveNavigationLogout("logout")}
                className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                  selectedNav === "logout" ? "active-class" : ""
                }`}
              >
                <i className="">
                  <img
                    alt="dashboard_logout"
                    src={dashboard_logout}
                    width="15"
                    height="15"
                  />
                </i>
                <span
                  className={`ms-1  label-sidebar label-sidebar-customer ${
                    selectedNavToggle ? "d-none-custom" : ""
                  }`}
                >
                  Logout
                </span>
              </Link>
            </div>
          </li>
          {/* <li className="">
            <input type="checkbox" id="toggle" className="toggleCheckbox" />
            <label htmlFor="toggle" className="toggleContainer">
              <div>
                {" "}
                <img src={light_mode_icon} width="30" height="30" />
              </div>
              <div>
                {" "}
                <img
                  src={dark_mode_icon}
                  width="23"
                  height="23"
                  style={{ filter: "brightness(1) invert(0)" }}
                  className="pt-2"
                />
              </div>
            </label>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default CustomerDashboardSidebar;

import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
} from "../../../assets/images";
import { CustomInput, MerchantOnboardLeftSide } from "../../../components";
import { FieldValues, Form, useForm } from "react-hook-form";
import rules from "../../../rules";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import {
  LoginData,
  MerchantNewPasswordData,
  RegistrationData,
  RegistrationMerchantData,
} from "../../../types";
import { SuccessModal } from "../../../components";
import { ErrorModal } from "../../../components";

const GarageOnboardNewPassword = () => {
  const [password, setPassword] = useState<string>("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const { state } = useLocation();
  const email = state && state.email;
  console.log("email", email);
  const transactionId = sessionStorage.getItem("transactionId");

  const navigate = useNavigate();

  const {
    control,

    formState: { isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: "onChange" });

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setError(null);
    const formData = getValues();
    console.log("working", formData.createpassword);

    const userData: MerchantNewPasswordData = {
      email: email ?? "",
      new_password: formData.createpassword,
      user_type: "GARAGE_USER",
      transaction_id: transactionId,
      otp: "111111",
      event: "forgot_password",
    };

    handleRegistration.mutate(userData);
    console.log("success", userData);
    setIsFormSubmitted(true);
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: MerchantNewPasswordData) =>
      axiosInstance.post(API_URL.FORGOT_PASSWORD, userData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      setShowSuccessModal(true);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} control={control}>
        <div className=" pt-5">
          <div className="row pt-4 ">
            <div className="col-md-12 text-start">
              <img
                src={kirkos_merchant_profile}
                className="icon-img img-fluid mb-3"
                alt="Icon"
              />
              <span className=" text-light merchant_newpass_update_text">
                &nbsp;Update Password
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="merchant_passtext fw-light  ">
                Enter and Create new password. Use at least 6 characters One
                uppercase,at least one lower case letter, one number in password
              </div>
            </div>
          </div>

          <div className=" col-md-12 newpass_box_one text-start">
            <label className="headingStyle_newpass">
              Create a New Password
              <span className="merchantStarx">*</span>
            </label>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <CustomInput
                  inputName="createpassword"
                  inputType={showPassword1 ? "text" : "password"}
                  className="merchant_login_input_field "
                  placeholder="xxxxxx"
                  editable={true}
                  onInputChange={(value: string) => setPassword(value)}
                  control={control}
                  rules={rules.AuthRules.password}
                />
                <span
                  className="fs-5 password_eye"
                  onClick={togglePasswordVisibility1}
                >
                  {showPassword1 ? <EyeSlash /> : <Eye />}
                </span>
              </div>
            </div>
          </div>
          <div className="row  newpass_box_two">
            <div className="col-md-12 text-start">
              <label className="headingStyle_newpass">
                Confirm New Password
                <span className="merchantStarx">*</span>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <CustomInput
                  inputName="confirmpassword"
                  inputType={showPassword2 ? "text" : "password"}
                  className="merchant_login_input_field"
                  placeholder="xxxxxx"
                  editable={true}
                  onInputChange={(value: string) => setConfirmPassword(value)}
                  control={control}
                  // rules={rules.AuthRules.password}
                />
                <span
                  className="fs-5 password_eye"
                  onClick={togglePasswordVisibility2}
                >
                  {showPassword2 ? <EyeSlash /> : <Eye />}
                </span>
              </div>
              {error && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    fontSize: "10px",
                    textAlign: "end",
                  }}
                >
                  {error}
                </p>
              )}
            </div>
          </div>
          <div className="col-12 pt-5 text-start">
            <button
              className="btn all_button_style text-black"
              disabled={!isValid}
              type="submit"
            >
              CONTINUE
            </button>
          </div>
        </div>
        <SuccessModal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          onNav={() => {
            navigate("/garageonboard");
          }}
          message="Success"
        />
        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          message="Password not valid"
        />
      </Form>
    </>
  );
};

export default GarageOnboardNewPassword;

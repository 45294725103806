import iconcus from "../assets/images/icon_normal.svg";
import { useState, useRef } from "react";
import "./PageNavigator.css";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "../services/axios.instance";
import { API_URL } from "../services/apiConfig";
import { UserData, VerifyOtp } from "../types";
import CustomerSignUp from "./CustomerSignUp";
import "./RightComponentStyle.css";
import { icon_normal } from "../assets/images";
import RegistrationSuccessComponent from "./RegistrationSuccessComponent";
import { useForm } from "react-hook-form";
import RegexPattern from "../utils/regexPattern";

import rules from "../rules";

import CustomInput from "./CustomInput";
import { Console } from "console";

const CustomerSignUpForm = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  const [isClicked, setIsClicked] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [showOtpscreen, setShowOtpscreen] = useState<boolean>(false);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
  const [showSignup, setShowSignup] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<number | undefined>(0);
  const [otp, setOtp] = useState<any>(["", "", "", ""]);
  const [otpRefs] = useState<any>([useRef(), useRef(), useRef(), useRef()]);
  const [phoneNum, setPhoneNum] = useState<any>("");

  const handleClick = (data: UserData) => {
    console.log("kkhjfdsghjkll", data);
    setPhoneNum(data.phoneNo);
    console.log("jnbdfgyugfdhv", phoneNum);

    const userData = {
      phoneNo: data.phoneNo,
      userType: "CONSUMER",
      event: "register",
    };
    mutation.mutate(userData);
    setShowOtpscreen(true);
  };

  const mutation = useMutation({
    mutationFn: (userData: UserData) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.transactionId) {
        setTransactionId(successResponse?.data?.transactionId);
        alert(successResponse?.data?.message);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
    },
  });

  const handleInputChange = (e: any) => {
    setPhoneNumber(e.target.value);
  };

  const handleOtpChange = (index: number, e: any) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otpRefs.length - 1) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleOtpVerify = () => {
    const payLoad: VerifyOtp = {
      phoneNo: phoneNum,
      transactionId,
      otp: "111111",
      userType: "CONSUMER",
      event: "register",
    };
    verifyOtp.mutate(payLoad);
  };
  const verifyOtp = useMutation({
    mutationFn: (userData: VerifyOtp) =>
      axiosInstance.post(API_URL.VERIFY_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        alert(successResponse?.data?.message);
        setIsOtpVerified(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
    },
  });

  return (
    <div className="containerStyle">
      {isOtpVerified ? (
        <CustomerSignUp phoneNo={phoneNum} />
      ) : showOtpscreen ? (
        <div className="whiteHalf">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              marginTop: "60px",
            }}
          >
            <img
              src={iconcus}
              style={{
                width: "51px",
                minHeight: "10%",
                marginRight: "15px",
              }}
            />
            <p className="signUpTextStyle">Customer Sign Up</p>
          </div>
          <label
            style={{
              color: "#F4B550",
            }}
          >
            We will send you one time password on this phone number to +971{" "}
            {phoneNum}
          </label>
          <div className="otpContainerStyle">
            <input
              type="text"
              className="otpBoxStyle"
              placeholder="0"
              maxLength={1}
              onChange={(e) => handleOtpChange(0, e)}
              ref={otpRefs[0]}
            />
            <input
              type="text"
              className="otpBoxStyle"
              placeholder="0"
              maxLength={1}
              onChange={(e) => handleOtpChange(1, e)}
              ref={otpRefs[1]}
            />
            <input
              type="text"
              className="otpBoxStyle"
              placeholder="0"
              maxLength={1}
              onChange={(e) => handleOtpChange(2, e)}
              ref={otpRefs[2]}
            />
            <input
              type="text"
              className="otpBoxStyle"
              placeholder="0"
              maxLength={1}
              onChange={(e) => handleOtpChange(3, e)}
              ref={otpRefs[3]}
            />
          </div>

          <button onClick={handleOtpVerify}  disabled={!isValid} className="buttonStylev">
            VERIFY
          </button>

          <p style={{ color: "grey" }}>
            You will receive OTP to your mobile soon via SMS to verify your
            phone number and will be automatically redirected to the
            registration app
          </p>
        </div>
      ) : (
        <>
          {/* <RegistrationSuccessComponent /> */}
          <div className="whiteHalf">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                marginTop: "60px",
              }}
            >
              <img
                src={icon_normal}
                style={{
                  width: "51px",
                  minHeight: "10%",
                  marginRight: "15px",
                }}
              />
              <p className="signUpTextStyle">Customer Sign Up</p>
            </div>
            <p
              style={{
                color: "#F4B550",
              }}
            >
              We will send you a one-time password to this phone number
            </p>
            <p className="headingStyle">Your Phone Number</p>
            <div className="inputContainerStyle">
              <span className="countryCodeStyle">+971</span>
              <CustomInput
                inputName="phoneNo"
                inputType="text"
                className="inputStyle"
                placeholder="+970....."
                editable={true}
                onInputChange={(value: string) => {}}
                control={control}
                maxLength={9}
                rules={rules.AuthRules.phone_number}
              />
            </div>

            <button className="buttonStyle" onClick={handleSubmit(handleClick)}  disabled={!isValid}>
              CONTINUE
            </button>
            <p style={{ color: "grey" }}>
              You will receive OTP to your mobile soon via SMS to verify your
              phone number and will be automatically redirected to the
              registration app
            </p>
          </div>
        </>
      )}

      <div className="pageNavigator">
        <div className="pageCircle initialActive"></div>
        <div className="pageLine"></div>
        <div
          className={`pageCircle ${showOtpscreen ? "initialActive" : ""}`}
        ></div>
        <div className="pageLine"></div>
        <div className="pageCircle"></div>
        <div className="pageLine"></div>
        <div className="pageCircle"></div>
      </div>
    </div>
  );
};

export default CustomerSignUpForm;

import React from "react";
//import "../SuccessmodalStyle.css";
//import "./confirmationModal.css";
import { thumb_up } from "../../assets/images";

interface QuickSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const QuickSearchModal: React.FC<QuickSearchModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
}) => {
  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div className="modal-dialog p-2 mt-5" role="document">
        <div className="modal-content custom-modal2">
          <div className="cross d-flex flex-row-reverse">
            <button
              type="button"
              className="close-confirmation btn rounded-circle"
              onClick={onClose}
            >
              <span>&times;</span>
            </button>
          </div>
          {/* <div>
            <img
              src={thumb_up}
              width="90"
              height="90"
              alt="Logo"
              className="thumb"
            />
          </div> */}
          <div className="modal-header d-flex justify-content-center align-items-center p-0">
            <h2 className="modal-title text-light">LOGIN NOW!!!</h2>
          </div>
          <div className="modal-body">
            <p
              className="text-message"
              dangerouslySetInnerHTML={{ __html: message }}
            ></p>
          </div>
          <div className="modal-footer d-flex justify-content-center p-0 mb-2 text-center">
            <button
              type="button"
              className="btn-lg w-25 ok-button "
              onClick={onConfirm}
            >
              Login
            </button>

            {/* <button
              type="button"
              className="btn-lg w-25 cancel-button"
              onClick={onClose}
            >
              Cancel
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickSearchModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/harmonyos-sans);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant_passtext_newpass {
  font-family: Poppins, sans-serif;
  color: var(--font-color-theme-1);
  line-height: normal;
  letter-spacing: 1px;
  text-align: left;
}

.merchant_newpass_update_text {
  font-size: 32px;
  font-family: HarmonyOS Sans, sans-serif;
}

.newpass_box_one {
  margin-top: 40px;
}
.newpass_box_two {
  margin-top: 40px;
}

.headingStyle_newpass {
  font-family: Poppins, sans-serif;
}

.merchantButtonStyle_newpass {
  background: linear-gradient(var(--button-background-radial));
  color: var(--font-color-secondary);
  border: none;
  padding: 3px 14px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-family: HarmonyOS Sans, sans-serif;
  transition: background 0.3s ease;
}

.merchantButtonStyle_newpass:hover {
  background: linear-gradient(var(--button-background-hover-radial));
}

.merchantinputStylex_newpass {
  border: none;
  outline: none;
  width: 100%;
  color: var(--font-color-primary);
  border-bottom: 1px solid var(--border-color-theme-6);
  background: transparent;
}

@media (max-width: 769px) {
  .merchant_newpass_update_text {
    font-size: 35px;
  }

  .merchant_passtext_newpass {
    font-size: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Merchant/MerchantNewpassword/Merchant_Newpassword.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,gCAAgC;EAChC,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAGA;EACE,eAAe;EACf,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,4DAA4D;EAC5D,kCAAkC;EAClC,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,uCAAuC;EACvC,gCAAgC;AAClC;;AAEA;EACE,kEAAkE;AACpE;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,WAAW;EACX,gCAAgC;EAChC,oDAAoD;EACpD,uBAAuB;AACzB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".merchant_passtext_newpass {\n  font-family: Poppins, sans-serif;\n  color: var(--font-color-theme-1);\n  line-height: normal;\n  letter-spacing: 1px;\n  text-align: left;\n}\n@import url(\"https://fonts.cdnfonts.com/css/harmonyos-sans\");\n\n.merchant_newpass_update_text {\n  font-size: 32px;\n  font-family: HarmonyOS Sans, sans-serif;\n}\n\n.newpass_box_one {\n  margin-top: 40px;\n}\n.newpass_box_two {\n  margin-top: 40px;\n}\n\n.headingStyle_newpass {\n  font-family: Poppins, sans-serif;\n}\n\n.merchantButtonStyle_newpass {\n  background: linear-gradient(var(--button-background-radial));\n  color: var(--font-color-secondary);\n  border: none;\n  padding: 3px 14px;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: 700;\n  font-family: HarmonyOS Sans, sans-serif;\n  transition: background 0.3s ease;\n}\n\n.merchantButtonStyle_newpass:hover {\n  background: linear-gradient(var(--button-background-hover-radial));\n}\n\n.merchantinputStylex_newpass {\n  border: none;\n  outline: none;\n  width: 100%;\n  color: var(--font-color-primary);\n  border-bottom: 1px solid var(--border-color-theme-6);\n  background: transparent;\n}\n\n@media (max-width: 769px) {\n  .merchant_newpass_update_text {\n    font-size: 35px;\n  }\n\n  .merchant_passtext_newpass {\n    font-size: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

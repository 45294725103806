import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Trash } from "react-bootstrap-icons";

import {
  kirkos_merchant_profile,
  file_browse_icon,
} from "../../../assets/images";
import {
  CustomInput,
  SuccessModal,
  ErrorModal,
  MerchantOnboardLeftSide,
} from "../../../components";

import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { useForm, FieldValues } from "react-hook-form";
import rules from "../../../rules";
import { CustomCheckBox } from "../../../components";
import "./styles.css";
import { useQuery, useMutation } from "@tanstack/react-query";
import Select, { components, OptionProps } from "react-select";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useNavigate } from "react-router-dom";
import { CustomRadio } from "../../../components";
import { Bank, KycDetails } from "../../../types";
import { useDropzone } from "react-dropzone";

const KYCdetails = () => {
  const [tradeLicenseNumber, setTradeLicenseNumber] = useState<string>("");
  const [ibanNumber, setIbanNumber] = useState<string>("");

  const [uploadedFile, setUploadedFile] = useState<string[]>([]);
  const [kycFile, setKycFile] = useState<string[]>([]);
  const [selectedBank, setSelectedBank] = useState<string>("");

  const [selectedInsuranceCompanyIds, setSelectedInsuranceCompanyIds] =
    useState<number[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isContractListed, setIsContractListed] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [invalidIban, setInvalidIban] = useState<boolean>(false);
  const [invalidTrn, setInvalidTrn] = useState<boolean>(false);
  const [imageTouched, setImageTouched] = useState(false);
  const [stateTouched, setStateTouched] = useState(false);

  const navigate = useNavigate();
  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    getValues,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (enteredKYCData) {
      console.log("🚀 ~ useEffect ~ parsedData:", parsedData);

      setTradeLicenseNumber(parsedData.trn_number || "");
      setValue("bankName", parsedData.bank_name || "");
      setValue("ibanNumber", parsedData.iban_number || "");
      setValue("accountName", parsedData.account_name || "");
      setIsContractListed(parsedData.is_contract_listed || false);
      setSelectedInsuranceCompanyIds(parsedData.listed_company_id || []);
      setSelectedOption(parsedData.is_contract_listed ? "Yes" : "No");
    }
  }, []);

  const enteredKYCData: string = localStorage.getItem(
    "enteredKYCData"
  ) as string;
  const parsedData = JSON.parse(enteredKYCData);
  const handleSubmit = () => {
    trigger();
    setImageTouched(true);
    setStateTouched(true);
    if (
      !tradeLicenseNumber ||
      !ibanNumber ||
      !isValid ||
      invalidTrn ||
      invalidIban ||
      selectedImage.length == 0 ||
      !selectedBank
    ) {
      return;
    }
    let { ibanNumber: iban_number, accountName: account_name }: FieldValues =
      getValues();
    const userData = {
      trn_number: tradeLicenseNumber,
      bank_name: selectedBank,
      iban_number: ibanNumber,
      account_name,
      is_contract_listed: isContractListed,
      listed_company_id: isContractListed
        ? selectedInsuranceCompanyIds || []
        : [],
    };
    localStorage.setItem("enteredKYCData", JSON.stringify(userData));

    const imagesKycData = selectedImage.map((imageName, index) => ({
      docType: imageName,
      docId: uploadedFile[index],
    }));
    localStorage.setItem("imagesKycData", JSON.stringify(imagesKycData));
    handleRegistration.mutate({
      kyc_and_bank_details: userData,
      customerDocs: imagesKycData,
      last_level: 999,
    });
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: KycDetails) =>
      axiosInstance.put(API_URL.KYC_DETAILS_MERCHANT, userData),
    onSuccess: (successResponse) => {
      if (successResponse.status && successResponse?.data?.message) {
        localStorage.clear();
        sessionStorage.clear();
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles);
    },
  });

  const retrievedImagesDataString = localStorage.getItem("imagesKycData");
  const retrievedImagesData = retrievedImagesDataString
    ? JSON.parse(retrievedImagesDataString)
    : null;

  useEffect(() => {
    const retrievedImagesDataString = localStorage.getItem("imagesKycData");
    const retrievedImagesData = retrievedImagesDataString
      ? JSON.parse(retrievedImagesDataString)
      : null;
    if (retrievedImagesData) {
      const docTypes = retrievedImagesData.map(
        (imageKycData: { docType: any }) => imageKycData.docType
      );
      const docIds = retrievedImagesData.map(
        (imageKycData: { docId: any }) => imageKycData.docId
      );
      setSelectedImage(docTypes);
      setUploadedFile(docIds);
    }
  }, []);

  const handleInputChange = (e: { target: { value: any } }) => {
    const value = e.target.value;

    const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, "");

    const ibanRegex = /^(AE|OM)[0-9]{21}$/;
    setInvalidIban(!ibanRegex.test(cleanedValue));

    let formattedIBAN = "";
    for (let i = 0; i < cleanedValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedIBAN += " ";
      }
      formattedIBAN += cleanedValue.charAt(i);
    }

    setIbanNumber(formattedIBAN);
  };

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value;

    const cleanedValue = value.replace(/[^0-9]/g, "");

    const trnRegex = /^100[0-9]{12}$/;
    setInvalidTrn(!trnRegex.test(cleanedValue));

    let formattedTRN = "";
    for (let i = 0; i < cleanedValue.length; i++) {
      if (i === 3) {
        formattedTRN += " ";
      } else if (i > 3 && (i - 3) % 4 === 0) {
        formattedTRN += " ";
      }
      formattedTRN += cleanedValue.charAt(i);
    }
    setTradeLicenseNumber(formattedTRN);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileInput = (event: any) => {
    event.preventDefault();
    fileInputRef?.current?.click();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      openFileInput(event);
    }
  };

  const [insuranceCompanyOptions, setInsuranceCompanyOptions] = useState<
    { value: number; label: string }[]
  >([]);
  type Company = {
    id: number;
    company: string;
  };
  const [selectedOption, setSelectedOption] = useState<string>("");

  const { mutate: fetchInsuranceCompanies } = useMutation({
    mutationFn: async () => {
      const response = await axiosInstance.get(
        API_URL.INSURANCE_COMPANY_LIST_MERCHANT
      );
      return response.data;
    },
    onSuccess: (successResponse) => {
      const options = successResponse.map((company: Company) => ({
        value: company.id,
        label: company.company,
      }));
      setInsuranceCompanyOptions(options);
    },
    onError: (error: any) => {
      console.error("Error fetching insurance companies:", error);
    },
  });

  useEffect(() => {
    if (selectedOption === "Yes") {
      fetchInsuranceCompanies();
    }
  }, [selectedOption]);

  interface CustomOptionProps extends OptionProps<any> {
    checked: boolean;
  }
  const [selectedInsuranceCompanies, setSelectedInsuranceCompanies] = useState<
    ((typeof insuranceCompanyOptions)[number] | null)[]
  >([]);

  const handleRadioChange = (value: string) => {
    setSelectedOption(value);

    setIsContractListed(value === "Yes");

    if (value === "No") {
      setSelectedInsuranceCompanyIds([]);
    }
  };

  const CheckboxOption: React.FC<OptionProps<any, boolean>> = ({
    children,
    isSelected,
    ...props
  }) => (
    <components.Option isSelected={isSelected} {...props}>
      <div className="d-flex">
        <CustomCheckBox
          isChecked={isSelected}
          onChange={() => {
            setIsContractListed(!isContractListed);

            const optionValue = props.data.value;

            if (isSelected) {
              setSelectedInsuranceCompanyIds((prevIds) => [
                ...prevIds,
                optionValue,
              ]);
            } else {
              setSelectedInsuranceCompanyIds((prevIds) =>
                prevIds.filter((id) => id !== optionValue)
              );
            }
          }}
        />
        <label className="form-label check-label-kyc">{children}</label>
      </div>
    </components.Option>
  );

  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const { mutate } = useMutation({
    mutationFn: (fileList: FileList) => {
      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("document", file);
      });
      return axiosInstance.post(API_URL.DOC_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onSuccess: (response) => {
      const uuid = response?.data?.uuid || null;
      setUploadedFile((prevUUIDs) => [...prevUUIDs, uuid]);
      setIsSuccess(true);
      toast.success("Document uploaded successfully!", {
        position: "top-right",
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
    onError: (error) => {
      console.error("Error uploading images", error);
      setIsSuccess(false);
      toast.error("Error uploading document. Please try again.", {
        position: "top-right",
      });
    },
  });

  const handleFileChange = (acceptedFiles: File[]) => {
    const updatedImages = [...selectedImage];
    const updatedUUIDs = [...uploadedFile];
    const selectedFileNames = acceptedFiles.map((file) => file.name);

    setKycFile((prevFiles) => [...prevFiles, ...selectedFileNames]);
    acceptedFiles.forEach((file) => {
      const docType = "KYC_DETAILS";
      console.log("docType", docType);
      updatedImages.push(docType || "");
    });

    setSelectedImage(updatedImages);

    const dataTransfer = new DataTransfer();
    acceptedFiles.forEach((file) => {
      dataTransfer.items.add(file);
    });

    mutate(dataTransfer.files);
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = [...selectedImage];
    const updatedUUIDs = [...uploadedFile];
    const updatedFiles = [...kycFile];

    updatedImages.splice(index, 1);
    updatedUUIDs.splice(index, 1);
    updatedFiles.splice(index, 1);

    setSelectedImage(updatedImages);
    setUploadedFile(updatedUUIDs);
    setKycFile(updatedFiles);
  };

  const { data: banksData } = useQuery<Bank[], Error>({
    queryKey: ["banks"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.BANK);
      return response.data;
    },
  });

  const handleBankChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBank(event.target.value);
  };

  return (
    <>
      <div className="container-fluid bg-black custom_container-style">
        <div className="row rounded onboard-garage-main ">
          <div className="col-md-6 custom-yellow-bg">
            <MerchantOnboardLeftSide />
          </div>

          <div className="col-md-6 custom-grey-bg text-light  text-light msignup-div-right">
            <div className="progressbar-custom ms-3">
              <MerchantProgressbar currentStage={6} />
            </div>
            <div className="row text-start ms-2 mt-5">
              <div className="row">
                <div className="col-md-12">
                  <img
                    src={kirkos_merchant_profile}
                    className="icon-img img-fluid mb-3 "
                    alt="Icon"
                  />
                  <span className="text-light custom-font-signup">
                    &nbsp;KYC Details
                  </span>
                </div>

                <div className="row">
                  <div className="col-md-10">
                    <p className="merchant_subtext-sign-kyc mt-1 text-start ">
                      You can enter your KYC details to setup your dashboard and
                      for verification as well{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 onboard-inner-div-scroll">
                      <div className="col-md-12">
                        <label className="form-label heading-Style-in">
                          TRN Number
                          <span className="merchantStar">*</span>
                        </label>
                        <div className="col-md-12 me-1">
                          <input
                            id="tradeLicenseNumber"
                            type="text"
                            className="merchant-input-Style "
                            placeholder="Eg: 100-xxxx-xxxx-xxxx"
                            value={tradeLicenseNumber}
                            contentEditable="true"
                            maxLength={18}
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </div>
                        {stateTouched && !tradeLicenseNumber && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}

                        {invalidTrn && (
                          <p className="iban_validation text-end mt-1">
                            Invalid TRN number.
                          </p>
                        )}
                      </div>
                      {/* ----------------------------------------------------------------------------- */}
                      <div className="dashed-box mt-5   ps-0 pb-1 pe-o me-0 border-dashed drag-dash-box  text-center">
                        <div className=" " {...getRootProps()}>
                          <div className="dashed-pattern p-4">
                            <img
                              src={file_browse_icon}
                              alt="file_browse_icon"
                              className="img-fluid mb-2"
                            />
                            <p className="text-light mb-2 drag_and_drop_font">
                              {isDragActive
                                ? "Drop the files here"
                                : "Drag & Drop to Upload Images / Files"}
                              <span className="merchantStar">*</span>
                            </p>
                            <input
                              {...getInputProps()}
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              multiple
                            />
                          </div>
                        </div>
                        <div>
                          <p className="text-light mb-2">OR</p>
                          <button
                            className="btn btn-custom"
                            onClick={openFileInput}
                          >
                            Browse Documents
                          </button>
                        </div>
                      </div>
                      {imageTouched && selectedImage.length === 0 && (
                        <span className="error-message text-danger d-flex justify-content-end">
                          required
                        </span>
                      )}

                      {isSuccess && selectedImage.length > 0 && (
                        <div className="mt-3">
                          <ul style={{ listStyleType: "none" }}>
                            {kycFile.map((imageName, index) => (
                              <li key={index} className="text-light iconxs">
                                {imageName}
                                <span
                                  className="text-danger cursor-pointer ms-2"
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <Trash />
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {/* ---------------------------------------------------------------- */}
                      <div>
                        <div className="row mt-4 ps-0 EnterBox-container ">
                          <div className="EnterBox col-md-5 ">
                            <p className="heading-Style-in">
                              Bank Name
                              <span className="merchantStar">*</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 expiry-date-field ">
                          <select
                            className="merchantinputStylex customWidths select-business-style"
                            value={selectedBank}
                            onChange={handleBankChange}
                          >
                            <option
                              value=""
                              key="select"
                              className="business_page_right_side_font"
                            >
                              Select Bank
                            </option>
                            {banksData &&
                              banksData.map((bank) => (
                                <option
                                  key={bank.bankName}
                                  value={bank.bankName}
                                >
                                  {bank.bankName}
                                </option>
                              ))}
                          </select>
                        </div>
                        {stateTouched && !selectedBank && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}
                      </div>
                      <div>
                        <div className="row mt-4 ps-0 EnterBox-container ">
                          <div className="EnterBox col-md-5 ">
                            <p className="heading-Style-in">
                              IBAN Number
                              <span className="merchantStar">*</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 expiry-date-field ">
                          <input
                            id="ibanNumber"
                            type="ibanNumber"
                            className="merchant-input-Style "
                            placeholder="Eg: AE07 0331 2345 6789 0123 456"
                            value={ibanNumber}
                            contentEditable="true"
                            autoComplete="off"
                            maxLength={28}
                            onChange={handleInputChange}
                          />
                        </div>
                        {stateTouched && !ibanNumber && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}

                        {invalidIban && (
                          <p className="iban_validation text-end mt-1">
                            Invalid IBAN number.
                          </p>
                        )}
                      </div>
                      <div>
                        <div className="row mt-4 ps-0 EnterBox-container ">
                          <div className="EnterBox col-md-5 ">
                            <p className="heading-Style-in">
                              Account Name
                              <span className="merchantStar">*</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 expiry-date-field ">
                          <CustomInput
                            inputName="accountName"
                            inputType="accountName"
                            className="merchant-input-Style"
                            placeholder=""
                            editable={true}
                            onInputChange={(value: string) => {}}
                            control={control}
                            rules={rules.AuthRules.account_name}
                          />
                        </div>
                      </div>

                      <div className=" d-flex align-items-center selection-check-container">
                        <div className=" selection-container-custom">
                          <p className="mb-0 ms-0 ps-0 mt-2">
                            {" "}
                            Do you have contract with any insurance companies
                          </p>
                        </div>
                      </div>
                      <div className="row choice-container mt-3">
                        <div className="col-md-1 pe-0 me-5 d-flex">
                          <CustomRadio
                            label="Yes"
                            checked={selectedOption === "Yes"}
                            onChange={() => handleRadioChange("Yes")}
                          />
                        </div>
                        <div className="col-md-1 pe-0 me-5 d-flex">
                          <CustomRadio
                            label="No"
                            checked={selectedOption === "No"}
                            onChange={() => handleRadioChange("No")}
                          />
                        </div>
                        {stateTouched && !selectedOption && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}
                        <div className="col-md-12">
                          {selectedOption === "Yes" && (
                            <div className="company-select-dropdown ms-5">
                              <Select
                                options={insuranceCompanyOptions}
                                isMulti={true}
                                menuIsOpen={true}
                                components={{
                                  Option: CheckboxOption,
                                }}
                                onChange={(selectedOptions) => {
                                  const selectedIds = selectedOptions.map(
                                    (option: { value: number }) => option.value
                                  );
                                  setSelectedInsuranceCompanyIds(selectedIds);
                                }}
                                value={insuranceCompanyOptions.filter(
                                  (option) =>
                                    selectedInsuranceCompanyIds.includes(
                                      option.value
                                    )
                                )}
                                menuPlacement="bottom"
                                placeholder="Select The Insurance Companies Below"
                                hideSelectedOptions={true}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: "70%",

                                    marginLeft: 20,
                                    color: "#fff",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#252421",
                                    border: "none",
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    color: "#F4B652",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: "#ffffff",
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: "#ffffff",
                                    border: "none",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#252421",
                                    paddingLeft: "5%",
                                    paddingRight: "5%",
                                    paddingTop: "1%",
                                    position: "relative",
                                    left: 0,
                                    border:
                                      "2px solid rgba(255, 255, 255, 0.2)",
                                    maxHeight: "none",
                                    overflowY: "auto",
                                    scrollbarWidth: "none",
                                  }),
                                  menuList: (provided) => ({
                                    ...provided,
                                    maxHeight: "230px",
                                    overflowY: "auto",
                                    scrollbarWidth: "none",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    padding: "0%",

                                    backgroundColor: state.isSelected
                                      ? "#F4B652"
                                      : "#252421",
                                    color: state.isSelected
                                      ? "#252421"
                                      : "#F4B652",
                                  }),
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <button
                          className="btn all_button_style text-black  mt-3 mb-3"
                          type="button"
                          onClick={handleSubmit}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/merchant/signup/welcome");
        }}
        message="Waiting for backoffice approval...."
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />
    </>
  );
};

export default KYCdetails;

// Progressbar.tsx
import React from "react";
import "./ProgressbarStyle.css";
import { progressType } from "../../types";

const Progressbar: React.FC<progressType> = ({ currentStage = 1 }) => {
  return (
    <div className="progressContainer">
      <div
        className={`progressCircle ${currentStage >= 1 ? "initialActive" : ""}`}
      />
      <div className="progressLine" />
      <div
        className={`progressCircle ${currentStage >= 2 ? "initialActive" : ""}`}
      />
      <div className="progressLine" />
      <div
        className={`progressCircle ${currentStage >= 3 ? "initialActive" : ""}`}
      />
      <div className="progressLine" />
      <div
        className={`progressCircle ${currentStage >= 4 ? "initialActive" : ""}`}
      />
    </div>
  );
};

export default Progressbar;

import React, { useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";
import TimePickerSelection from "./TimePickerSelection";
import "../styles/react-ios-time-picker.css";
import { time_icon } from "../../../assets/images";
import { OverlayTrigger, Popover } from "react-bootstrap";

function TimePicker({
  value: initialValue = null,
  cellHeight = 28,
  placeHolder = " ",
  label = "",
  pickerDefaultValue = "10:00",
  // onChange = () => {},
  onChange = (newTime) => {},
  onFocus = () => {},
  onSave = () => {},
  onCancel = () => {},
  disabled = false,
  isOpen: initialIsOpenValue = false,
  required = false,
  cancelButtonText = "Cancel",
  saveButtonText = "Save",
  controllers = true,
  seperator = true,
  id = null,
  use12Hours = false,
  onAmPmChange = () => {},
  name = null,
  onOpen = () => {},
  popupClassName = null,
  inputClassName = "",
}) {
  const [isOpen, setIsOpen] = useState(initialIsOpenValue);
  const [height, setHeight] = useState(cellHeight);
  const [inputValue, setInputValue] = useState(initialValue);

  const [showPopover, setShowPopover] = useState(false); // Define showPopover state

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleFocus = () => {
    onFocus();
    onOpen();
  };

  let finalValue = inputValue;

  if (initialValue === null && use12Hours) {
    finalValue = `${pickerDefaultValue} AM`;
  } else if (initialValue === null && !use12Hours) {
    finalValue = pickerDefaultValue;
  }

  const params = {
    // onChange,
    height,
    onSave,
    onCancel,
    cancelButtonText,
    saveButtonText,
    controllers,
    setInputValue,
    setIsOpen,
    seperator,
    use12Hours,
    onAmPmChange,
    initialValue: finalValue,
    pickerDefaultValue,
    placeHolder,
    label,
    inputClassName,
  };

  const popoverRight = (
    <Popover className="popover-custom">
      <div className="react-ios-time-picker-popup">
        <div
          className={`react-ios-time-picker-popup-overlay ${
            popupClassName || ""
          }`}
          // onClick={() => setIsOpen(!isOpen)}
        />
        <TimePickerSelection
          {...params}
          onChange={onChange}
          setShowPopover={setShowPopover}
        />
      </div>
    </Popover>
  );

  const ref = useRef(null);

  return (
    <>
      <div className="date-time-component-container " ref={ref}>
        <div className="date-time-component-wrapper">
          <div className="icon-input-container  ">
            <img
              src={time_icon}
              alt="Time Icon"
              className="me-1"
              style={{ cursor: "pointer", height: "20px" }}
            />
            <div className="text-start">
              <label className="label-date-time">{label}</label>

              <div className="react-ios-time-picker-main ">
                <OverlayTrigger
                  container={ref}
                  show={showPopover} // Use showPopover state to control popover visibility
                  placement="bottom"
                  overlay={popoverRight}
                >
                  <input
                    id={id}
                    name={name}
                    className={`  input-time ${inputClassName || ""}`}
                    value={inputValue === null ? "" : inputValue}
                    type="text"
                    placeholder={placeHolder}
                    autoComplete="off"
                    readOnly
                    disabled={disabled}
                    required={required}
                    onFocus={handleFocus}
                    onClick={() => setShowPopover(true)} // Open popover when input is clicked
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isOpen && !disabled && (
            <Portal>
               <div className="react-ios-time-picker-popup">
                  <div
                     className={`react-ios-time-picker-popup-overlay ${popupClassName || ''}`}
                     onClick={() => setIsOpen(!isOpen)}
                  />
                  <TimePickerSelection {...params} onChange={onChange} />
               </div>
            </Portal>
         )} */}
    </>
  );
}

export default TimePicker;

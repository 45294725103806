import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import "./TimePickerComponent.css";

interface TimePickerProps {
  onChange: (time: string | null) => void;
  label: string;
}

const TimePickerComponent: React.FC<TimePickerProps> = ({
  onChange,
  label,
}) => {
  //   const handleSave = (newValue: Dayjs | null) => {
  //     onChange(newValue);
  //   };
  const handleSave = (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedTime = newValue.format("HH:mm");
      onChange(formattedTime);
    } else {
      onChange(null);
    }
  };
  return (
    <div className="time-picker-container pt-2">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={label}
          views={["hours", "minutes"]}
          ampm={false}
          onChange={(newValue) => handleSave(newValue)}
          slotProps={{
            textField: { size: "small", fullWidth: true },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimePickerComponent;

import React from "react";

type CustomRadioProps = {
  label: string;
  checked: boolean | undefined;
  onChange: () => void;
};

const CustomRadio: React.FC<CustomRadioProps> = ({
  label,
  checked,
  onChange,
}) => {
  return (
    <label
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        fontSize: "18px",
        cursor: "pointer",
      }}
    >
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        style={{
          display: "none",
        }}
      />
      <div
        className="custom-radio"
        style={{
          width: "18px",
          height: "18px",
          borderRadius: "50%",
          border: "2px solid #F4B550",
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "8px",
        }}
      >
        {checked && (
          <div
            style={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor: "#F4B550",
            }}
          ></div>
        )}
      </div>
      <span>{label}</span>
    </label>
  );
};

export default CustomRadio;

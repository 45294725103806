import React, { ChangeEvent, FC } from "react";
import { FloatingInputLocationProps } from "../../types";
import { Controller } from "react-hook-form";
import { pickup_icon } from "../../assets/images";
import "./styles.css";

const FloatingInputLocation: FC<FloatingInputLocationProps> = ({
  onInputChange,
  inputType,
  className,
  label,
  editable,
  placeholder,
  control,
  inputName,
  rules = {},
  selectedLocationName,
}) => {
  const handleOnChange = (input: string, onChange: any) => {
    onInputChange(input);
    onChange(input);
  };

  return (
    <Controller
      control={control}
      name={inputName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div
          className={`form-floating merchant-input-style-create-product form-floating-label ${className} ${
            error ? "hasError" : ""
          }`}
        >
          <input
            id="floatingInput"
            type={inputType}
            className={`form-control floating-input-style ${className} ${
              error ? "hasError" : ""
            }`}
            placeholder={placeholder}
            contentEditable={editable}
            autoComplete="off"
            value={selectedLocationName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleOnChange(e.target.value, onChange)
            }
          />
          <label
            htmlFor="floatingInput"
            className="merchant-input-style-create-product"
          >
            <img src={pickup_icon} alt="Calendar Icon" className="me-2" />
            {label}
          </label>
          {error && (
            <div className="errorTextContainer">
              <div className="errorText">{error?.message || "Error"}</div>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default FloatingInputLocation;

import React, { useState } from "react";
import {
  info_details_sample_car,
  merchant_dashboard_location_icon,
} from "../../assets/images";
import MerchantDashboardnavbar from "../MerchantDashboard/MerchantDashboardNavbar";
import MerchantDashboardSidebar from "../MerchantDashboard/MerchantDashboardSidebar";
import { PencilSquare } from "react-bootstrap-icons";
import "./styles.css";
import { useForm } from "react-hook-form";
import CustomInput from "../CustomInput";
import rules from "../../rules";
import MapBox from "../MapBox";

const LocationStatus = () => {
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [yearModel, setYearModel] = useState("");
  const [insuranceType] = useState("");
  const [engineType, setEngineType] = useState("");

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    register,
    getValues,
  } = useForm({ mode: "onChange" });

  const mapOptions = {
    center: [25.204849, 55.270782],
    zoom: 13,
    maxZoom: 18,
    minZoom: 5,
  };

  return (
    <div className="row info_details_box">
      <div className="col-md-6 pt-4 text-start margin-line ps-4 ">
        <div>
          <div className="location_status_vehicle_text">Honda</div>
          <div className="location_status_model_text">CR-V</div>
        </div>
        <div className=" ps-2 pt-5">
          <img
            src={info_details_sample_car}
            width="70%"
            height="70%"
            alt="img"
            className="img-fluid "
          />
        </div>
        <div className="pt-3 table-location-status">
          <div className="table table-details_ls">
            <tr>
              <td className="table-left_ls border_line">Registration Number</td>
              <td className="colon-style border_line">:</td>
              <td className="table-right_ls border_line">H 98235 DUBAI</td>
            </tr>
            <tr>
              <td className="table-left_ls  border_line">Year Model</td>
              <td className="colon-style border_line">:</td>
              <td className="table-right_ls border_line">24 Feb 2018</td>
            </tr>
            <tr>
              <td className="table-left_ls border_line">Insurance Type</td>
              <td className="colon-style border_line">:</td>
              <td className="table-right_ls border_line">INS 30472849</td>
            </tr>
            <tr>
              <td className="table-left_ls border_line">Engine Type</td>
              <td className="colon-style border_line">:</td>
              <td className="table-right_ls border_line">HDCR-V-497W4</td>
            </tr>
          </div>

          <div className="d-flex pt-4">
            <div className="pe-1">
              <PencilSquare color={"#F0B64F"} cursor={"pointer"} />
            </div>
            <div>
              <a className="link-location-status ">Edit from car list</a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 info_right_side_padding_ls">
        <div className="main_right_location_box ">
          <div className="ps-2">
            <div className="d-flex">
              <div className="info_details_more_information_text_ls text-end colmd-6 d-flex pt-2">
                <div className="location-status-tic-icon"> </div>
                <div className="location_status_text pt-1 ps-2">
                  Arrived & Handover Done
                </div>
              </div>
              <div className="col text-end pt-2">
                <PencilSquare color={"#F0B64F"} size={20} cursor={"pointer"} />
              </div>
            </div>
            <div className="d-flex text-start" style={{ paddingTop: "20px" }}>
              <img
                src={merchant_dashboard_location_icon}
                width="20"
                height="20"
                alt="Logo"
                className="img-fluid ps-1"
              />
              <div className="ps-3">
                <div className="location_status_grey_text">
                  Departure Date & Location
                </div>
                <div className="location_listing_text">
                  10 Jan 2024 | EZ 21, Dera, Dubai
                </div>
              </div>
            </div>
            <div className="d-flex text-start" style={{ paddingTop: "20px" }}>
              <img
                src={merchant_dashboard_location_icon}
                width="20"
                height="20"
                alt="Logo"
                className="img-fluid ps-1"
              />
              <div className="ps-3">
                <div className="location_status_grey_text">
                  Departure Date & Location
                </div>
                <div className="location_listing_text">
                  10 Jan 2024 | EZ 21, Dera, Dubai
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="info_details_more_information_text_ls text-end colmd-6 d-flex pt-2 ps-2">
              <div className="tic-icon-location-status"> </div>
              <div className="location_status_text pt-1 ps-2">
                Payment Done (Completed)
              </div>
            </div>
          </div>
        </div>

        <MapBox latitude={25.204849} longitude={55.270782} />
      </div>
    </div>
  );
};

export default LocationStatus;

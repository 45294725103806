import React, { ChangeEvent } from "react";
import "./Customer_Newpassword.css";
import { car_sign_up, icon_black, icon_normal } from "../../assets/images";
import iconcus from "../../assets/images/kirkos_logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CustomInput,
  CustomerOnboardLeftSide,
  Progressbar,
} from "../../components";
import { Form, useForm } from "react-hook-form";
import rules from "../../rules";
import { useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../services/apiConfig";
import axiosInstance from "../../services/axios.instance";
import { ForgotPasswordData, RegistrationData } from "../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { forEachLeadingCommentRange } from "typescript";
//import { navigate, useNavigate } from "react-router-dom";
import { SuccessModal } from "../../components";
import { ErrorModal } from "../../components";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const Customernewpassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null); // New state variable for error
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { state } = useLocation();
  const phoneNo = state && state.phoneNo;
  console.log("phonenum", phoneNo);
  const transactionId = sessionStorage.getItem("transactionId");

  const handleInputChange = (name: string, value: string) => {
    if (name === "password") {
      setPassword(value);
    }
  };

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    getValues,
  } = useForm({ mode: "onChange" });

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setError(null);
    const formData = getValues();
    try {
      const userData: ForgotPasswordData = {
        phoneNo: phoneNo ?? "",
        new_password: formData.createpassword,
        user_type: "CONSUMER",
        transaction_id: transactionId,
        otp: "111111",
        event: "forgot_password",
      };

      const registrationResponse = await handleRegistration.mutateAsync(
        userData
      );

      if (registrationResponse.status && registrationResponse?.data?.message) {
      }
    } catch (error: any) {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    }

    setIsFormSubmitted(true);
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: ForgotPasswordData) =>
      axiosInstance.post(API_URL.FORGOT_PASSWORD, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        // alert(successResponse?.data?.message);
        setShowSuccessModal(true);
        // navigate("./welcome");
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <div className="outer-container ">
      <Form onSubmit={handleSubmit} control={control}>
        <div className="container-fluid bg-black custom-container ">
          <div className="row onboard-garage-main ">
            {/* Logo in the top-left corner of the yellow part */}
            <CustomerOnboardLeftSide />

            <div className="col-md-6 newpasssword_rightbox text-light pt-4 text-start  ">
              <div className="ps-4 pt-5">
                <div className="row pt-4 ">
                  <div className="col-md-9 ">
                    <img
                      src={icon_normal}
                      className="icon-img img-fluid mb-3"
                      alt="Icon"
                    />
                    <span className=" text-light customer_newpassword_text">
                      &nbsp;Update Password
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-11">
                    <p className="customer_newpassword_subhead fw-light  ">
                      Enter and Create new password. Use at least 6 characters
                      One
                      <br />
                      uppercase,at least one lower case letter,
                      <br />
                      one number in password
                    </p>
                  </div>
                </div>

                <div className=" col-md-5 newpassword_field">
                  <p className="headingStyle_newpassword">
                    Create a New Password
                    <span className="merchantStarx">*</span>
                  </p>
                </div>

                <div className="row">
                  <div className="col-md-10">
                    <div className="d-flex">
                      <CustomInput
                        inputName="createpassword"
                        inputType={showPassword1 ? "text" : "password"}
                        className="merchant_login_input_field "
                        placeholder="xxxxxx"
                        editable={true}
                        onInputChange={(value: string) => setPassword(value)}
                        control={control}
                        rules={rules.AuthRules.password}
                      />
                      <span
                        className="fs-5 password_eye"
                        onClick={togglePasswordVisibility1}
                      >
                        {showPassword1 ? <EyeSlash /> : <Eye />}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row  newconfirm_password">
                  <div className="col-md-5 ">
                    <p className="headingStyle_newpassword">
                      Confirm New Password
                      <span className="merchantStarx">*</span>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <div className="d-flex">
                      <CustomInput
                        inputName="confirmpassword"
                        inputType={showPassword2 ? "text" : "password"}
                        className="merchant_login_input_field "
                        placeholder="xxxxxx"
                        editable={true}
                        onInputChange={(value: string) =>
                          setConfirmPassword(value)
                        }
                        control={control}
                        // rules={rules.AuthRules.password}
                      />
                      <span
                        className="fs-5 password_eye"
                        onClick={togglePasswordVisibility2}
                      >
                        {showPassword2 ? <EyeSlash /> : <Eye />}
                      </span>
                    </div>
                    {error && (
                      <p
                        className="error-message"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          textAlign: "end",
                        }}
                      >
                        {error}
                      </p>
                    )}
                  </div>
                </div>
                <div className=" pt-5">
                  <button
                    className="btn all_button_style text-black"
                    type="submit"
                    disabled={!isValid}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SuccessModal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          onNav={() => {
            navigate("/signin");
          }}
          message="Update Success"
        />
        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          message="Password not valid"
        />
      </Form>
    </div>
  );
};

export default Customernewpassword;

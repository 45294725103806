// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-Insurance-holder-rental-confirm {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}

.heading-bottom-insurance-holder-rental-confirm {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 200;
  color: var(--font-colour-theme-16);
}

.link-style-insurance-holder-rental-confirm {
  color: var(--font-color-theme-2);
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomerDashBoard/InsuranceHolderRentalConfirm/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,gCAAgC;EAChC,yCAAyC;EACzC,eAAe;AACjB","sourcesContent":[".heading-Insurance-holder-rental-confirm {\n  font-size: 22px;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.heading-bottom-insurance-holder-rental-confirm {\n  font-family: \"Poppins\", sans-serif;\n  font-size: 12px;\n  font-weight: 200;\n  color: var(--font-colour-theme-16);\n}\n\n.link-style-insurance-holder-rental-confirm {\n  color: var(--font-color-theme-2);\n  font-family: \"PT Sans Narrow\", sans-serif;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

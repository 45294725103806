import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Form } from 'react-bootstrap';
import { Eye, EyeSlash } from "react-bootstrap-icons";

import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
} from "../../../assets/images";
import { CustomInput, MerchantOnboardLeftSide } from "../../../components";
import { FieldValues, useForm } from "react-hook-form";
import rules from "../../../rules";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import {
  LoginData,
  RegistrationData,
  RegistrationMerchantData,
} from "../../../types";
import { SuccessModal } from "../../../components";
import { ErrorModal } from "../../../components";
import { getTotp } from "../../../utils/totpUtil";

const GaragePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const { state } = useLocation();
  const email = state && state.email;
  const phoneNum = state && state.phoneNum;

  const handleInputChange = (name: string, value: string) => {
    if (name === "password") {
      setPassword(value);
    }
  };

  const {
    control,
    formState: { isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: "onChange" });

  const handleRegistration = useMutation({
    mutationFn: (userData: RegistrationMerchantData) =>
      axiosInstance.post(API_URL.REGISTER, userData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });

  const handleLogin = useMutation({
    mutationFn: (userData: LoginData) =>
      axiosInstance.post(API_URL.LOGIN, userData),
    onSuccess: (successResponse: any) => {
      console.log("successResponse77", successResponse);
      if (successResponse.status && successResponse?.data) {
        setShowSuccessModal(true);
      }
      const access_token = successResponse?.data?.access_token;
      sessionStorage.setItem("access_token", access_token);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });

  const handleTotp = async () => {
    try {
      handleLogin.mutate({
        username: email || phoneNum || "",
        password,
        userType: "GARAGE_USER",
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setError(null);

    try {
      const registrationData = {
        password,
        user_type: "GARAGE_USER",
        email: email,
        phone_no: phoneNum,
        last_level: 1,
      };

      const registrationResponse = await handleRegistration.mutateAsync(
        registrationData
      );

      if (registrationResponse.status && registrationResponse?.data?.message) {
        handleLogin.mutate({
          username: email || phoneNum || "",
          password,
          userType: "GARAGE_USER",
        });
      }
    } catch (error: any) {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    }

    setIsFormSubmitted(true);
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <>
      <div className="progressbar-custom">
        <MerchantProgressbar currentStage={3} />
      </div>

      <div className="row text-start mt-3">
        <div className="d-flex align-items-start pt-3 mt-4">
          <img
            src={kirkos_merchant_profile}
            className="img-fluid mb-3 "
            alt="Icon"
          />
          <span className="ms-1text-light merchant_password_signup_text">
            {" "}
            &nbsp;Garage Sign Up
          </span>
        </div>

        <div className="row">
          <div className="col-md-10">
            <div className="merchant_passtext fw-light  ">
              Enter and Create the password. Use at least 6 characters One
              uppercase,at least one lower case letter, one number in password
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-12">
            <label className="headingStylex">
              Create a Password
              <span className="merchantStarx">*</span>
            </label>
            <div className="d-flex">
              <CustomInput
                inputName="createpassword"
                inputType={showPassword1 ? "text" : "password"}
                className="merchant_login_input_field "
                placeholder="xxxxxx"
                editable={true}
                onInputChange={(value: string) => setPassword(value)}
                control={control}
                rules={rules.AuthRules.password}
              />
              <span
                className="fs-5 password_eye"
                onClick={togglePasswordVisibility1}
              >
                {showPassword1 ? <EyeSlash /> : <Eye />}
              </span>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-12">
            <label className="headingStylex">
              Confirm Password
              <span className="merchantStarx">*</span>
            </label>
            <div className="d-flex">
              <CustomInput
                inputName="confirmpassword"
                inputType={showPassword2 ? "text" : "password"}
                className="merchant_login_input_field "
                placeholder="xxxxxx"
                editable={true}
                onInputChange={(value: string) => setConfirmPassword(value)}
                control={control}
                // rules={rules.AuthRules.password}
              />
              <span
                className="fs-5 password_eye"
                onClick={togglePasswordVisibility2}
              >
                {showPassword2 ? <EyeSlash /> : <Eye />}
              </span>
            </div>

            {error && (
              <p
                className="error-message"
                style={{ color: "red", fontSize: "10px", textAlign: "end" }}
              >
                {error}
              </p>
            )}
          </div>
        </div>
        <div className="col-12 pt-5">
          <div className="col-3">
            <button
              className="btn all_button_style text-black"
              disabled={!isValid}
              onClick={handleSubmit}
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/garageonboard/business");
        }}
        message="Success"
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="Password not valid"
      />
    </>
  );
};

export default GaragePassword;

import React from "react";
import { dashboard_logo_merchant } from "../../../assets/images";
import "./imagepopupmodal.css";

interface ImagePopupModal {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  src: string;
}

const ImagePopupModal: React.FC<ImagePopupModal> = ({
  isOpen,
  onClose,
  message,
  src,
}) => {
  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div
        className="modal-dialog modal-dialog-imgpopup p-2 mt-5"
        role="document"
      >
        <div className="modal-content custom-modal-popup">
          <div className=" button-div-popup p-2">
            <button
              type="button"
              className=" button-close-img-popup pb-2"
              onClick={onClose}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="div-img-pop-modal">
            <img
              src={src}
              alt=""
              className="img-fluid img-pop-modal"
              // style={{ width: 300, height: 400 }}
            />
          </div>

          <div className="modal-footer d-flex justify-content-center p-0 mb-2 text-center">
            {/* <button
              type="button"
              className="btn-lg w-50 ok-button"
              onClick={onClose}
            >
              OK
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePopupModal;

import React from "react";
import "./MerchantDashboardMyIncome.css";
import { my_income_logo } from "../../../assets/images";

const MerchantDashboardMyIncome = () => {
  return (
    <div className="col-md-5">
      <div className="row me-2 row-height-myincome">
        <div className="col-md-12 text-end">
          <div className="bg-image-income">
            <div className="myincome-bg-custom p-2">
              <div className="row pt-1">
                <div className="col-md-12">
                  <table className="myincome-table-one">
                    <tr>
                      <td rowSpan={2}>
                        <img src={my_income_logo} alt="" />
                      </td>
                      <td className="header-myincome-cu text-center">
                        My Income (January)
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center heading-btm-myincome">
                        My Monthly income Status
                      </td>
                      <td>See all</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-12">
                  <table className="myincome-table-two pt-2 pb-4">
                    <tr className="myincome-table-income">
                      <td>AED 3750.00</td>
                      <td className="myincome-per">20%</td>
                    </tr>
                    <tr className="myincome-table-income-btm">
                      <td>January 2024</td>
                      <td>Today</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="row pt-3 pb-4">
                <div className="col-md-12">
                  <table className="myincome-table-two pt-2">
                    <tr className="pt-2">
                      <td>Yesterday total earning</td>
                      <td>Last Month Earning (Dec)</td>
                    </tr>
                    <tr className="bottom-myincome-aed pt-1">
                      <td>AED 1100.00</td>
                      <td>AED 31000.00</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantDashboardMyIncome;

import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import CustomInput from "../CustomInput";
import CustomRadio from "../customRadio";
import DatePicker from "react-datepicker";

import { useRef, useState } from "react";
import {
  car_key,
  baby_seat,
  mdi_car_insurance,
  steering_wheel,
} from "../../assets/images";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CustomerDetails, DocumentDetails } from "../../types";
import axiosInstance from "../../services/axios.instance";
import { API_URL } from "../../services/apiConfig";
import "./styles.css";
import CustomCheckBox from "../customCheckbox";
import { useLocation, useNavigate } from "react-router-dom";
import { Trash } from "react-bootstrap-icons";
import rules from "../../rules";

import { RentalSummarySecondPage } from "..";
import { setBookingValues } from "../../Redux/BookingData/bookingDataSlice";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
const NonInsuranceHolderSecondPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { deliveryDetails, selectedItem, merchantId, searchData } =
    location.state;
  const {
    control,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm({ mode: "onChange" });

  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);
  const [expiryError, setExpiryError] = useState("");

  const [isCarRentalAllowed, setIsCarRentalAllowed] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedOption1, setSelectedOption1] = useState<string>("");

  const [babySeatSelected, setBabySeatSelected] = useState<boolean>(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isDriverButtonDisabled, setIsDriverButtonDisabled] =
    useState<boolean>(true);

  const [additionalDriverSelected, setAdditionalDriverSelected] =
    useState<boolean>(false);
  const { bookingValues } = useSelector((state: any) => state.bookingValues);
  const handleRadioChange = (value: string) => {
    // setSelectedOption(value);
    dispatch(setBookingValues({ ...bookingValues, insuranceType: value }));
  };

  const handleRadioChangeNew = (value: string) => {
    setSelectedOption1(value);
  };

  const handleBabySeatChange = (value: boolean) => {
    //setBabySeatSelected(value === false ? false : value);
    dispatch(setBookingValues({ ...bookingValues, babySeatSelected: value }));
    setIsButtonDisabled(false);
  };

  const handleAdditionalDriverChange = (value: boolean) => {
    //setAdditionalDriverSelected(value === false ? false : value);
    dispatch(
      setBookingValues({ ...bookingValues, additionalDriverSelected: value })
    );
    setIsDriverButtonDisabled(false);
  };

  // const handleCarRentalChange = (value: boolean) => {
  //   // setIsCarRentalAllowed(value);
  //   dispatch(setBookingValues({ ...bookingValues, isCarRentalAllowed: value }));
  // };
  const handleCarRentalChange = (newValue: boolean) => {
    dispatch(
      setBookingValues({
        ...bookingValues,
        isCarRentalAllowed: newValue,
      })
    );
  };

  const handleDataFromRentalSummary = (data: any) => {
    // Handle the received data here
    console.log("Data received from RentalSummary:", data);
  };

  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [uploadedFile, setUploadedFile] = useState<string[]>([]);
  const [licenseFile, setLicenseFile] = useState<string[]>([]);
  const [emiratesIdFile, setEmiratesIdFile] = useState<string[]>([]);
  const [licenseFile2, setLicenseFile2] = useState<string[]>([]);
  const [VisitVisaFile, setVisitVisaFile] = useState<string[]>([]);
  const [PassportFile, setPassportFile] = useState<string[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const fileInputRefLicense = useRef<HTMLInputElement>(null);
  const fileInputRefEmiratesId = useRef<HTMLInputElement>(null);
  const fileInputRefLicense2 = useRef<HTMLInputElement>(null);
  const fileInputRefVisitVisa = useRef<HTMLInputElement>(null);
  const fileInputRefPassport = useRef<HTMLInputElement>(null);

  const openFileInputLicense = () => {
    fileInputRefLicense?.current?.click();
  };
  const openFileInputEmiratesId = () => {
    fileInputRefEmiratesId?.current?.click();
  };
  const openFileInputLicense2 = () => {
    fileInputRefLicense2?.current?.click();
  };
  const openFileInputVisitVisa = () => {
    fileInputRefVisitVisa?.current?.click();
  };
  const openFileInputPassport = () => {
    fileInputRefPassport?.current?.click();
  };
  const dispatch = useDispatch();

  const handleFileChangeLicense = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const acceptedFiles = Array.from(e.target.files);
      const updatedImages = [...selectedImage];
      const updatedUUIDs = [...uploadedFile];
      const selectedFileNames = acceptedFiles.map((file) => file.name);

      setLicenseFile((prevFiles) => [...prevFiles, ...selectedFileNames]);
      acceptedFiles.forEach((file) => {
        const docType = "LICENSE";
        console.log("docType", docType);
        updatedImages.push(docType || "");
      });

      setSelectedImage(updatedImages);

      const dataTransfer = new DataTransfer();
      acceptedFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });

      mutate(dataTransfer.files);
    }
  };

  const handleFileChangeEmiratesId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      const acceptedFiles = Array.from(e.target.files);
      const updatedImages = [...selectedImage];
      const updatedUUIDs = [...uploadedFile];
      const selectedFileNames = acceptedFiles.map((file) => file.name);

      setEmiratesIdFile((prevFiles) => [...prevFiles, ...selectedFileNames]);

      acceptedFiles.forEach((file) => {
        const docType = "EMIRITIES";
        console.log("docType", docType);
        updatedImages.push(docType || "");
      });

      setSelectedImage(updatedImages);

      const dataTransfer = new DataTransfer();
      acceptedFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });

      mutate(dataTransfer.files);
    }
  };

  const handleFileChangeLicense2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const acceptedFiles = Array.from(e.target.files);
      const updatedImages = [...selectedImage];
      const updatedUUIDs = [...uploadedFile];
      const selectedFileNames = acceptedFiles.map((file) => file.name);

      setLicenseFile2((prevFiles) => [...prevFiles, ...selectedFileNames]);

      acceptedFiles.forEach((file) => {
        const docType = "LICENSE";
        console.log("docType", docType);
        updatedImages.push(docType || "");
      });

      setSelectedImage(updatedImages);

      const dataTransfer = new DataTransfer();
      acceptedFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });

      mutate(dataTransfer.files);
    }
  };
  const handleFileChangeVisitVisa = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      const acceptedFiles = Array.from(e.target.files);
      const updatedImages = [...selectedImage];
      const updatedUUIDs = [...uploadedFile];
      const selectedFileNames = acceptedFiles.map((file) => file.name);

      setVisitVisaFile((prevFiles) => [...prevFiles, ...selectedFileNames]);

      acceptedFiles.forEach((file) => {
        const docType = "VisitVisa";
        console.log("docType", docType);
        updatedImages.push(docType || "");
      });

      setSelectedImage(updatedImages);

      const dataTransfer = new DataTransfer();
      acceptedFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });

      mutate(dataTransfer.files);
    }
  };
  const handleFileChangePassport = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const acceptedFiles = Array.from(e.target.files);
      const updatedImages = [...selectedImage];
      const updatedUUIDs = [...uploadedFile];
      const selectedFileNames = acceptedFiles.map((file) => file.name);

      setPassportFile((prevFiles) => [...prevFiles, ...selectedFileNames]);

      acceptedFiles.forEach((file) => {
        const docType = "Passport";
        console.log("docType", docType);
        updatedImages.push(docType || "");
      });

      setSelectedImage(updatedImages);

      const dataTransfer = new DataTransfer();
      acceptedFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });

      mutate(dataTransfer.files);
    }
  };

  const handleDeleteImageLicense = (index: number) => {
    setLicenseFile((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      // Remove corresponding docType if exists
      setSelectedImage((prevImages) => {
        const updatedImages = [...prevImages];
        if (index < prevImages.length) {
          updatedImages.splice(index, 1);
        }
        return updatedImages;
      });
      // Remove corresponding UUID if exists
      setUploadedFile((prevUUIDs) => {
        const updatedUUIDs = [...prevUUIDs];
        if (index < prevUUIDs.length) {
          updatedUUIDs.splice(index, 1);
        }
        return updatedUUIDs;
      });
      return updatedFiles;
    });
  };
  const handleDeleteImageEmiratesId = (index: number) => {
    setEmiratesIdFile((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      setSelectedImage((prevImages) => {
        const updatedImages = [...prevImages];
        if (index < prevImages.length) {
          updatedImages.splice(index, 1);
        }
        return updatedImages;
      });
      setUploadedFile((prevUUIDs) => {
        const updatedUUIDs = [...prevUUIDs];
        if (index < prevUUIDs.length) {
          updatedUUIDs.splice(index, 1);
        }
        return updatedUUIDs;
      });
      return updatedFiles;
    });
  };
  const handleDeleteImageLicense2 = (index: number) => {
    setLicenseFile2((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      setSelectedImage((prevImages) => {
        const updatedImages = [...prevImages];
        if (index < prevImages.length) {
          updatedImages.splice(index, 1);
        }
        return updatedImages;
      });
      setUploadedFile((prevUUIDs) => {
        const updatedUUIDs = [...prevUUIDs];
        if (index < prevUUIDs.length) {
          updatedUUIDs.splice(index, 1);
        }
        return updatedUUIDs;
      });
      return updatedFiles;
    });
  };
  const handleDeleteImageVisitVisa = (index: number) => {
    setVisitVisaFile((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      setSelectedImage((prevImages) => {
        const updatedImages = [...prevImages];
        if (index < prevImages.length) {
          updatedImages.splice(index, 1);
        }
        return updatedImages;
      });
      setUploadedFile((prevUUIDs) => {
        const updatedUUIDs = [...prevUUIDs];
        if (index < prevUUIDs.length) {
          updatedUUIDs.splice(index, 1);
        }
        return updatedUUIDs;
      });
      return updatedFiles;
    });
  };
  const handleDeleteImagePassport = (index: number) => {
    setPassportFile((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      setSelectedImage((prevImages) => {
        const updatedImages = [...prevImages];
        if (index < prevImages.length) {
          updatedImages.splice(index, 1);
        }
        return updatedImages;
      });
      setUploadedFile((prevUUIDs) => {
        const updatedUUIDs = [...prevUUIDs];
        if (index < prevUUIDs.length) {
          updatedUUIDs.splice(index, 1);
        }
        return updatedUUIDs;
      });
      return updatedFiles;
    });
  };

  const { mutate } = useMutation({
    mutationFn: (fileList: FileList) => {
      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("document", file);
      });
      return axiosInstance.post(API_URL.DOC_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onSuccess: (response) => {
      const uuid = response?.data?.uuid || null;
      setUploadedFile((prevUUIDs) => [...prevUUIDs, uuid]);
      setUploadSuccess(true);
      toast.success("Updated successfully!", {
        position: "top-right",
      });
    },
    onError: (error) => {
      console.error("Error uploading images", error);
      setUploadSuccess(false);
      toast.error("Error updating data. Please try again.", {
        position: "top-right",
      });
    },
  });

  const onHandleSubmitLicense = () => {
    const formattedExpiryDate = expiryDate || "";

    let { residentsLicenseNumber: licence_number }: FieldValues = getValues();

    const imagesData = selectedImage.map((docType, index) => ({
      docType: docType,
      docId: uploadedFile[index],
    }));
    const userData: DocumentDetails = {
      licence_number,
      licence_expiry_date: formattedExpiryDate,
      last_level: 999,
      customerDocs: imagesData,
    };
    console.log("Expiry Date:", expiryDate);

    handleRegistration.mutate(userData);
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: DocumentDetails) =>
      axiosInstance.put(API_URL.SIGNUP_MERCHANT, userData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
      }
    },
  });

  const {
    data: customerData,
    isLoading,
    isError,
    error,
  } = useQuery<CustomerDetails, Error>({
    queryKey: ["details"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.CUSTOMER_DETAILS);
      console.log(response, "response");
      return response.data;
    },
  });

  //------------------------
  const daysDifference = deliveryDetails?.daysDifference;
  const cdwDailyRate =
    selectedItem?.product_car_insurance?.collision_damage_waiver_model
      ?.daily_collision_damage_waiver;

  let cdwAmount = 0;
  if (cdwDailyRate !== undefined && daysDifference !== undefined) {
    cdwAmount = cdwDailyRate * daysDifference;
  }

  const scdwDailyRate =
    selectedItem.product_car_insurance?.super_collision_damage_waiver_model
      ?.daily_super_collision_damage_waiver;

  let scdwAmount = 0;
  if (scdwDailyRate !== undefined && daysDifference !== undefined) {
    scdwAmount = scdwDailyRate * daysDifference;
  }

  //---------------------

  const onHandleSubmit = () => {
    let paiInsuranceAmount = 0.0;

    if (isCarRentalAllowed) {
      paiInsuranceAmount =
        selectedItem?.product_car_insurance?.personal_accident_insurance_model
          ?.daily_personal_accident_insurance;
    }

    let scdwAmount = 0.0;
    let cdwAmount = 0.0;
    let scdwFlag = false;
    let cdwFlag = false;
    if (selectedOption === "SCDW") {
      scdwAmount =
        selectedItem?.product_car_insurance?.super_collision_damage_waiver_model
          ?.daily_super_collision_damage_waiver;
      scdwFlag = true;
      cdwFlag = false;
    } else if (selectedOption === "CDW") {
      cdwAmount =
        selectedItem?.product_car_insurance?.collision_damage_waiver_model
          ?.daily_collision_damage_waiver;
      cdwFlag = true;
      scdwFlag = false;
    }
    const optionalData = {
      baby_seat: babySeatSelected,
      baby_seat_amount: babySeatSelected
        ? selectedItem?.product_details_car?.child_seat_amount
        : 0,
      additional_driver: additionalDriverSelected,
      additional_driver_amount: additionalDriverSelected ? 25 : 0,
      pai_insurance_amount: paiInsuranceAmount,
      scdw_amount: scdwAmount,
      cdw_amount: cdwAmount,
      cdw_flag: cdwFlag,
      scdw_flag: scdwFlag,
    };
    console.log(babySeatSelected);

    navigate("/customer/vehicleinspection", {
      state: {
        deliveryDetails,
        optionalData,
        selectedItem,
        merchantId,
        searchData,
      },
    });
  };

  const handleExpiryDateChange = (date: Date) => {
    if (date) {
      const currentDate = new Date();
      const oneYearLater = new Date();
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

      if (date == oneYearLater) {
        setExpiryError(
          "Expiry date must be at least one year from the current date."
        );
      } else {
        setExpiryDate(date.toISOString());
        setExpiryError("");
      }
    } else {
      setExpiryDate("");
      setExpiryError("");
    }
  };

  const handleCombinedSubmit = () => {
    onHandleSubmitLicense();
    onHandleSubmit();
  };

  return (
    <div className="row pt-2 pb-3">
      <div className="col-md-8 pt-2">
        <div className="card card-custom">
          <div className="card-body ms-3 mb-3 text-start">
            <div className="row ">
              <div className="col-md-6 pt-3">
                <table style={{ width: "75%" }}>
                  <tr>
                    <td rowSpan={2}>
                      <img
                        src={baby_seat}
                        alt="file_browse_icon"
                        className="img-fluid header-icon"
                      />
                    </td>
                    <td
                      style={{
                        color: "#A1A1A1",
                        fontSize: "10px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {/* Per day{" "} */}
                      <span className="non_insur_per_day_aed">
                        AED{" "}
                        {selectedItem?.product_details_car?.child_seat_amount}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="page-head-sub"> Extra baby Seat</td>
                  </tr>
                </table>
                <table className="mt-2 ms-1  table-non-data-style">
                  <tr>
                    <td>
                      <div className="d-flex ">
                        <label className="radio-label-noninsurence">Yes</label>
                        <div className="ps-2">
                          <CustomRadio
                            onChange={() => handleBabySeatChange(true)}
                            // checked={babySeatSelected === true}
                            checked={bookingValues?.babySeatSelected === true}
                            label=""
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex ">
                        <label className="radio-label-noninsurence  ps-1">
                          No
                        </label>
                        <div className="ps-3">
                          <CustomRadio
                            onChange={() => handleBabySeatChange(false)}
                            // checked={babySeatSelected === false}
                            checked={bookingValues?.babySeatSelected === false}
                            label=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-md-6 text-start pt-3">
                <table style={{ width: "75%" }}>
                  <tr>
                    <td rowSpan={2}>
                      <img
                        src={steering_wheel}
                        alt="file_browse_icon"
                        className="img-fluid header-icon"
                      />
                    </td>
                    <td
                      style={{
                        color: "#A1A1A1",
                        fontSize: "10px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Per day{" "}
                      <span className="non_insur_per_day_aed">AED 35</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="page-head-sub">Additional Driver</td>
                  </tr>
                </table>
                <table className="mt-2 ms-1 table-non-data-style">
                  <tr>
                    <td>
                      <div className="d-flex ">
                        <label className="radio-label-noninsurence">Yes</label>
                        <div className="ps-2">
                          <CustomRadio
                            onChange={() => handleAdditionalDriverChange(true)}
                            //checked={additionalDriverSelected === true}
                            checked={
                              bookingValues?.additionalDriverSelected === true
                            }
                            label=""
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex ">
                        <label className="radio-label-noninsurence ps-1">
                          No
                        </label>
                        <div className="ps-3">
                          <CustomRadio
                            onChange={() => handleAdditionalDriverChange(false)}
                            checked={
                              bookingValues?.additionalDriverSelected === false
                            }
                            label=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pt-3">
                <table style={{ width: "100%" }}>
                  <tr>
                    <td rowSpan={2}>
                      <div className="d-flex">
                        <img
                          src={mdi_car_insurance}
                          alt="file_browse_icon"
                          className="img-fluid header-icon me-2"
                        />
                        <div className="page-head-sub">
                          Insurance (Optional)
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <table style={{ width: "100%" }} className="mt-2">
                  <tr>
                    <td>
                      <div className="col-md-12 d-flex">
                        <label className="selection-container-custom-license">
                          PAI Insurance
                        </label>
                        <div className="col-md-1 pe-0 me-0 ms-2">
                          <CustomCheckBox
                            isChecked={
                              bookingValues?.isCarRentalAllowed === true
                            }
                            onChange={() =>
                              handleCarRentalChange(
                                !bookingValues?.isCarRentalAllowed
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-md-6 text-start pt-3">
                <table style={{ width: "100%" }}>
                  <tr>
                    <td rowSpan={2}>
                      <img
                        src={mdi_car_insurance}
                        alt="file_browse_icon"
                        className="img-fluid header-icon me-2"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="page-head-sub">
                      CDW/SCDW Insurance *(Optional)
                    </td>
                  </tr>
                </table>
                <table className="mt-2 table-non-data-style">
                  <tr>
                    <td>
                      <div className="d-flex ">
                        <label className="radio-label-noninsurence">CDW</label>
                        <div className="ps-2">
                          <CustomRadio
                            onChange={() => handleRadioChange("CDW")}
                            checked={bookingValues?.insuranceType === "CDW"}
                            label=""
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex ">
                        <label className="radio-label-noninsurence">SCDW</label>
                        <div className="ps-2">
                          <CustomRadio
                            onChange={() => handleRadioChange("SCDW")}
                            checked={bookingValues?.insuranceType === "SCDW"}
                            label=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            {!Object.keys(customerData?.customerDocs || {}).length &&
              customerData && (
                <div>
                  <div className="row pt-3">
                    <div className="col-11 d-flex">
                      <img
                        src={car_key}
                        alt="file_browse_icon"
                        className="img-fluid header-icon"
                      />

                      <span className="col-md-6 ms-2 page-head-sub">
                        License Details / Documents *
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex pt-3">
                        <label className="res_license_num radio-label-noninsurence custom_radio_label">
                          For UAE Residents
                        </label>
                        <div className="ps-2">
                          <CustomRadio
                            onChange={() =>
                              handleRadioChangeNew("UAE Residents")
                            }
                            checked={selectedOption1 === "UAE Residents"}
                            label=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex pt-3">
                        <label className=" res_license_num radio-label-noninsurence custom_radio_label">
                          For Tourists
                        </label>
                        <div className="ps-2">
                          <CustomRadio
                            onChange={() => handleRadioChangeNew("Tourists")}
                            checked={selectedOption1 === "Tourists"}
                            label=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-start">
                    <div className="col-md-8">
                      {selectedOption1 === "UAE Residents" && (
                        <>
                          <div className="row mt-4 ps-0 EnterBox-container ">
                            <div className=" col-md-12 res_license_num">
                              License Number
                              <span className="merchantStar">*</span>
                            </div>
                          </div>
                          <div className="col-md-12 expiry-date-field ">
                            <CustomInput
                              inputName="residentsLicenseNumber"
                              inputType="residentsLicenseNumber"
                              className="ins_holder_sec_input_style pt-1"
                              placeholder="Eg: 995721"
                              editable={true}
                              onInputChange={(value: string) => {}}
                              control={control}
                              rules={rules.AuthRules.license_number}
                            />
                          </div>
                          {/* <div className="row mt-4 ps-0 EnterBox-container "> */}
                          <div className=" col-md-12 pt-3 res_license_num">
                            Expiry Date
                            <span className="merchantStar">*</span>
                          </div>
                          {/* </div> */}
                          <div className="col-md-12 expiry-date-field  date-line date-line-noninsurence">
                            <DatePicker
                              id="datetimepicker"
                              selected={
                                expiryDate ? new Date(expiryDate) : null
                              }
                              onChange={handleExpiryDateChange}
                              dateFormat="EEE, MMM d"
                              customInput={
                                <input
                                  readOnly
                                  className="input-date pt-1 ms-1"
                                />
                              }
                              popperClassName="hide-calendar"
                              showPopperArrow={true}
                              autoComplete="off"
                              minDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() + 1
                                  )
                                )
                              }
                            />
                          </div>
                          {expiryError && (
                            <div className="text-danger">{expiryError}</div>
                          )}
                          <div className="row mt-4 ps-0 EnterBox-container ">
                            <div className=" col-md-12 res_license_num">
                              Copy of your License
                              <span className="merchantStar">*</span>
                            </div>
                          </div>
                          <div className="row">
                            <span className="col-md-12">
                              <button
                                className="btn-custom-upload"
                                onClick={() => openFileInputLicense()}
                              >
                                Browse File
                              </button>
                              <div className="mt-3">
                                {licenseFile.map((fileName, index) => (
                                  <div
                                    key={index}
                                    className="text-light iconxs"
                                  >
                                    {fileName}
                                    <span
                                      className="text-danger cursor-pointer ms-2"
                                      onClick={() =>
                                        handleDeleteImageLicense(index)
                                      }
                                    >
                                      <Trash />
                                    </span>
                                  </div>
                                ))}
                              </div>

                              <input
                                type="file"
                                ref={fileInputRefLicense}
                                style={{ display: "none" }}
                                onChange={handleFileChangeLicense}
                                multiple
                              />
                            </span>
                          </div>
                          <div className="row mt-4 ps-0 EnterBox-container ">
                            <div className=" col-md-12 res_license_num">
                              Copy of your Emirates ID
                              <span className="merchantStar">*</span>
                            </div>
                          </div>
                          <div className="row expiry-date-field ">
                            <div className="col-md-12 ">
                              <button
                                className="btn-custom-upload"
                                onClick={openFileInputEmiratesId}
                              >
                                Browse File
                              </button>

                              <div className="mt-3">
                                {emiratesIdFile.map((fileName, index) => (
                                  <div
                                    key={index}
                                    className="text-light iconxs"
                                  >
                                    {fileName}
                                    <span
                                      className="text-danger cursor-pointer ms-2"
                                      onClick={() =>
                                        handleDeleteImageEmiratesId(index)
                                      }
                                    >
                                      <Trash />
                                    </span>
                                  </div>
                                ))}
                              </div>

                              <input
                                type="file"
                                ref={fileInputRefEmiratesId}
                                style={{ display: "none" }}
                                onChange={handleFileChangeEmiratesId}
                                multiple
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {selectedOption1 === "Tourists" && (
                        <>
                          <div className="row mt-4 ps-0 EnterBox-container ">
                            <div className=" col-md-12 res_license_num">
                              License Number
                              <span className="merchantStar">*</span>
                            </div>
                          </div>
                          <div className="col-md-12 expiry-date-field ">
                            <CustomInput
                              inputName="touristsLicenseNumber"
                              inputType="touristsLicenseNumber"
                              className="ins_holder_sec_input_style pt-1"
                              placeholder="Eg: 449236"
                              editable={true}
                              onInputChange={(value: string) => {}}
                              control={control}
                              rules={rules.AuthRules.license_number}
                            />
                          </div>
                          <div className="row mt-4 ps-0 EnterBox-container ">
                            <div className=" col-md-12 res_license_num">
                              Expiry Date
                              <span className="merchantStar">*</span>
                            </div>
                          </div>
                          <div className="col-md-12 expiry-date-field  date-line date-line-noninsurence">
                            <DatePicker
                              id="datetimepicker"
                              selected={
                                expiryDate ? new Date(expiryDate) : null
                              }
                              onChange={handleExpiryDateChange}
                              // onChange={(date: Date) => {
                              //   if (date) {
                              //     setExpiryDate(date.toISOString());
                              //   } else {
                              //     setExpiryDate("");
                              //   }
                              // }}
                              dateFormat="EEE, MMM d"
                              customInput={
                                <input
                                  readOnly
                                  className="input-date pt-1 ms-1"
                                />
                              }
                              popperClassName="hide-calendar"
                              showPopperArrow={true}
                              autoComplete="off"
                              minDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() + 1
                                  )
                                )
                              }
                            />
                          </div>
                          {expiryError && (
                            <div className="text-danger">{expiryError}</div>
                          )}
                          <div className="row mt-4 ps-0 EnterBox-container ">
                            <div className=" col-md-12 res_license_num">
                              Copy of your License
                              <span className="merchantStar">*</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 pt-3">
                              <button
                                className="btn-custom-upload"
                                onClick={openFileInputLicense2}
                              >
                                Browse File
                              </button>
                              <div className="mt-3">
                                {licenseFile2.map((fileName, index) => (
                                  <div
                                    key={index}
                                    className="text-light iconxs"
                                  >
                                    {fileName}
                                    <span
                                      className="text-danger cursor-pointer ms-2"
                                      onClick={() =>
                                        handleDeleteImageLicense2(index)
                                      }
                                    >
                                      <Trash />
                                    </span>
                                  </div>
                                ))}
                              </div>
                              <input
                                type="file"
                                ref={fileInputRefLicense2}
                                style={{ display: "none" }}
                                onChange={handleFileChangeLicense2}
                                multiple
                              />
                            </div>
                          </div>
                          <div className="row mt-4 ps-0 EnterBox-container ">
                            <div className=" col-md-12 res_license_num">
                              Copy of your Visit Visa
                              <span className="merchantStar">*</span>
                            </div>
                          </div>
                          <div className="row expiry-date-field ">
                            <div className="col-md-12 ">
                              <button
                                className="btn-custom-upload"
                                onClick={openFileInputVisitVisa}
                              >
                                Browse File
                              </button>
                              <div className="mt-3">
                                {VisitVisaFile.map((fileName, index) => (
                                  <div
                                    key={index}
                                    className="text-light iconxs"
                                  >
                                    {fileName}
                                    <span
                                      className="text-danger cursor-pointer ms-2"
                                      onClick={() =>
                                        handleDeleteImageVisitVisa(index)
                                      }
                                    >
                                      <Trash />
                                    </span>
                                  </div>
                                ))}
                              </div>
                              <input
                                type="file"
                                ref={fileInputRefVisitVisa}
                                style={{ display: "none" }}
                                onChange={handleFileChangeVisitVisa}
                                multiple
                              />
                            </div>
                          </div>
                          <div className="row mt-4 ps-0 EnterBox-container ">
                            <div className=" col-md-12 res_license_num">
                              Copy of your Passport
                              <span className="merchantStar">*</span>
                            </div>
                          </div>
                          <div className="row expiry-date-field ">
                            <div className="col-md-12 ">
                              <button
                                className="btn-custom-upload"
                                onClick={openFileInputPassport}
                              >
                                Browse File
                              </button>
                              <div className="mt-3">
                                {PassportFile.map((fileName, index) => (
                                  <div
                                    key={index}
                                    className="text-light iconxs"
                                  >
                                    {fileName}
                                    <span
                                      className="text-danger cursor-pointer ms-2"
                                      onClick={() =>
                                        handleDeleteImagePassport(index)
                                      }
                                    >
                                      <Trash />
                                    </span>
                                  </div>
                                ))}
                              </div>
                              <input
                                type="file"
                                ref={fileInputRefPassport}
                                style={{ display: "none" }}
                                onChange={handleFileChangePassport}
                                multiple
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 non_insur_sec_bottom_text_style pt-1">
                              <span className="non_insur_sec_bottom_star">
                                *
                              </span>{" "}
                              First page and last page of your passport
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            <div className="text-end pt-4 pe-md-5">
              <button
                className=" btn all_button_style text-black"
                onClick={handleSubmit(handleCombinedSubmit)}
                disabled={
                  isButtonDisabled ||
                  isDriverButtonDisabled ||
                  (!(
                    selectedOption1 === "Tourists" &&
                    isValid &&
                    uploadSuccess &&
                    expiryDate &&
                    PassportFile.length > 0 &&
                    VisitVisaFile.length > 0 &&
                    licenseFile2.length > 0
                  ) &&
                    !(
                      selectedOption1 === "UAE Residents" &&
                      isValid &&
                      uploadSuccess &&
                      expiryDate &&
                      emiratesIdFile.length > 0 &&
                      licenseFile.length > 0
                    ) &&
                    Object.keys(customerData?.customerDocs || {}).length === 0)
                }
              >
                CONTINUE
              </button>
              {/* <button
                className="btn all_button_style text-black"
                onClick={handleSubmit(handleCombinedSubmit)}
                disabled={isButtonDisabled}
              >
                CONTINUE
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* <RentalSummarySecondPage /> */}
      <div className="col-md-4 pt-2">
        <RentalSummarySecondPage
          daysDifference={deliveryDetails.daysDifference}
          totalAmount={deliveryDetails.totalAmount}
          childSeatAmount={selectedItem.product_details_car.child_seat_amount}
          paiInsuranceAmount={
            selectedItem.product_car_insurance
              ?.personal_accident_insurance_model
              ?.daily_personal_accident_insurance
          }
          // cdwAmount={
          //   selectedItem.product_car_insurance?.collision_damage_waiver_model
          //     ?.daily_collision_damage_waiver
          // }
          cdwAmount={cdwAmount}
          scdwAmount={scdwAmount}
          deliveryFee={0}
          // scdwAmount={
          //   selectedItem.product_car_insurance
          //     ?.super_collision_damage_waiver_model
          //     ?.daily_super_collision_damage_waiver
          // }
          // paiInsuranceDaily={selectedItem}
          carImage={selectedItem.product_details.images[0]}
          onDataFromRentalSummary={handleDataFromRentalSummary}
          carModel={selectedItem.product_details_car.car_model}
          seat={selectedItem.product_details_car.number_of_seats}
          fuelType={selectedItem.product_details_car.fuel_type}
        />
      </div>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      /> */}
    </div>
  );
};

export default NonInsuranceHolderSecondPage;

import React from "react";
import "./style.css";
import { Outlet } from "react-router-dom";
import { GarageOnboardLeft } from "../../../components";

const GarageOnboard = () => {
  return (
    <div className="container-fluid bg-black custom_containerx scroll-class">
      <div className="row rounded onboard-garage-main">
        <div className="col-md-6  custom-yellow-bg">
          <GarageOnboardLeft />
        </div>
        <div className=" col-md-6 custom-grey-bg text-light text-center p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default GarageOnboard;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-time-component-container {
  display: flex;
  margin-right: 1%;
  justify-content: space-between;
}

.date-time-component-wrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border: none;
  border-bottom: 2px solid var(--border-color-theme-7);
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100%;
}
.icon-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  width: 100%;
}
.div-date-time-picker {
  width: 100%;
}

.label-date-time {
  margin-bottom: 0;
  /* font-size: 75%; */
  color: var(--font-colour-theme-30);
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.input-containerdate {
  display: flex;
  align-items: center;
  border: none;
}

.input-time,
.input-date {
  border: none;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  color: var(--font-color-primary);
  font-size: 12px;
  /* border-bottom: 1px solid var(--border-color-theme-6); */
  background: transparent;
}

.input-time::-webkit-calendar-picker-indicator {
  display: none;
}

.input-date::-webkit-calendar-picker-indicator {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/DateTimeComponent/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,YAAY;EACZ,oDAAoD;EACpD,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,kCAAkC;EAClC,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,WAAW;EACX,gCAAgC;EAChC,eAAe;EACf,0DAA0D;EAC1D,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".date-time-component-container {\n  display: flex;\n  margin-right: 1%;\n  justify-content: space-between;\n}\n\n.date-time-component-wrapper {\n  display: flex;\n  flex-direction: column;\n  /* align-items: center; */\n  border: none;\n  border-bottom: 2px solid var(--border-color-theme-7);\n  width: 100%;\n}\n.react-datepicker-wrapper {\n  width: 100%;\n}\n.icon-input-container {\n  display: flex;\n  align-items: center;\n  margin-bottom: 2px;\n  width: 100%;\n}\n.div-date-time-picker {\n  width: 100%;\n}\n\n.label-date-time {\n  margin-bottom: 0;\n  /* font-size: 75%; */\n  color: var(--font-colour-theme-30);\n  font-size: 12px;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.input-containerdate {\n  display: flex;\n  align-items: center;\n  border: none;\n}\n\n.input-time,\n.input-date {\n  border: none;\n  background-color: transparent;\n  border: none;\n  outline: none;\n  width: 100%;\n  color: var(--font-color-primary);\n  font-size: 12px;\n  /* border-bottom: 1px solid var(--border-color-theme-6); */\n  background: transparent;\n}\n\n.input-time::-webkit-calendar-picker-indicator {\n  display: none;\n}\n\n.input-date::-webkit-calendar-picker-indicator {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.car-style-img {
  align-items: "left";
  /* width: '500%'; */
  height: "150px";
  /* marginInlineStart: '20px' */
}

.file_image_icon {
  aspect-ratio: 3 / 2;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}
.rent-carmodel-text {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.fontsize-li {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
}

.card-custom-carousel {
  border: 1px solid #4a4f55;
  background-color: var(--bg-colour-theme-11);
  border-radius: 0px;
}

.rental-carousel .carousel-control-next,
.rental-carousel .carousel-control-prev {
  position: absolute;
  top: 50%;
  height: 25px;
  bottom: 0;
  z-index: 1;
  display: block;
  align-items: center;
  justify-content: center;
  width: 25px;
  padding: 0;
  color: var(--font-color-primary);
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
  border-radius: 50%;
  background-color: var(--bg-colour-theme-45);
  padding: 5px;
}

.rental-carousel .carousel-control-next-icon,
.rental-carousel .carousel-control-prev-icon {
  width: 15px;
  color: var(--font-color-primary);
  height: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomerDashBoard/RentalSummaryCarousel/RentalSummaryCarousel.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,kCAAkC;EAClC,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,yBAAyB;EACzB,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,YAAY;EACZ,SAAS;EACT,UAAU;EACV,cAAc;EACd,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,UAAU;EACV,gCAAgC;EAChC,kBAAkB;EAClB,eAAe;EACf,SAAS;EACT,UAAU;EACV,8BAA8B;EAC9B,kBAAkB;EAClB,2CAA2C;EAC3C,YAAY;AACd;;AAEA;;EAEE,WAAW;EACX,gCAAgC;EAChC,YAAY;AACd","sourcesContent":[".car-style-img {\n  align-items: \"left\";\n  /* width: '500%'; */\n  height: \"150px\";\n  /* marginInlineStart: '20px' */\n}\n\n.file_image_icon {\n  aspect-ratio: 3 / 2;\n  object-fit: cover;\n  width: 100%;\n  border-radius: 10px;\n}\n.rent-carmodel-text {\n  font-size: 16px;\n  font-family: \"Poppins\", sans-serif;\n  font-weight: 500;\n}\n.fontsize-li {\n  font-size: 10px;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.card-custom-carousel {\n  border: 1px solid #4a4f55;\n  background-color: var(--bg-colour-theme-11);\n  border-radius: 0px;\n}\n\n.rental-carousel .carousel-control-next,\n.rental-carousel .carousel-control-prev {\n  position: absolute;\n  top: 50%;\n  height: 25px;\n  bottom: 0;\n  z-index: 1;\n  display: block;\n  align-items: center;\n  justify-content: center;\n  width: 25px;\n  padding: 0;\n  color: var(--font-color-primary);\n  text-align: center;\n  background: 0 0;\n  border: 0;\n  opacity: 1;\n  transition: opacity 0.15s ease;\n  border-radius: 50%;\n  background-color: var(--bg-colour-theme-45);\n  padding: 5px;\n}\n\n.rental-carousel .carousel-control-next-icon,\n.rental-carousel .carousel-control-prev-icon {\n  width: 15px;\n  color: var(--font-color-primary);\n  height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

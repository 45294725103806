import React, { useState } from "react";
import {
  american_express_logo,
  master_card_logo,
  payment_option_logo,
  visa_card_logo,
} from "../../assets/images";
import MerchantDashboardnavbar from "../MerchantDashboard/MerchantDashboardNavbar";
import MerchantDashboardSidebar from "../MerchantDashboard/MerchantDashboardSidebar";
import "./SelectPaymentMethod.css";
import { useForm } from "react-hook-form";
import CustomInput from "../CustomInput";
import rules from "../../rules";
import { useNavigate } from "react-router-dom";

const SelectPaymentMethod = () => {
  const navigate = useNavigate();
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [yearModel, setYearModel] = useState("");
  const [insuranceType] = useState("");
  const [engineType, setEngineType] = useState("");

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    register,
    getValues,
  } = useForm({ mode: "onChange" });

  const mapOptions = {
    center: [25.204849, 55.270782],
    zoom: 13,
    maxZoom: 18,
    minZoom: 5,
  };
  const handleNavigate = () => {
    navigate("/customer/myaccount");
  };

  return (
    <div className="row  pt-5 ">
      <div className="col-md-7 payment_option_box ">
        <div className="px-4  py-5 mb-5">
          <div className="row">
            <div className=" col-12 d-flex">
              <div className="d-flex">
                <div className="pt-2">
                  <img
                    src={payment_option_logo}
                    width="100%"
                    height="100%"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="payment_option_text ps-2">Payment Option</div>
              </div>

              <div className="d-flex ms-5 pt-3">
                <div>
                  <img
                    src={visa_card_logo}
                    width="100%"
                    height="100%"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="ps-1">
                  <img
                    src={master_card_logo}
                    width="100%"
                    height="100%"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="ps-1">
                  <img
                    src={american_express_logo}
                    width="100%"
                    height="100%"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="payment_option_credit_debit_text pt-4">
              Credit Card / Debit Card
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-4">
              <div className="text-start">
                <label className="form-label card_given_name_text">
                  Card Given Name
                  <span className="select_payment_method_star">*</span>
                </label>
                <CustomInput
                  inputName="cardgivenname"
                  inputType="cardgivenname"
                  editable={true}
                  control={control}
                  className="select_payment_method_placeholder"
                  placeholder="Eg: Thomas John"
                  onInputChange={(value: string) => {}}
                  rules={rules.AuthRules.cardgivenname}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-start">
                <label className="form-label card_given_name_text">
                  Card Expiration Date
                  <span className="select_payment_method_star">*</span>
                </label>
                <CustomInput
                  inputName="cardexpirationdate"
                  inputType="cardexpirationdate"
                  editable={true}
                  control={control}
                  className="select_payment_method_placeholder"
                  placeholder="Month / Year"
                  onInputChange={(value: string) => {}}
                  rules={rules.AuthRules.cardexpirationdate}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-start">
                <label className="form-label card_given_name_text">
                  CVV
                  <span className="select_payment_method_star">*</span>
                </label>
                <CustomInput
                  inputName="cvv"
                  inputType="cvv"
                  editable={true}
                  control={control}
                  className="select_payment_method_placeholder"
                  placeholder="..."
                  onInputChange={(value: string) => {}}
                  rules={rules.AuthRules.cvv}
                />
              </div>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-md-4">
              <div className="text-start">
                <label className="form-label card_given_name_text">
                  Postal Code
                  <span className="select_payment_method_star">*</span>
                </label>
                <CustomInput
                  inputName="postalcode"
                  inputType="postalcode"
                  editable={true}
                  control={control}
                  className="select_payment_method_placeholder"
                  placeholder="Eg: 353257"
                  onInputChange={(value: string) => {}}
                  rules={rules.AuthRules.postalcode}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="text-start">
                <label className="form-label card_given_name_text">
                  Phone Number
                  <span className="select_payment_method_star">*</span>
                </label>
                <div className="inputContainerStyle">
                  <span className="selectpayment_new_country_codestyle ">
                    +971
                  </span>
                  <CustomInput
                    inputName="phonenumber"
                    inputType="phonenumber"
                    editable={true}
                    control={control}
                    className="select_payment_method_placeholder"
                    placeholder="enter your number"
                    onInputChange={(value: string) => {}}
                    rules={rules.AuthRules.phonenumber}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-start">
                <label className="form-label card_given_name_text">
                  Email Address
                  <span className="select_payment_method_star">*</span>
                </label>
                <CustomInput
                  inputName="email"
                  inputType="email"
                  editable={true}
                  control={control}
                  className="select_payment_method_placeholder"
                  placeholder="name@emailaddress.com"
                  onInputChange={(value: string) => {}}
                  rules={rules.AuthRules.email}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 payment_option_box  ms-md-3">
        <div className="px-2  py-5 ">
          <table className="text-start select_payment_table_font">
            <tr className="select_payment_model_text">
              <td>Lexus - LX</td>
            </tr>
            <tr className="select_payment_submodel_text">
              <td className="pt-5">Subtotal</td>
              <td className="ps-5 pt-5">AED 820</td>
            </tr>
            <tr className="select_payment_submodel_text">
              <td className="pt-1">Extra Charges</td>
              <td className="ps-5 pt-1">AED 50</td>
            </tr>
            <tr className="select_payment_grey_text">
              <td className="">Kirkos Executive delivery charges</td>
            </tr>
            <tr className="select_payment_total_price_text">
              <td className="pt-4">Total Rental Price</td>
              <td className="ps-5 pt-4">AED 870</td>
            </tr>
          </table>
          <div className="pt-5 text-end">
            <button
              className="btn all_button_style text-black"
              onClick={handleNavigate}
            >
              CONFIRM PAYMENT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPaymentMethod;

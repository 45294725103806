import React from "react";
import "../../Css/Dashboard_style.css";
import { Outlet } from "react-router-dom";
import CustomerDashBoardNavbar from "../../../components/CustomerDashBoard/CustomerDashBoardNavbar";
import CustomerDashboardSidebar from "../../../components/CustomerDashBoard/CustomerDashBoardSidebar";

const CustomerDashboard = () => {
  return (
    <div className="col-md-12 col-xl-12 col-sm-12 container-fluid   dashboardcontainer">
      <div className="row flex-nowrap hide-overflow">
        <CustomerDashboardSidebar />
        <div className="col dashboardright d-flex justify-content-center ">
          <div className="dashboardright-width">
            <CustomerDashBoardNavbar />
            <div className="dashboardright-scroll">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;

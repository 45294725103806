import React from "react";
import { Card, CardBody } from "react-bootstrap";
import { Link } from "react-router-dom";
import GarageInsuranceClaimList from "../GarageInsuranceClaimList";
import {
  garage_dashboard_icon_one,
  garage_div_icon,
} from "../../../assets/images";
import { ArrowUp } from "react-bootstrap-icons";

const GarageHome = () => {
  return (
    <>
      <div className="col-12 pt-4 pb-3">
        <Card className="card-garage text-start">
          <CardBody>
            <div className="row bottom-border-garage">
              <div className="col-8  garage-data-head">
                <img
                  src={garage_div_icon}
                  alt=""
                  className="img-fluid img-icon-garage"
                />
                Total number of Garage Cars
              </div>
              <div className="col text-end">
                <Link to="/garage" className="listing_see_all">
                  See all
                </Link>
              </div>
            </div>
            <div className="d-flex pt-3">
              <div>
                <img src={garage_dashboard_icon_one} alt="" />
                &nbsp;
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-9">
                    <div className="garage-data-head">
                      Garage Cars Total Order Placed Today{" "}
                    </div>
                    <div className="yellow-count-garage">30</div>
                  </div>
                  <div className="col ">
                    <div className="month-head-garage">Last MonthTotal</div>
                    <div className="month-garage">March 2024</div>
                    <div className="yellow-month-garage">
                      300 <ArrowUp size={20} />
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-8">
                    <div className="garage-day-data">
                      <ArrowUp size={20} />
                      13
                    </div>
                    <div className="garage-day-data-label">Previous day</div>
                  </div>
                  <div className="col garage-day-data-label align-content-center">
                    See all in detail Monthly count,Weekly ,Daily Count
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <GarageInsuranceClaimList />
    </>
  );
};

export default GarageHome;

// components/DateTimeComponent.jsx
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calender_icon } from "../../assets/images";
import "./styles.css";

const DateTimeComponent = ({
  placeholder,
  label,
  value,
  onChange,
  className,
}: any) => {
  return (
    <div className="date-time-component-container">
      <div className="date-time-component-wrapper">
        <div className="icon-input-container">
          <img
            src={calender_icon}
            alt="Calendar Icon"
            className="me-1"
            style={{ cursor: "pointer", height: "20px" }}
          />

          <div className={`  text-start ${className || ""}`}>
            <label className="label-date-time">{label}</label>
            <div className="div-date-time-picker">
              <DatePicker
                id="datetimepicker"
                selected={value}
                onChange={(date: Date) => onChange(date)}
                dateFormat="dd-MM-yyyy"
                customInput={
                  <input
                    readOnly
                    className={`  input-date ${className || ""}`}
                    placeholder={placeholder}
                  />
                }
                popperClassName="hide-calendar"
                showPopperArrow={true}
                autoComplete="off"
                minDate={new Date()} // Set minDate to the current date
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimeComponent;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog-imgpopup {
  /* max-height: 200px; */
  max-width: 500px;
  border: none;
}

.button-close-img-popup {
  background: var(--bg-color-theme-3);
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: var(--font-color-primary);
  border-radius: 50%;
}

.button-div-popup {
  display: flex;
  justify-content: end;
}
.custom-modal-popup {
  background: linear-gradient(0deg, var(--radial-background-theme-1)),
    linear-gradient(0deg, var(--radial-background-theme-2));
  border-radius: 60px;
  /* padding: 5px; */
  text-align: center;
  color: var(--font-color-theme-3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.div-img-pop-modal {
  /* width: 100%; */
  height: auto;
  width: 100%;
}

.img-pop-modal {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/MerchantDashboard/ImagePopupModal/imagepopupmodal.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;AACA;EACE;2DACyD;EACzD,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,gCAAgC;EAChC,wCAAwC;AAC1C;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".modal-dialog-imgpopup {\n  /* max-height: 200px; */\n  max-width: 500px;\n  border: none;\n}\n\n.button-close-img-popup {\n  background: var(--bg-color-theme-3);\n  height: 40px;\n  width: 40px;\n  text-align: center;\n  font-size: 28px;\n  font-weight: 600;\n  color: var(--font-color-primary);\n  border-radius: 50%;\n}\n\n.button-div-popup {\n  display: flex;\n  justify-content: end;\n}\n.custom-modal-popup {\n  background: linear-gradient(0deg, var(--radial-background-theme-1)),\n    linear-gradient(0deg, var(--radial-background-theme-2));\n  border-radius: 60px;\n  /* padding: 5px; */\n  text-align: center;\n  color: var(--font-color-theme-3);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.div-img-pop-modal {\n  /* width: 100%; */\n  height: auto;\n  width: 100%;\n}\n\n.img-pop-modal {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

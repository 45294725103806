// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .merchant_dashboard_scroll{
    height:320px;
    overflow-y: scroll;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  } */

  /* .fullcomponent_height {
    height: 80vh;
    overflow-y:auto;
    overflow-x: hidden;
  } */`, "",{"version":3,"sources":["webpack://./src/components/MerchantDashboard/MerchantDashboardFullComponents/MerchantDashboardFullComponents.css"],"names":[],"mappings":"AAAA;;;;;;;KAOK;;EAEH;;;;KAIG","sourcesContent":["/* .merchant_dashboard_scroll{\r\n    height:320px;\r\n    overflow-y: scroll;\r\n    margin-left: 15px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n  } */\r\n\r\n  /* .fullcomponent_height {\r\n    height: 80vh;\r\n    overflow-y:auto;\r\n    overflow-x: hidden;\r\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import iconcus from "../../../assets/images/kirkos_logo.svg";
import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  kirkos_logo_black_yellow,
  onboard_full_logo_kirkos,
  car_home,
  car_sign_in,
  car_sign_up,
  customer_lock_icon,
  icon_black,
  icon_normal,
} from "../../../assets/images";
import { CustomInput, ErrorModal, SuccessModal } from "../../../components";
import { FieldValues, Form, useForm } from "react-hook-form";
import rules from "../../../rules";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { UserData } from "../../../types";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import "../signin/styles.css";
import "./forgotpassword.css";

import { getTotp } from "../../../utils/totpUtil";
const CustomerForgotPassword = () => {
  const [phoneNum, setPhoneNum] = useState<any>("");
  const [transactionId, setTransactionId] = useState<number | undefined>(0);
  const [showOtpscreen, setShowOtpscreen] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false); // State to manage modal visibility
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message

  const { state } = useLocation();
  const phoneNo = state && state.phoneNo;
  console.log("phonenum", phoneNo);

  const navigate = useNavigate();

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    getValues,
  } = useForm({ mode: "onChange" });

  const handleSubmit = () => {
    const formData = getValues();
    const phoneNo = formData.phoneNo;

    const userData = {
      phoneNo: formData.phoneNo,
      userType: "CONSUMER",
      event: "forgot_password",
    };
    mutation.mutate(userData);
  };

  const mutation = useMutation({
    mutationFn: (userData: UserData) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.transactionId) {
        setTransactionId(successResponse?.data?.transactionId);
        sessionStorage.setItem(
          "transactionId",
          successResponse?.data?.transactionId
        );

        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });
  return (
    <div className="outer-container">
      <Form onSubmit={handleSubmit} control={control}>
        <div className="container-fluid bg-black custom-container">
          {/* Main content row with two columns */}
          <div className="row onboard-garage-main ">
            {/* Left side with yellow color */}
            <div className="col-md-6 custom-yellow-bg ">
              {/* Logo in the top-left corner of the yellow part */}
              <div className="row ps-3">
                <div className="col-md-12 text-start mt-5">
                  <img
                    src={kirkos_logo_black_yellow}
                    width="150"
                    height="150"
                    alt="Logo"
                    className="img-fluid image-logo-left-side"
                  />
                </div>
                <div className="col-md-10 pt-4">
                  <p className="text">Enjoy your Trips with our wheels</p>
                </div>
                <div className="col-md-12 pt-2">
                  <p className="subtext">
                    Get ready to hit the road with confidence. Your next
                    generation adventure begins here.
                  </p>
                </div>
                <div className="car-div">
                  <img
                    src={car_sign_in}
                    className="car-img img-fluid"
                    alt="img"
                  ></img>
                </div>
              </div>

              {/* Your content for the left side goes here */}
            </div>

            {/* Right side with black color */}
            <div className="col-md-6 customer_forgot_right text-light custom-black py-5 ">
              <div className="text-start ps-4 pt-5">
                <div className="d-flex ">
                  <img
                    src={customer_lock_icon}
                    className=" icon-img img-fluid customer_lock_yellow_logo "
                    alt="Icon"
                  />
                  <p className="customer_forgot_text_head pt-1">
                    Forgot your Password
                  </p>
                </div>
                <div className="text-start">
                  <p className="customer_forgot_password_subtext">
                    Don’t worry! Resetting your password is easy. Just type
                    <br />
                    your registered Phone number below and send{" "}
                  </p>
                </div>

                <div className="customer_forgot_email pt-5">
                  <label className="headingStyle-forgot">
                    Enter your phone number
                    <span className="customer_star_text">*</span>
                  </label>
                  <div className=" inputContainerStyle">
                    <span className="country_code_forgot pe-2">+971</span>

                    <CustomInput
                      inputName="phoneNo"
                      inputType="text"
                      className="inputStyle-forgot-customer "
                      placeholder="enter your number"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      control={control}
                      maxLength={9}
                      rules={rules.AuthRules.phone_number}
                    />
                  </div>

                  <button
                    className="btn all_button_style text-black"
                    type="submit"
                  >
                    SEND
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SuccessModal
            isOpen={showSuccessModal}
            onClose={() => setShowSuccessModal(false)}
            onNav={() => {
              navigate("/forgot/otpscreen", {
                state: {
                  phoneNo: getValues("phoneNo"),
                  transactionId: transactionId,
                },
              });
            }}
            message="Otp Send Success"
          />

          <ErrorModal
            isOpen={showErrorModal}
            onClose={() => setShowErrorModal(false)}
            message="You are Not registered"
          />
        </div>
      </Form>
    </div>
  );
};

export default CustomerForgotPassword;

import iconcus from "../../../assets/images/kirkos_logo.svg";
import { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./signupboot.css";
import {
  onboard_full_logo_kirkos,
  car_sign_up,
  icon_black,
  icon_normal,
} from "../../../assets/images";
import {
  CustomInput,
  ErrorModal,
  CustomerOnboardLeftSide,
  Progressbar,
  SuccessModal,
} from "../../../components";
import { useForm } from "react-hook-form";
import rules from "../../../rules";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { UserData, VerifyOtp } from "../../../types";
import { Navigate, useNavigate } from "react-router-dom";

const CustomerSignUpFormBoot = () => {
  const [phoneNum, setPhoneNum] = useState<any>("");
  const [transactionId, setTransactionId] = useState<number | undefined>(0);
  const [showOtpscreen, setShowOtpscreen] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    setIsPhoneValid(phoneNum.length === 9);
  }, [phoneNum]);

  const handleClick = async (data: UserData) => {
    setPhoneNum(data.phoneNo);

    const userData = {
      phoneNo: data.phoneNo,
      userType: "CONSUMER",
      event: "register",
    };

    await mutation.mutateAsync(userData); // Use mutateAsync for an async mutation

    setShowOtpscreen(true);

    // Include both phoneNum and transactionId in the state object
    // navigate("./otpscreen", {
    //   state: { phoneNum: data.phoneNo, transactionId: transactionId },
    // });
  };

  const mutation = useMutation({
    mutationFn: (userData: UserData) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.transactionId) {
        setTransactionId(successResponse?.data?.transactionId);
        sessionStorage.setItem(
          "transactionId",
          successResponse?.data?.transactionId
        );
        setShowSuccessModal(true);
      } else {
        if (successResponse.status == 201 && successResponse?.data?.message) {
          setErrorMessage(successResponse?.data?.message);
          setShowErrorModal(true);
        }
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });
  return (
    <>
      <div className="outer-container">
        <div className="container-fluid bg-black custom-container">
          {/* Main content row with two columns */}
          <div className="row onboard-garage-main ">
            {/* Left side with yellow color */}
            <CustomerOnboardLeftSide />

            {/* Right side with black color */}
            <div className="col-md-6   customer_signup_righthalf text-start">
              <div className="progress-bar">
                <Progressbar currentStage={1} />
              </div>
              <div className="ps-3 pt-5 pe-2">
                <div className="row">
                  <div className="col-md-12 text-end">
                    <a
                      href="/"
                      className="text-end"
                      style={{
                        color: "#f4b652",
                        textDecoration: "underline",
                        fontFamily: "Poppins, sans-serif",
                        // fontStyle: "initial",
                        // display: "",
                      }}
                    >
                      Home
                    </a>
                  </div>
                </div>
                <div className=" d-flex ">
                  <img
                    src={icon_normal}
                    className="icon-img img-fluid"
                    alt="Icon"
                  />
                  <p className=" customer_signup_headtext text-light ">
                    Customer Sign Up
                  </p>
                </div>
                <p className=" customer_signup_subtextsign mt-2 ">
                  We will send you a one-time password on this phone number.
                </p>
                <div className="pt-5">
                  <p className="customer_signup_your_text text-white">
                    Your Phone Number
                    <span className="merchantStarx">*</span>
                  </p>

                  <div className=" d-flex customer_signup_inputcontainerstyle">
                    <span className="text-white me-1">+971</span>
                    <CustomInput
                      inputName="phoneNo"
                      inputType="text"
                      className="customer_signup_input"
                      placeholder="enter your number"
                      editable={true}
                      // onInputChange={(value: string) => {}}
                      onInputChange={(value: string) => setPhoneNum(value)}
                      control={control}
                      maxLength={9}
                      rules={rules.AuthRules.phone_number}
                    />
                  </div>
                  <div className="pt-3">
                    <button
                      className="btn all_button_style text-black mt-2 "
                      onClick={handleSubmit(handleClick)}
                      disabled={!isValid || !isPhoneValid}
                    >
                      CONTINUE
                    </button>
                  </div>
                </div>
                <div>
                  <p className="customer_signup_otp_text pt-4">
                    You will receive an OTP to your mobile soon via SMS to
                    verify your phone number and it will be automatically
                    detected by the registration app
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("./otpscreen", {
            state: { phoneNum: phoneNum, transactionId: transactionId },
          });
        }}
        message="Otp Send Successfully"
      />
      <ErrorModal
        isOpen={showErrorModal}
        //  onClose={onCloseErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />
    </>
  );
};

export default CustomerSignUpFormBoot;

import React, { useEffect } from "react";

import { Gear, Plus, Trash, ThreeDots } from "react-bootstrap-icons";

import { dashboard_vertical_three_dots } from "../../../assets/images";

import CreateProduct from "../../../components/MerchantDashboard/CreateProduct";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useState } from "react";
import { Car, ProductData, ProductList, ProductDelete } from "../../../types";

import { Dropdown, Pagination } from "react-bootstrap";
import CustomCheckBox from "../../customCheckbox";
import ConfirmationModal from "../../ConfirmationModal";

const ManageCarList = () => {
  const navigate = useNavigate;

  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [carList, setCarList] = useState<Car[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [deletePayload, setDeletePayload] = useState<ProductDelete | null>(
    null
  );

  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const accessToken = localStorage.getItem("access_token");

  // const decodeToken = (token: any) => {
  //   return JSON.parse(atob(token.split(".")[1]));
  // };
  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  // const merchantId = decodedToken["merchant-id"];
  // const businessArea = decodedToken["planet"];

  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const businessArea =
    decodedToken && decodedToken["planet"] ? decodedToken["planet"] : null;

  const mutation = useMutation({
    mutationFn: (userData: ProductList) =>
      axiosInstance.post(
        `${API_URL.PRODUCT_LIST}?page=${currentPage}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),

    onSuccess: (successResponse) => {
      console.log("successResponseproductlist", successResponse);
      const filteredCarList = successResponse?.data.content.filter(
        (car: Car) => car.record_status === 1
      );
      setCarList(filteredCarList);
      setTotalPages(successResponse?.data?.totalPages);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
    },
  });

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedStatus(value);
    setCurrentPage(0);
    // mutation.mutate({
    //   merchant_id: merchantId,
    //   business_area: businessArea,
    //   status: value, // Use the extracted value here
    // });
  };

  useEffect(() => {
    mutation.mutate({
      merchant_id: merchantId,
      business_area: businessArea,
      status: selectedStatus,
    });
  }, [currentPage, selectedStatus]);

  //-----------------------------

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // const itemsPerPage = 8;
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // const currentItems = carList.slice(indexOfFirstItem, indexOfLastItem);

  const handleUploadModalOpen = () => {
    setShowCreateProduct(true);
  };

  //------------------------------------------------------------------
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);

  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");

  const mutationDelete = useMutation({
    mutationFn: (userData: ProductDelete) =>
      axiosInstance.put(API_URL.PRODUCT_DELETE, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse) => {
      //window.location.reload();
      mutation.mutate({
        merchant_id: merchantId,
        business_area: businessArea,
        status: selectedStatus || "PENDING",
      });
      console.log("successResponse", successResponse);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
      mutation.mutate({
        merchant_id: merchantId,
        business_area: businessArea,
        status: selectedStatus || "PENDING",
      });
    },
  });

  const handleDeleteProducts = () => {
    if (selectedCheckboxes.length === 0) {
      return;
    }

    // const deletePayload: any = {
    //   merchant_id: merchantId,
    //   category_id: "",
    //   product_ids: selectedCheckboxes,
    //   is_enable: false,
    // };
    setDeletePayload({
      merchant_id: merchantId,
      category_id: "",
      product_ids: selectedCheckboxes,
      is_enable: false,
    });
    handleOpenConfirmationModal("Delete the product?");
    //mutationDelete.mutate(deletePayload);
  };

  const handleDeleteItem = (productId: string) => {
    setDeletePayload({
      merchant_id: merchantId,
      category_id: "",
      product_ids: [productId],
      is_enable: false,
    });
    // const deletePayload: any = {
    //   merchant_id: merchantId,
    //   category_id: "",
    //   product_ids: [productId],
    //   is_enable: false,
    // };
    handleOpenConfirmationModal("Delete the product?");
    //mutationDelete.mutate(deletePayload);
  };
  const handleOpenConfirmationModal = (message: string) => {
    setAcceptConfirmationMessage(message);
    setShowAcceptConfirmationModal(true);
  };
  //-------------------------------------------------------------------

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const handleCheckboxChange = (productId: string) => {
    setSelectedCheckboxes((prevState) => {
      if (prevState.includes(productId)) {
        const updatedCheckboxes = prevState.filter(
          (name) => name !== productId
        );
        console.log("Selected Productiddd111112344:", updatedCheckboxes);
        return updatedCheckboxes;
      } else {
        const updatedCheckboxes = [...prevState, productId];
        console.log("Selected Productiddddddddddd:", updatedCheckboxes);
        return updatedCheckboxes;
      }
    });
  };

  const location = useLocation();
  const pathname = location.pathname;
  const shouldShowDiv = pathname === "/merchant/managecars";

  return (
    <div className="row pt-5">
      <div className="col-md-12">
        {shouldShowDiv && (
          <div className="row">
            <div className="dashboard-button-right pe-5 d-flex justify-content-end">
              <button
                className={`dashboard-modal-button ${
                  showCreateProduct ? "active" : ""
                }`}
                onClick={handleUploadModalOpen}
              >
                <span className="px-1">
                  <Plus size={18} />
                  Upload Excel
                </span>
              </button>
              <button className="dashboard-modal-button">
                <Link to="/merchant/managecars/carinfo" className="nav-link">
                  <span className="px-1">
                    <Plus size={18} />
                    Add a Car
                  </span>
                </Link>
              </button>

              {/* <button className="dashboard-modal-button">
              <span className="px-1">
                <Plus size={18} />
                Upload Excel
              </span>
            </button> */}
              <button
                className="dashboard-modal-button"
                onClick={handleDeleteProducts}
              >
                <span className="px-1">
                  <Trash size={20} />
                </span>
              </button>
              <button className="dashboard-modal-button">
                <span className="px-1">
                  <ThreeDots size={20} /> More
                </span>
              </button>
            </div>
          </div>
        )}

        <div className="row">
          <div className="text-start div-sort-managecarlist">
            <select
              name="status"
              id=""
              className="merchant-input-style-create-product-selectbox-managecarlist"
              onChange={handleStatusChange}
              value={selectedStatus}
            >
              {/* <option value="ALL">ALL</option> */}
              <option value="APPROVED">APPROVED</option>
              <option value="PENDING">PENDING</option>
            </select>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12 dashboard-table-div">
            <table className="table table-hover dashboard-table">
              <thead>
                <tr>
                  <th></th>
                  <th>#</th>
                  <th>Car Name</th>
                  <th>Status</th>
                  <th>Colour</th>
                  <th>Reg:Number</th>
                  <th>Model Year </th>
                  {/* <th >Last 7 Days</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {carList &&
                  carList.map((car, index: number) => (
                    <tr key={index}>
                      <td>
                        {/* <CustomCheckBox
                          isChecked={false}
                          onChange={function (): void {
                            // throw new Error('Function not implemented.');
                          }}
                        /> */}
                        <CustomCheckBox
                          isChecked={selectedCheckboxes.includes(
                            car.product_id
                          )}
                          onChange={() => handleCheckboxChange(car.product_id)}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{car?.product_name}</td>
                      <td
                        className={
                          car?.status === "PENDING"
                            ? "status-colour-red"
                            : "status-colour-green"
                        }
                      >
                        {car?.status}
                      </td>
                      <td>{car?.product_details_car?.car_color}</td>
                      <td>{car?.product_details_car?.register_number}</td>
                      <td>{car?.product_details_car?.model_year}</td>
                      <td className="table-action-btn">
                        {/* <button className="btn">
                                                <img  src={dashboard_vertical_three_dots}width="15" height="15" />
                                            </button>
                                            <ul>
                                                <li>edite</li>
                                                <li>delete</li>
                                            </ul> */}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="Dropdown-table"
                          >
                            <img
                              src={dashboard_vertical_three_dots}
                              width="15"
                              height="15"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="Dropdown-table-item">
                            <Dropdown.Item className="Dropdown-table-list Dropdown-table-border-top">
                              <Link
                                to="#"
                                className="nav-link Dropdown-table-item-link"
                                onClick={() => handleDeleteItem(car.product_id)}
                              >
                                Delete
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item className="Dropdown-table-list Dropdown-table-border-bottom">
                              <Link
                                to={`/merchant/managecars/list/${car.product_id}`}
                                className="nav-link Dropdown-table-item-link"
                              >
                                View / Edit
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {carList.length > 0 && (
              <Pagination className="pagination-align">
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 0}
                />
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === currentPage}
                    onClick={() => handlePageChange(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages - 1}
                />
              </Pagination>
            )}
          </div>
        </div>
        <CreateProduct
          isOpen={showCreateProduct}
          onClose={() => setShowCreateProduct(false)}
        />
      </div>
      <ConfirmationModal
        isOpen={showAcceptConfirmationModal}
        onClose={() => setShowAcceptConfirmationModal(false)}
        // onConfirm={() => {
        //   mutationDelete.mutate(deletePayload);
        //   setShowAcceptConfirmationModal(false);
        // }}
        onConfirm={() => {
          if (deletePayload) {
            mutationDelete.mutate(deletePayload);
          }
          setShowAcceptConfirmationModal(false);
        }}
        message={acceptConfirmationMessage}
      />
    </div>
  );
};

export default ManageCarList;

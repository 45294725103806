// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant_dashboard_car_upload_box_style {
  background-color: var(--bg-colour-theme-25);
}

.car_upload_text {
  font-family: Poppins, sans-serif;
}

.car_upload_update_text {
  font-family: Poppins, sans-serif;
  color: var(--font-color-theme-2);
}
`, "",{"version":3,"sources":["webpack://./src/components/MerchantDashboard/MerchantDashboardCarUpload/MerchantDashboardCarUpload.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;AAC7C;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;EAChC,gCAAgC;AAClC","sourcesContent":[".merchant_dashboard_car_upload_box_style {\r\n  background-color: var(--bg-colour-theme-25);\r\n}\r\n\r\n.car_upload_text {\r\n  font-family: Poppins, sans-serif;\r\n}\r\n\r\n.car_upload_update_text {\r\n  font-family: Poppins, sans-serif;\r\n  color: var(--font-color-theme-2);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

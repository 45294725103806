// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftContainerStyle {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.yellowHalf {
  flex: 1 1;
  background-color: var(--bg-colour-theme-2);
  padding: 20px;
  margin-top: -60px;
}

.textStyle {
  color: var(--font-color-primary);
  font-size: 37px;
  margin-top: 150px;
  margin-bottom: -30px;
  text-align: left;
  font-family: Poppins, sans-serif;
  line-height: 50px;
  letter-spacing: -1px;
}

.subTextStyle {
  color: var(--font-color-primary);
  font-size: 20px;
  margin-right: 50px;
  text-align: left;
  line-height: 26px;
}

.logoStyle {
  position: absolute;
  top: 40px;
  left: 95px;
  width: 100px;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftComponentStyle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,SAAO;EACP,0CAA0C;EAC1C,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;EAChB,gCAAgC;EAChC,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,YAAY;EACZ,YAAY;AACd","sourcesContent":[".leftContainerStyle {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 100vh;\n}\n\n.yellowHalf {\n  flex: 1;\n  background-color: var(--bg-colour-theme-2);\n  padding: 20px;\n  margin-top: -60px;\n}\n\n.textStyle {\n  color: var(--font-color-primary);\n  font-size: 37px;\n  margin-top: 150px;\n  margin-bottom: -30px;\n  text-align: left;\n  font-family: Poppins, sans-serif;\n  line-height: 50px;\n  letter-spacing: -1px;\n}\n\n.subTextStyle {\n  color: var(--font-color-primary);\n  font-size: 20px;\n  margin-right: 50px;\n  text-align: left;\n  line-height: 26px;\n}\n\n.logoStyle {\n  position: absolute;\n  top: 40px;\n  left: 95px;\n  width: 100px;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

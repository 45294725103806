import React from "react";
import "./MerchantprogressbarStyle.css";
import { progressType } from "../../types";

 
const MerchantProgressbar: React.FC <progressType> = ({ currentStage = 1 }) => {  
  return (
    <div className="merchantprogressContainer ">
      <div className="merchantprogressCircle initialActive" />
      <div className="merchantprogressLine" />
      <div
        className={`merchantprogressCircle ${currentStage >= 2 ? "initialActive" : ""}`}
      />
      <div className="merchantprogressLine" />
      <div
        className={`merchantprogressCircle ${currentStage >= 3 ? "initialActive" : ""}`}
      />
      <div className="merchantprogressLine" />
      <div
        className={`merchantprogressCircle ${currentStage >= 4 ? "initialActive" : ""}`}
      />
      <div className="merchantprogressLine" />
      <div
        className={`merchantprogressCircle ${currentStage >= 5 ? "initialActive" : ""}`}
      />
       <div className="merchantprogressLine" />
      <div
        className={`merchantprogressCircle ${currentStage >= 6 ? "initialActive" : ""}`}
      />
       <div className="merchantprogressLine" />
      <div
        className={`merchantprogressCircle ${currentStage >= 7 ? "initialActive" : ""}`}
      />
    </div>
  );
};

export default MerchantProgressbar;

import React from "react";
import "./styles.css"; // Make sure to have the appropriate CSS file for styling

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
}) => {
  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div className="modal-dialog p-2 mt-5" role="document">
        <div className="modal-content custom-modal2">
          <div className="cross d-flex flex-row-reverse">
            <button
              type="button"
              className="close-confirmation btn rounded-circle"
              onClick={onClose}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-header d-flex justify-content-center align-items-center p-0">
            <h5 className="modal-title text-message">Confirmation!!!</h5>
          </div>
          <div className="modal-body">
            <p
              className="text-message"
              dangerouslySetInnerHTML={{ __html: message }}
            ></p>
          </div>
          <div className="modal-footer d-flex justify-content-center p-0 mb-2 text-center">
            <button
              type="button"
              className="btn-lg w-25 confirm-button "
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-order-details {
  padding: 6px;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  border-radius: 3px;
}

.button-rejected {
  background-color: var(--bg-colour-theme-68);
}
`, "",{"version":3,"sources":["webpack://./src/components/GarageDashBoard/GarageInsuranceOrderDetails/orderdetails.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".button-order-details {\n  padding: 6px;\n  font-size: 10px;\n  font-family: \"Poppins\", sans-serif;\n  border-radius: 3px;\n}\n\n.button-rejected {\n  background-color: var(--bg-colour-theme-68);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

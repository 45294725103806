import React, { ChangeEvent, useEffect } from "react";
import "./SignUpDetailsScreen.css";
import { car_sign_up, icon_black, icon_normal } from "../../../assets/images";
import iconcus from "../../../assets/images/kirkos_logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import {
  CustomInput,
  CustomerOnboardLeftSide,
  Progressbar,
} from "../../../components";
import { useForm } from "react-hook-form";
import rules from "../../../rules";
import { useState, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { Country, CountryState, RegistrationData } from "../../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { forEachLeadingCommentRange } from "typescript";
//import { navigate, useNavigate } from "react-router-dom";
import { SuccessModal } from "../../../components";
import { ErrorModal } from "../../../components";

interface LocationData {
  latitude: number;
  longitude: number;
}

const SignUpDetails = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [state1, setState1] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [stateData, setStateData] = useState<CountryState[]>([]);
  const [stateTouched, setStateTouched] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [suggestionClicked, setSuggestionClicked] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState<LocationData>({
    latitude: 0,
    longitude: 0,
  });

  const { state } = useLocation();
  const phoneNum = state && state.phoneNum;
  console.log("phonenum", phoneNum);

  const navigate = useNavigate();
  //const navigate = useNavigate();

  const handleInputChange = (name: string, value: string) => {
    switch (name) {
      case "password":
        setPassword(value);
        break;
      case "email":
        setEmail(value);
        break;

      default:
        break;
    }
  };

  const {
    control,
    formState: { isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: "onChange" });

  //-------------------------------------------------------------------
  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword) {
      setError(null);
    }
  }, [password, confirmPassword]);

  const handleNewInputChange = (value: string) => {
    setSearchQuery(value);
    console.log("add", value);
    setSuggestionClicked(false);
  };

  const fetchLocationName = async (searchQuery: string) => {
    const response = await fetch(
      `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${searchQuery}&limit=5&format=json&addressdetails=1`
    );
    console.log(response, "wowww");
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setAddressSuggestions(suggestions);
    const locationName = data?.display_name;
    return locationName;
  };

  const { data: locationName } = useQuery<string>({
    queryKey: ["locationName", searchQuery],
    queryFn: () => fetchLocationName(searchQuery),
  });
  const handleSuggestionClick = async (selectedSuggestion: string) => {
    const response = await fetch(
      `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${selectedSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      });
    }
    setValue("contactAddress", selectedSuggestion);
    setAddressSuggestions([]);
    setSuggestionClicked(true);
  };

  //----------------------------------------------------------------

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    trigger();
    setStateTouched(true);
    if (!country || !state1 || !isValid || !suggestionClicked) {
      return;
    }

    setError(null);
    const userData = {
      password,
      confirmPassword,
      email,
      firstName,
      lastName,
      address,
    };
    handleRegistration.mutate({
      phone_no: phoneNum,
      password,
      user_type: "CONSUMER",
      email,
      firstname: firstName,
      lastname: lastName,
      last_level: 1,
      //address,
      address: getValues("contactAddress"),
      state: state1,
      country,
    });
    console.log("dtatttttt", userData);
    setIsFormSubmitted(true);
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: RegistrationData) =>
      axiosInstance.post(API_URL.REGISTER, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });

  const {
    data: countriesData,
    isLoading,
    isError,
  } = useQuery<Country[], Error>({
    queryKey: ["countries"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.COUNTRY);
      return response.data;
    },
  });

  const handleCountryChange = async (e: any) => {
    const selectedCountryName = e.target.value;
    setCountry(selectedCountryName);

    const selectedCountry = countriesData?.find(
      (country) => country.countryName === selectedCountryName
    );

    if (selectedCountry) {
      setSelectedCountryId(selectedCountry.countryId.toString());

      const response = await axiosInstance.get(
        `${API_URL.STATE}${selectedCountry.countryId}`
      );
      setStateData(response.data);
    }
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
  };

  return (
    <div className="outer-container ">
      <div className="container-fluid bg-black custom-container ">
        <div className="row onboard-garage-main ">
          {/* Left side with yellow color */}
          <CustomerOnboardLeftSide />

          {/* Right side with black color */}
          <div className="col-md-6 custom-grey-bg text-light  text-start ">
            <div className="progress-bar">
              <Progressbar currentStage={3} />
            </div>
            <div className="ps-4 pt-5">
              <div className=" d-flex ">
                <img
                  src={icon_normal}
                  className="icon-img img-fluid"
                  alt="Icon"
                />
                <p className=" signup_details_headtext text-light ps-3 pt-2">
                  Customer Sign Up
                </p>
              </div>
              <p className="signup_details_subtext pt-1">
                Password Creation & Basic contact information with minimum{" "}
                fields needs to be filled below
              </p>

              {/* <div className=" input-container2  ms-1 pt-4"> */}
              <div className="col-md-12 pe-2 onboard-inner-div-scroll">
                <div className="row">
                  <div className="col-md-12">
                    <label className="text-light signup_details_poppinstext pt-3  ">
                      Create a Password
                      <span className="text-danger  ">*</span>
                    </label>
                    <div className="d-flex">
                      <CustomInput
                        inputName="password"
                        inputType={showPassword1 ? "text" : "password"}
                        className="merchant_login_input_field"
                        placeholder="xxxxxx"
                        editable={true}
                        onInputChange={(value: string) =>
                          handleInputChange("password", value)
                        }
                        control={control}
                        rules={rules.AuthRules.password}
                      />
                      <span
                        className="fs-5 password_eye"
                        onClick={togglePasswordVisibility1}
                      >
                        {showPassword1 ? <EyeSlash /> : <Eye />}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="text-light signup_details_poppinstext mt-3">
                      Confirm Password
                      <label className="text-danger  ">*</label>
                    </label>
                    <div className="d-flex">
                      <CustomInput
                        inputName="confirmpassword"
                        inputType={showPassword2 ? "text" : "password"}
                        className="merchant_login_input_field"
                        placeholder="xxxxxx"
                        editable={true}
                        // onInputChange={(value: string) =>
                        //   setConfirmPassword(value)
                        // }
                        onInputChange={handleConfirmPassword}
                        control={control}
                        // rules={rules.AuthRules.password}
                      />
                      <span
                        className="fs-5 password_eye"
                        onClick={togglePasswordVisibility2}
                      >
                        {showPassword2 ? <EyeSlash /> : <Eye />}
                      </span>
                    </div>
                    {error && (
                      <p
                        className=""
                        style={{
                          color: "red",
                          fontSize: "10px",
                          textAlign: "end",
                        }}
                      >
                        {error}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label className="text-light signup_details_poppinstext mt-4  ">
                      Email Address <label className="text-danger ">*</label>
                    </label>
                    <CustomInput
                      inputName="email"
                      inputType="email"
                      className="inputStyle-signupdetails"
                      placeholder="abc@gmail.com"
                      editable={true}
                      // onInputChange={(value: string) => {}}
                      onInputChange={(value: string) =>
                        handleInputChange("email", value)
                      }
                      control={control}
                      rules={rules.AuthRules.email}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="text-light signup_details_poppinstext mt-3">
                      First Name <label className="text-danger ">*</label>
                    </label>
                    <input
                      type="text"
                      className="inputStyle-signupdetails pt-1 "
                      placeholder="firstname"
                      autoComplete="off"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFirstName(e.target.value)
                      }
                    />
                    {stateTouched && !firstName && (
                      <span className="error-message text-danger d-flex justify-content-end">
                        required
                      </span>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label className="text-light signup_details_poppinstext mt-4">
                      Last Name<label className="text-danger ">*</label>
                    </label>
                    <input
                      type="text"
                      className="inputStyle-signupdetails pt-1 "
                      placeholder="lastname"
                      autoComplete="off"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setLastName(e.target.value)
                      }
                    />
                    {stateTouched && !lastName && (
                      <span className="error-message text-danger d-flex justify-content-end">
                        required
                      </span>
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="text-light  pt-4 signup_details_poppinstext">
                          Country <span className="text-danger ">*</span>
                        </label>
                        <select
                          className="inputStyle-signupdetails-select customWidth "
                          value={country}
                          onChange={handleCountryChange}
                        >
                          <option value="" key="select">
                            Select country
                          </option>
                          {countriesData &&
                            countriesData.map((country) => (
                              <option
                                key={country.countryId}
                                value={country.countryName}
                              >
                                {country.countryName}
                              </option>
                            ))}
                        </select>
                        {stateTouched && !country && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-light signup_details_poppinstext pt-4">
                          State<span className="text-danger">*</span>
                        </label>
                        <select
                          className="inputStyle-signupdetails-select customWidth "
                          value={state1}
                          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                            setState1(e.target.value)
                          }
                        >
                          <option value="" key="select">
                            Select state
                          </option>
                          {stateData &&
                            stateData.map((state) => (
                              <option key={state.id} value={state.state_name}>
                                {state.state_name}
                              </option>
                            ))}
                        </select>
                        {stateTouched && !state1 && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 pt-3 relative-div">
                    <label className="text-light  business_page_right_side_font ">
                      Contact Address
                      <label className="text-danger ms-0 p-0">*</label>
                    </label>
                    <CustomInput
                      inputType="contactAddress"
                      inputName="contactAddress"
                      control={control}
                      editable={true}
                      className="merchantinputStylex"
                      placeholder="Abc house"
                      onInputChange={(value: string) =>
                        handleNewInputChange(value)
                      }
                      rules={rules.AuthRules.companyAddress}
                    />

                    {stateTouched &&
                      addressSuggestions.length === 0 &&
                      !suggestionClicked && (
                        <span className="error-message text-danger d-flex justify-content-end">
                          valid address required
                        </span>
                      )}

                    {addressSuggestions.length > 0 && (
                      <div className="absolute-div">
                        <ul className="text-start" style={{ color: "white" }}>
                          {addressSuggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="pt-3 pb-3">
                      <button
                        className="btn all_button_style text-black mt-2 "
                        //disabled={!isValid}
                        onClick={handleSubmit}
                      >
                        {" "}
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("./welcome");
        }}
        message="Registration Success"
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="Password not valid"
      />
    </div>
  );
};

export default SignUpDetails;

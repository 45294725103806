import React from "react";

import "./PageNavigator.css";
import "./RightComponentStyle.css";
import { qr_code, stores, welcome_icon } from "../assets/images";

const RegistrationSuccessComponent: React.FC = () => {
  return (
    <div className="whiteHalf">
      <div className="successContainer">
        <img src={welcome_icon} className="welcomeIcon" />
        <p className="welcomeTitle">Welcome to Kirkos</p>
      </div>
      <div>
        <p className="successMessage">
          You have successfully completed the sign up Process
        </p>
      </div>
      <div>
        <p className="descriptionText">
          Proceed to{" "}
          <span className="custom-link">
            <a href="/your-link">My Account</a>
          </span>{" "}
          & Continue <br /> Searching for Cars.
        </p>
      </div>
      <div className="successContainer">
        <div>
          <img src={qr_code} className="scanIcon" />
          <div className="scanText">SCAN NOW</div>
        </div>
        <div>
          <div className="downloadText">
            You can also download the <br /> KIRKOS App as well
          </div>
          <img src={stores} className="scanIcon" />
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccessComponent;

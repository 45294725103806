import React from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import "./styles.css";
import { API_URL } from "../../../services/apiConfig";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";

interface Order {
  orderNumber: string;
  oderStatus: string;
  statusChangeDate: string | null;
}

interface Event {
  event_id: number;
  title: string;
  start: Date;
  end: Date;
}

const BookingCalender = () => {
  const {
    data: orders = [],
    isLoading,
    isError,
  } = useQuery<Order[]>({
    queryKey: ["orders"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.BOOKING_CALENDER);
      return response.data;
    },
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  const events: Event[] = orders
    .map((order: Order, index: number) => {
      const validDate =
        order.statusChangeDate && !isNaN(Date.parse(order.statusChangeDate));
      if (validDate) {
        const date = new Date(order.statusChangeDate!);
        return {
          event_id: index + 1,
          title: `${order.oderStatus} , Order Id ${order.orderNumber}`,
          start: date,
          end: date,
        };
      }
      return null;
    })
    .filter((event: any): event is Event => event !== null);

  return (
    <div className="booking-calendar">
      <Scheduler
        view="month"
        editable={false}
        deletable={false}
        agenda={false}
        // height={500}
        month={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 0,
          startHour: 0,
          endHour: 24,
          navigation: true,
          disableGoToDay: false,
        }}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 0,
          startHour: 0,
          endHour: 24,
          step: 60,
          navigation: true,
          disableGoToDay: false,
        }}
        day={{
          startHour: 0,
          endHour: 24,
          step: 60,
        }}
        events={events}
      />
    </div>
  );
};

export default BookingCalender;

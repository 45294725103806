// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  justify-content: flex-start;
  margin-left: 5%;
}

.progressLine {
  width: 13%;
  height: 1px;
  background-color: var(--bg-colour-theme-26);
}

.progressCircle {
  width: 1vw;
  height: 1vw;
  min-width: 1vw;
  min-height: 1vw;
  border-radius: 50%;
  background-color: var(--bg-colour-theme-26);
  margin: 0 2vw;
  transition: background-color 0.3s ease;
}

.progressCircle.initialActive {
  background-color: transparent;
  border: 1px solid orange;
  width: 1vw;
  height: 1vw;
}

@media only screen and (max-width: 768px) {
  .progressContainer {
    margin-left: 5vw;
  }

  .progressLine {
    width: 20%;
  }

  .progressCircle {
    margin: 0 1vw;
  }

  .progressCircle.initialActive {
    width: 1vw;
    height: 1vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Progressbar/ProgressbarStyle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,2CAA2C;AAC7C;;AAEA;EACE,UAAU;EACV,WAAW;EACX,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,2CAA2C;EAC3C,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,6BAA6B;EAC7B,wBAAwB;EACxB,UAAU;EACV,WAAW;AACb;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,UAAU;IACV,WAAW;EACb;AACF","sourcesContent":[".progressContainer {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin-top: 50px;\n  justify-content: flex-start;\n  margin-left: 5%;\n}\n\n.progressLine {\n  width: 13%;\n  height: 1px;\n  background-color: var(--bg-colour-theme-26);\n}\n\n.progressCircle {\n  width: 1vw;\n  height: 1vw;\n  min-width: 1vw;\n  min-height: 1vw;\n  border-radius: 50%;\n  background-color: var(--bg-colour-theme-26);\n  margin: 0 2vw;\n  transition: background-color 0.3s ease;\n}\n\n.progressCircle.initialActive {\n  background-color: transparent;\n  border: 1px solid orange;\n  width: 1vw;\n  height: 1vw;\n}\n\n@media only screen and (max-width: 768px) {\n  .progressContainer {\n    margin-left: 5vw;\n  }\n\n  .progressLine {\n    width: 20%;\n  }\n\n  .progressCircle {\n    margin: 0 1vw;\n  }\n\n  .progressCircle.initialActive {\n    width: 1vw;\n    height: 1vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

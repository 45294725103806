// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PageNavigator.css */

.pageNavigator {
  position: absolute;
  top: 8%;
  left: 2%;
  transform: translateX(0%);
  display: flex;
  align-items: center;
  width: "80px";
}

.pageLine {
  width: 80px;
  height: 1px;
  background-color: var(--bg-colour-theme-19); /* Default grey color */
}

.pageCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--bg-colour-theme-19); /* Default grey color */
  margin: 0 50px;
  transition: background-color 0.3s ease; /* Transition effect */
}

.pageCircle.initialActive {
  background-color: transparent; /* Make the circle transparent */
  border: 2px solid orange; /* Add a 2px orange border to create the outline */
  width: 8px; /* Adjust the width to make the circle smaller if needed */
  height: 8px; /* Adjust the height to make the circle smaller if needed */
}
`, "",{"version":3,"sources":["webpack://./src/components/PageNavigator.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,WAAW;EACX,2CAA2C,EAAE,uBAAuB;AACtE;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,2CAA2C,EAAE,uBAAuB;EACpE,cAAc;EACd,sCAAsC,EAAE,sBAAsB;AAChE;;AAEA;EACE,6BAA6B,EAAE,gCAAgC;EAC/D,wBAAwB,EAAE,kDAAkD;EAC5E,UAAU,EAAE,0DAA0D;EACtE,WAAW,EAAE,2DAA2D;AAC1E","sourcesContent":["/* PageNavigator.css */\n\n.pageNavigator {\n  position: absolute;\n  top: 8%;\n  left: 2%;\n  transform: translateX(0%);\n  display: flex;\n  align-items: center;\n  width: \"80px\";\n}\n\n.pageLine {\n  width: 80px;\n  height: 1px;\n  background-color: var(--bg-colour-theme-19); /* Default grey color */\n}\n\n.pageCircle {\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background-color: var(--bg-colour-theme-19); /* Default grey color */\n  margin: 0 50px;\n  transition: background-color 0.3s ease; /* Transition effect */\n}\n\n.pageCircle.initialActive {\n  background-color: transparent; /* Make the circle transparent */\n  border: 2px solid orange; /* Add a 2px orange border to create the outline */\n  width: 8px; /* Adjust the width to make the circle smaller if needed */\n  height: 8px; /* Adjust the height to make the circle smaller if needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// rentalSummarySlice.js
import { createSlice } from "@reduxjs/toolkit";

export const rentalSummarySlice = createSlice({
  name: "rentalSummary",
  initialState: {
    isCarRentalAllowed: false,
    selectedOption: "",
    babySeatSelected: false,
    additionalDriverSelected: false,
  },
  reducers: {
    updateRentalSummary: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateRentalSummary } = rentalSummarySlice.actions;

export default rentalSummarySlice.reducer;

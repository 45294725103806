import React, { FC, ChangeEvent } from "react";
import { Controller } from "react-hook-form";

import { CustomInputProps } from "../../types";
import "./styles.css";

const CustomInput: FC<CustomInputProps> = ({
  onInputChange,
  inputType,
  className,
  editable,
  placeholder,
  control,
  inputName,
  rules = {},
  maxLength,
}) => {
  const handleOnChange = (input: string, onChange: any) => {
    onInputChange(input);
    onChange(input);
  };

  return (
    <Controller
      control={control}
      name={inputName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className="inputContainer">
          <input
            type={inputType}
            className={`${className} ${error ? "hasError" : ""}`}
            placeholder={placeholder}
            autoComplete="off"
            // contentEditable={false}
            disabled={!editable}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              // Limit input length if maxLength is provided and inputName is 'phoneNo'
              const inputValue =
                maxLength && inputName === "phoneNo"
                  ? e.target.value.slice(0, maxLength)
                  : e.target.value;
              handleOnChange(inputValue, onChange);
            }}
            // Conditionally render maxLength attribute based on inputName
            {...(maxLength && inputName === "phoneNo" ? { maxLength } : {})}
          />
          {value?.length > 0 && inputName === "phoneNo" ? (
            <>
              {!value.startsWith("5") ? (
                <div className="errorTextContainer">
                  <div className="errorText">{"invalid mobile number"}</div>
                </div>
              ) : (
                value.length !== 9 && (
                  <div className="errorTextContainer">
                    <div className="errorText">
                      {"Phone number must be exactly 9 digits"}
                    </div>
                  </div>
                )
              )}
            </>
          ) : (
            <>
              {error && (
                <div className="errorTextContainer">
                  <div className="errorText">{error?.message}</div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    />
  );
};

export default CustomInput;

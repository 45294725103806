import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './Odoo.css'
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../../services/axios.instance';
import { API_URL } from '../../../services/apiConfig';
import { MerchantList } from "../../../types";
const Odoo = () => {

    const {
        data: merchantList,
        isLoading,
        isError,
      } = useQuery<MerchantList[], Error>({
        queryKey: ["merchants"],
        queryFn: async () => {
          const response = await axiosInstance.get(API_URL.MERCHANT_LIST);
          return response.data;
        },
      });
  return (
    <div>
    <h2 className='custom-merchant-list mt-3 ms-5'>Merchant List</h2>
    {isLoading && <p>Loading...</p>}
    {isError && <p>Error loading merchant list</p>}
    {merchantList && (
      <table className="table custom-new ms-5 me-3">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">NAME</th>
          </tr>
        </thead>
        <tbody>
          {merchantList.map((merchant) => (
            <tr key={merchant.id}>
              <th scope="row">{merchant.id}</th>
              <td>{merchant.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
  )
}

export default Odoo;


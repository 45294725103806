import React from "react";
import "./RegistrationModal.css";
import { thumb_up } from "../../assets/images";

interface RegistrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  onNav: () => void;
  password?: boolean;
  confirmPassword?: boolean;
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({
  isOpen,
  onClose,
  message,
  onNav,
  password = false,
  confirmPassword = false,
}) => {
  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div className="modal-dialog p-2 mt-5" role="document">
        <div className="modal-content    custom-modal2 ">
          {password ||
            (confirmPassword && (
              <div className="cross d-flex flex-row-reverse ">
                <button
                  type="button"
                  className="close  btn  rounded-circle "
                  onClick={onClose}
                >
                  <span>&times;</span>
                </button>
              </div>
            ))}

          <div className="modal-body  ">
            <p className="text-light">{message}</p>
          </div>
          <div className="modal-footer  d-flex justify-content-center p-0 mb-2 text-center">
            <button
              type="button"
              className=" btn-lg w-50  ok-button"
              onClick={onNav}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position-relative-img {
  position: relative;
}
.position-absolute-img-div {
  position: absolute;
  top: 100%;
  padding: 3px;
  background-color: var(--bg-colour-theme-51);
  /* width: 100%; */
  z-index: 1;
}

.li-img-div-style:hover {
  background-color: var(--bg-colour-theme-13);
}

.show-more-button-img-div:hover {
  color: var(--font-color-secondary);
  background-color: var(--bg-colour-theme-1);
}

.show-more-button-img-div {
  background-color: var(--bg-colour-theme-1);
  color: var(--font-color-secondary);
  border-radius: 5px;
  font-weight: 500;
}

.product_button_style {
  background: linear-gradient(var(--button-background-radial));
  color: var(--font-color-secondary);
  border: none;
  padding: 3px 14px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 4px 4px 0px var(--shadow-color-theme-3);
  box-shadow: 2px -18px 4.800000190734863px 0px var(--shadow-color-theme-4) inset;
  transition: background 0.3s ease;
  font-family: HarmonyOS Sans, sans-serif;
}

.product_button_style:hover {
  background: linear-gradient(var(--button-background-hover-radial));
}

@media (min-width: 768px) and (max-width: 992px) {
  .product_button_style {
    width: 35%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MerchantDashboard/CreateProductCarInfo/CreateProductCarInfo.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,2CAA2C;EAC3C,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,kCAAkC;EAClC,0CAA0C;AAC5C;;AAEA;EACE,0CAA0C;EAC1C,kCAAkC;EAClC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,4DAA4D;EAC5D,kCAAkC;EAClC,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,uDAAuD;EACvD,+EAA+E;EAC/E,gCAAgC;EAChC,uCAAuC;AACzC;;AAEA;EACE,kEAAkE;AACpE;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".position-relative-img {\n  position: relative;\n}\n.position-absolute-img-div {\n  position: absolute;\n  top: 100%;\n  padding: 3px;\n  background-color: var(--bg-colour-theme-51);\n  /* width: 100%; */\n  z-index: 1;\n}\n\n.li-img-div-style:hover {\n  background-color: var(--bg-colour-theme-13);\n}\n\n.show-more-button-img-div:hover {\n  color: var(--font-color-secondary);\n  background-color: var(--bg-colour-theme-1);\n}\n\n.show-more-button-img-div {\n  background-color: var(--bg-colour-theme-1);\n  color: var(--font-color-secondary);\n  border-radius: 5px;\n  font-weight: 500;\n}\n\n.product_button_style {\n  background: linear-gradient(var(--button-background-radial));\n  color: var(--font-color-secondary);\n  border: none;\n  padding: 3px 14px;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: bold;\n  box-shadow: 0px 4px 4px 0px var(--shadow-color-theme-3);\n  box-shadow: 2px -18px 4.800000190734863px 0px var(--shadow-color-theme-4) inset;\n  transition: background 0.3s ease;\n  font-family: HarmonyOS Sans, sans-serif;\n}\n\n.product_button_style:hover {\n  background: linear-gradient(var(--button-background-hover-radial));\n}\n\n@media (min-width: 768px) and (max-width: 992px) {\n  .product_button_style {\n    width: 35%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import { Dropdown, Pagination } from "react-bootstrap";
import { dashboard_vertical_three_dots } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse, Invoice } from "../../../types";
// import { PurchaseOrder } from "../../../types";

const AssignedOrderList = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");
  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };
  const decodedToken = decodeToken(accessToken);
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;
  const [currentPage, setCurrentPage] = useState(0);

  const fetchAssignedOrders = async (page: number) => {
    const response = await axiosInstance.get(
      `${API_URL.PURCHASE_ORDER}/${merchantId}?status=ORDER_ACCEPT&page=${page}`
    );
    return response.data;
  };
  const { data: assignedOrders } = useQuery<ApiResponse>({
    queryKey: ["assignedOrders", currentPage],
    queryFn: () => fetchAssignedOrders(currentPage),
    refetchInterval: 30000,
  });
  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  // const handleOrderDetails = (orderId: string) => {
  //   navigate("/merchant/Invoice/Details", { state: { orderId } });
  // };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="row">
      <div className="col-md-12 dashboard-table-div">
        <table className="table table-hover dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Order Number</th>
              <th>Number of Days</th>
              <th>Customer Name</th>
              <th>Product Name</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {assignedOrders?.content?.map((invoice: Invoice, index: number) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{invoice?.orderId}</td>
                <td>{invoice?.rental_days}</td>
                <td>{invoice?.customer_name}</td>
                <td>{invoice?.products[0]?.productName}</td>
                <td>
                  <span className="invoice-date-td">
                    {formatDate(invoice?.createdAt)}
                  </span>
                </td>

                <td>
                  <td>
                    <Link
                      className="trackOrderButton"
                      to="/merchant/TrackOrderDetails"
                    >
                      Track Order
                    </Link>
                  </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {assignedOrders && (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            />
            {Array.from({ length: assignedOrders.totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index === currentPage}
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === assignedOrders.totalPages - 1}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default AssignedOrderList;

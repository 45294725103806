import React from "react";
import Group1Logo from "./Group1.svg";
import cusLogo from "../assets/images/kirkos_logo.svg";
import carimg from "../assets/images/car_signup.svg";
import iconcus from "./iconcus.svg";
import "./LeftComponentStyle.css";

const CustomerInfo = () => {
  return (
    <div className="left-half">
      <div className="leftContainerStyle">
        <div className="yellowHalf">
          <p className="textStyle">
            Fill up the Info to Set Up
            <br /> your Dashboard and <br />
            Ease your Journey
            <br />
            <p className="subTextStyle">
              Get ready to hit the road with confidence. Your next generation
              adventure begins here
            </p>
          </p>

          <img
            src={carimg}
            alt="car image"
            style={{
              transform: "translateY(20px) translateX(0px)",
              width: "400px",
              minHeight: "10%",
            }}
          />
          <img src={cusLogo} alt="Logo" className="logoStyle" />
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;

import { configureStore } from "@reduxjs/toolkit";
import bookingDataSlice from "../Redux/BookingData/bookingDataSlice";
import rentalSummaryDataSlice from "./RentalSummaryData/rentalSummaryDataSlice";
import authSlice from "./Auth/authSlice";

const store = configureStore({
  reducer: {
    bookingValues: bookingDataSlice,
    rentalSummary: rentalSummaryDataSlice,
    authSlice: authSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import "../../LocationStatus/styles.css";
import "./styles.css";

import CustomInput from "../../CustomInput";
import { useForm } from "react-hook-form";
import rules from "../../../rules";
import CustomRadio from "../../customRadio";
import DatePicker from "react-datepicker";

import {
  calender_icon,
  manage_my_profile_security_logo,
  mastercard_logo,
  payment_option_logo,
  pencil_info_icon,
} from "../../../assets/images";

const CustomerSettings = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);

  const handleRadioChange = (value: string) => {
    setSelectedOption(value !== selectedOption ? value : "");
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  //   const {
  //     data: countriesData,
  //     isLoading,
  //     isError,
  //   } = useQuery<Country[], Error>({
  //     queryKey: ["countries"],
  //     queryFn: async () => {
  //       const response = await axiosInstance.get(API_URL.COUNTRY);
  //       return response.data;
  //     },
  //   });

  //   const handleCountryChange = async (e: any) => {
  //     const selectedCountryName = e.target.value;
  //     setCountry(selectedCountryName);

  //     const selectedCountry = countriesData?.find(
  //       (country) => country.countryName === selectedCountryName
  //     );

  //     if (selectedCountry) {
  //       setSelectedCountryId(selectedCountry.countryId.toString());

  //       const response = await axiosInstance.get(
  //         `${API_URL.STATE}${selectedCountry.countryId}`
  //       );
  //       setStateData(response.data);
  //     }
  //   };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <div className="row ">
      <div className="col-md-12 pt-4  ">
        <div className="row ">
          <div className="col-md-10 text-start pt-5">
            <div className="card cus_set_my_profile_box">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="pt-1">
                      <img
                        alt="manage_my_profile_security_logo"
                        src={manage_my_profile_security_logo}
                        width="23"
                      />
                    </div>
                    <div className="cus_set_my_profile_gen_info ps-2">
                      Security
                    </div>
                  </div>
                  <div className="col-md-6 d-flex  justify-content-md-end">
                    <div className="cus_set_my_profile_info_edit text-end">
                      Information Editable
                    </div>
                    <div className="ps-2">
                      <img
                        alt="pencil_info_icon"
                        src={pencil_info_icon}
                        width="18"
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-md-3">
                    <div className="cus_set_my_profile_input_text">
                      Email Address <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="email"
                      inputType="email"
                      className="merchant_login_input_field"
                      placeholder="Alex-j@gmail.com"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      control={control}
                      rules={rules.AuthRules.email}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="cus_set_my_profile_input_text">
                      Phone Number <span className="merchantStar">*</span>
                    </div>
                    <div className="inputContainerStyle">
                      <span className="new_country_code_style ">+971</span>
                      <CustomInput
                        inputName="phoneNo"
                        inputType="text"
                        className="merchant_login_input_field"
                        placeholder="5001 00110"
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        maxLength={9}
                        rules={rules.AuthRules.phone_number}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="cus_set_my_profile_input_text">
                      Password <span className="merchantStar">*</span>
                    </div>
                    <div className="d-flex">
                      <CustomInput
                        inputName="createpassword"
                        inputType={showPassword1 ? "text" : "password"}
                        className="merchant_login_input_field"
                        placeholder="xxxxxx"
                        editable={true}
                        onInputChange={(value: string) => setPassword(value)}
                        control={control}
                        rules={rules.AuthRules.password}
                      />
                      <span
                        className="fs-6 password_eye"
                        onClick={togglePasswordVisibility1}
                      >
                        {showPassword1 ? <EyeSlash /> : <Eye />}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="cus_set_my_profile_input_text">
                      Confirm Password <span className="merchantStar">*</span>
                    </div>
                    <div className="d-flex">
                      <CustomInput
                        inputName="confirmpassword"
                        inputType={showPassword2 ? "text" : "password"}
                        className="merchant_login_input_field"
                        placeholder="xxxxxx"
                        editable={true}
                        onInputChange={(value: string) =>
                          setConfirmPassword(value)
                        }
                        control={control}
                        rules={rules.AuthRules.bank_name}
                      />
                      <span
                        className="fs-6 password_eye"
                        onClick={togglePasswordVisibility2}
                      >
                        {showPassword2 ? <EyeSlash /> : <Eye />}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row float-end">
                  <div className="col-md-4 pt-5 pb-2">
                    <button
                      className=" btn all_button_style text-black"
                      // onClick={handleSubmit(onHandleSubmit)}
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-10 text-start pt-5">
            <div className="card cus_set_my_profile_box">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="pt-1">
                      <img
                        alt="payment_option_logo"
                        src={payment_option_logo}
                        width="23"
                      />
                    </div>
                    <div className="cus_set_my_profile_gen_info ps-2">
                      My Card Details
                    </div>
                  </div>
                  <div className="col-md-6 d-flex  justify-content-md-end">
                    <div className="cus_set_my_profile_info_edit text-end">
                      Information Editable
                    </div>
                    <div className="ps-2">
                      <img
                        alt="pencil_info_icon"
                        src={pencil_info_icon}
                        width="18"
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-md-3">
                    <div className="cus_set_my_profile_input_text">
                      Name on your Card <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="name"
                      inputType="email"
                      className="cus_set_my_profile_input_field pt-1"
                      placeholder="Alex-john"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      control={control}
                      // rules={rules.AuthRules.email}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="cus_set_my_profile_input_text">
                      Expiry <span className="merchantStar">*</span>
                    </div>
                    <div className=" date-pick text-start d-flex">
                      <DatePicker
                        id="date-time-picker"
                        className="date-time-picker-custom "
                        selected={expiryDate ? new Date(expiryDate) : null}
                        onChange={(date: Date) => {
                          if (date) {
                            setExpiryDate(date.toISOString());
                          } else {
                            setExpiryDate("");
                          }
                        }}
                        dateFormat="dd-MM-yyyy"
                        customInput={
                          <input className="day d-flex  ms-3 text-light date-style-lic " />
                        }
                        popperClassName="hide-calendar"
                        showPopperArrow={true}
                        minDate={new Date()}
                        autoComplete="off"
                      />
                      <div className=" float-end">
                        <img
                          src={calender_icon}
                          alt="icon"
                          className=" img-calender "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="cus_set_my_profile_input_text">
                      Card Number <span className="merchantStar">*</span>
                    </div>
                    <div className="input-container_card_image d-flex ">
                      <div className="card_image-container">
                        <img
                          src={mastercard_logo}
                          className="mastercard_settings_logo"
                          alt=""
                        />
                      </div>
                      <div>
                        <CustomInput
                          inputName="cardnumber"
                          inputType="password"
                          className="cus_set_my_profile_input_field pt-1"
                          placeholder="2222 3232 4234 3434"
                          editable={true}
                          onInputChange={(value: string) => setPassword(value)}
                          control={control}
                          // rules={rules.AuthRules.password}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="cus_set_my_profile_input_text">
                      CVV<span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="cvv"
                      inputType="password"
                      className="cus_set_my_profile_input_field pt-1"
                      placeholder="***"
                      editable={true}
                      onInputChange={(value: string) =>
                        setConfirmPassword(value)
                      }
                      control={control}
                      // rules={rules.AuthRules.bank_name}
                    />
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="d-flex">
                    <div>
                      <CustomRadio
                        onChange={() => handleRadioChange("existing email")}
                        checked={selectedOption === "existing email"}
                        label=""
                      />
                    </div>
                    <div className="cus_set_my_profile_input_text">
                      Send to the existing email (Alex-j@gmail.com)
                    </div>
                  </div>
                  <div className="cus_set_existing_email_subtext ps-5">
                    (deals purchased transactions deails send to this email)
                  </div>
                </div>
                <div className="row pt-4 ">
                  <div className="d-flex">
                    <div>
                      <button className="cus_set_add_another_email">+</button>
                    </div>
                    <div className="cus_set_my_profile_input_text pt-2 ps-2">
                      Add Another Email
                    </div>
                  </div>
                </div>

                <div className="row float-end">
                  <div className="col-md-4 pt-3 pb-2">
                    <button
                      className=" btn all_button_style text-black"
                      // onClick={handleSubmit(onHandleSubmit)}
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSettings;

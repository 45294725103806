import React from "react";
import "./styles.css"; // Make sure to create a corresponding CSS file for styling
import { vector } from "../../assets/images"; // Assuming you have an error icon

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpen,
  onClose,
  message,
}) => {
  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div className="modal-dialog p-2 mt-5" role="document">
        <div className="modal-content custom-modal error-modal">
          <div className="cross cross d-flex flex-row-reverse">
            <button
              type="button"
              className="close btn  rounded-circle"
              onClick={onClose}
            >
              <span>&times;</span>
            </button>
          </div>
          <div>
            <img
              src={vector}
              width="90"
              height="90"
              alt="Error Logo"
              className="error-icon"
            />
          </div>
          <div className="modal-header d-flex justify-content-center align-items-center p-0">
            <h5 className="modal-title">Error!</h5>
          </div>

          <div className="modal-body">
            <p className="text-danger">{message}</p>
          </div>
          <div className="modal-footer d-flex justify-content-center p-0 mb-2 text-center">
            <button
              type="button"
              className="btn-lg w-50 ok-button"
              onClick={onClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InsuranceDateComponent = ({ placeholder, value, onChange }: any) => {
  return (
    <div className="date-time-component-container">
      <div className="date-time-component-wrapper border-bottom-style">
        <div className="icon-input-container">
          <div className="text-start">
            <div className="div-date-time-picker">
              <DatePicker
                id="date-time-picker"
                selected={value}
                onChange={(date: Date) => onChange(date)}
                dateFormat="dd-MM-yyyy"
                customInput={
                  <input
                    readOnly
                    className="input-date"
                    placeholder={placeholder}
                  />
                }
                popperClassName="hide-calendar"
                showPopperArrow={true}
                autoComplete="off"
                minDate={new Date()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceDateComponent;

import { useState } from "react";
import { Bell } from "react-bootstrap-icons";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import {
  dashboard_search_normal,
  kirkos_merchant_profile,
} from "../../../assets/images";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CustomerDetails } from "../../../types";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { log_out } from "../../../services/axios.instance";
import { clearBookingValues } from "../../../Redux/BookingData/bookingDataSlice";
import { useDispatch } from "react-redux";

const CustomerDashBoardNavbar = () => {
  const dispatch = useDispatch();
  const [showProfile, setShowProfile] = useState(false);
  const [profileImage, setProfileImage] = useState<string>(
    kirkos_merchant_profile
  );

  const handleProfile = () => {
    setShowProfile(!showProfile);
  };

  const { data: customerData, error } = useQuery<CustomerDetails, Error>({
    queryKey: ["details"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.CUSTOMER_DETAILS);
      setProfileImage(response?.data?.customer_details?.profile_picture);
      return response.data;
    },
  });
  const handleSetActiveNavigationLogout = (nav: string) => {
    log_out();
    dispatch(clearBookingValues());
  };

  return (
    <div className="nav-bar-style">
      <Navbar
        expand="lg"
        className="nav-pills navbar-light nav-tab-style justify-content-end"
      >
        <Navbar.Collapse id="navbarText" className="right-side-nav ">
          <ul className="navbar-nav justify-content-end customer-nav-custom">
            <li className="nav-item nav-bar-li ">
              <Link
                to="/customer/bookingprocess"
                className="nav-link text-start customer-nav-yellow "
              >
                INSURANCE MEMBER
              </Link>
            </li>
            <li className="nav-item nav-bar-li">
              <a href="/#" className="nav-link customer-nav-link text-start">
                HOW IT WORKS
              </a>
            </li>
            <li className="nav-item nav-bar-li">
              <a href="/#" className="nav-link customer-nav-link text-start">
                DEAL OF THE DAY (SPECIAL OFFER)
              </a>
            </li>
          </ul>
        </Navbar.Collapse>
        <Nav>
          <div className="d-inline-flex p-2">
            <li className="nav-item nav-bar-li">
              <a href="/#" className="nav-link search-bar-bg p-1">
                <form className="d-flex ">
                  <label htmlFor="searchbox">
                    <img
                      alt="dashboard_search_normal"
                      src={dashboard_search_normal}
                      width="30"
                      height="30"
                    />
                  </label>
                  <input
                    id="searchbox"
                    type="text"
                    className="search-bar-input-box px-1"
                    placeholder="Search your car activity log"
                  />
                </form>
              </a>
            </li>
            <li
              className="nav-item px-2 nav-bar-li custom-d-none-navbar"
              style={{ width: 55 }}
            >
              <a className="nav-link search-bar-bg" href="/#">
                <Bell size={20} color="white" />
              </a>
            </li>
            <Dropdown
              show={showProfile}
              onToggle={handleProfile}
              style={{ width: 100 }}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="profile-hover pt-1 px-2"
              >
                <img
                  src={profileImage || kirkos_merchant_profile}
                  className="rounded-pill profile-logo"
                  alt=""
                />
                <span className="custom-d-none-navbar">&nbsp;Profile</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="display-drop-down">
                <Dropdown.Item as={Link} to="/customer/managemyprofile">
                  Manage My Profile
                </Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/Customer/Settings">
                  Settings
                </Dropdown.Item> */}
                {/* <Dropdown.Item as={Link} to="#">
                  My Rented Deals
                </Dropdown.Item> */}
                <Dropdown.Item
                  as={Link}
                  to="/"
                  onClick={() => handleSetActiveNavigationLogout("logout")}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Navbar.Toggle
              aria-controls="navbarText"
              className="nav-tog-btn float-right nav-bar-toggle-button"
            />
          </div>
        </Nav>
      </Navbar>
    </div>
  );
};

export default CustomerDashBoardNavbar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp_forgot_codetext {
  padding-top: 30px;
  font-family: Poppins, sans-serif;
}

.otp_forgot_icon_textt {
  margin-top: 10%;
}

.otp_forgot_textsign {
  font-size: 32px;
  margin-left: 4%;
  margin-top: 2%;
  letter-spacing: 1px;
  font-family: HarmonyOS Sans, sans-serif;
}

.otp_forgot_subtext {
  display: flex;
  margin-bottom: 60px;
  font-size: 15px;
  font-family: Poppins, sans-serif;
  color: var(--font-color-theme-1);
}

.otp_forgot_enter_text {
  font-size: 15px;
  font-family: Poppins, sans-serif;
}

.otp_forgot_container_style {
  display: flex;
  width: 50%;
}

.otp_forgot_resend_link {
  color: var(--font-color-theme-1);
  font-family: Poppins, sans-serif;
}

.otp_forgot_button_style {
  background: linear-gradient(var(--button-background-radial));
  color: var(--font-color-secondary);
  border: none;
  padding: 3px 14px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-family: HarmonyOS Sans, sans-serif;
  transition: background 0.3s ease;
}

.otp_forgot_button_style:hover {
  background: linear-gradient(var(--button-background-hover-radial));
}

@media (max-width: 769px) {
  .otp_forgot_textsign {
    font-size: 30px;
  }

  .otp_forgot_subtext {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Merchant/MerchantOtpViaForgot/Merchant_Otp_ViaForgot.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gCAAgC;EAChC,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,gCAAgC;EAChC,gCAAgC;AAClC;;AAEA;EACE,4DAA4D;EAC5D,kCAAkC;EAClC,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,uCAAuC;EACvC,gCAAgC;AAClC;;AAEA;EACE,kEAAkE;AACpE;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".otp_forgot_codetext {\n  padding-top: 30px;\n  font-family: Poppins, sans-serif;\n}\n\n.otp_forgot_icon_textt {\n  margin-top: 10%;\n}\n\n.otp_forgot_textsign {\n  font-size: 32px;\n  margin-left: 4%;\n  margin-top: 2%;\n  letter-spacing: 1px;\n  font-family: HarmonyOS Sans, sans-serif;\n}\n\n.otp_forgot_subtext {\n  display: flex;\n  margin-bottom: 60px;\n  font-size: 15px;\n  font-family: Poppins, sans-serif;\n  color: var(--font-color-theme-1);\n}\n\n.otp_forgot_enter_text {\n  font-size: 15px;\n  font-family: Poppins, sans-serif;\n}\n\n.otp_forgot_container_style {\n  display: flex;\n  width: 50%;\n}\n\n.otp_forgot_resend_link {\n  color: var(--font-color-theme-1);\n  font-family: Poppins, sans-serif;\n}\n\n.otp_forgot_button_style {\n  background: linear-gradient(var(--button-background-radial));\n  color: var(--font-color-secondary);\n  border: none;\n  padding: 3px 14px;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: 700;\n  font-family: HarmonyOS Sans, sans-serif;\n  transition: background 0.3s ease;\n}\n\n.otp_forgot_button_style:hover {\n  background: linear-gradient(var(--button-background-hover-radial));\n}\n\n@media (max-width: 769px) {\n  .otp_forgot_textsign {\n    font-size: 30px;\n  }\n\n  .otp_forgot_subtext {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import axios from "axios";
import { API_URL, baseUrl } from "./apiConfig";
import { decodeToken } from "../utils/jwtDecode";
import store from "../Redux/store";
import { logout } from "../Redux/Auth/authSlice";
import { useNavigate } from "react-router-dom";
const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    version: "v1",
  },
});

const unInterceptedAxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});

const onRequestSuccess = async (config: any) => {
  const updatedConfig = await authTokenSetting(config);
  var access_token = sessionStorage.getItem("access_token");
  if (access_token) {
    //Continue
  } else {
    const local_token = localStorage.getItem("access_token");
    if (local_token) {
      access_token = local_token;
    }
  }
  if (access_token) {
    console.log("access_token : " + access_token);
    var decoded = decodeToken(access_token);
    console.log("decoded : " + JSON.stringify(decoded));
    const isTokenExpired = Date.now() >= decoded.exp * 1000;

    if (isTokenExpired) {
      console.log("Token Expired : " + isTokenExpired);
      const refresh_token = await doRefreshToken();
      updatedConfig.headers.Authorization = `Bearer ${refresh_token}`;
    } else {
      updatedConfig.headers.Authorization = `Bearer ${access_token}`;
    }
  }

  return updatedConfig;
};

const doRefreshToken = async () => {
  console.log("Inside Refresh Token");
  const refresh_token = localStorage.getItem("refresh_token");
  const decoded = decodeToken(refresh_token);
  const isTokenExpired = Date.now() >= decoded.exp * 1000;
  if (isTokenExpired) {
    console.log("Refresh Token Expired : " + isTokenExpired);
    log_out();
    return null;
  } else {
    console.log("Refresh Token not Expired");
    const body = { refresh_token };
    try {
      const response: any = axiosInstance.post(
        baseUrl + API_URL.REFRESH_TOKEN,
        body
      );
      console.log("Refresh Token Response : " + JSON.stringify(response));
      if (
        response?.hasOwnProperty("data") &&
        response?.data.status === true &&
        response?.data.hasOwnProperty("access_token")
      ) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        return response.data.access_token;
      } else {
        log_out();
        return null;
      }
    } catch (err) {
      log_out();
      console.log("🚀 ~ file: client.ts:67 ~ doRefreshToken ~ err:", err);
    }
  }
};

const authTokenSetting = async (config: any) => {
  if (!config?.baseURL?.startsWith(baseUrl)) {
    return config;
  }
  if (!config.headers) {
    config.headers = {};
  }
  config.headers.language = "en_US";
  return config;
};

axiosInstance.interceptors.request.use(onRequestSuccess);

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    let customResponse = error?.response;
    if (error?.response) {
      console.log(
        "axios_intercept____",
        error?.response?.status,
        JSON.stringify(error?.response?.data),
        error?.response?.data?.message
      );
      return Promise.reject(customResponse);
    }
  }
);

export const log_out = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
  // store.dispatch(logout());
  window.location.replace("/");
  console.log("Logout : success");
};

export default axiosInstance;

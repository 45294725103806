import React from "react";
import "./MerchantDashboardReviews.css";
import {
  merchant_dashboard_review_arrow,
  merchant_dashboard_review_icon,
} from "../../../assets/images";
import { StarFill } from "react-bootstrap-icons";

const MerchantDashboardReviews = () => {
  return (
    <>
      <div className="col-md-12 text-end">
        <div className="merchant_dashboard_review_box p-3">
          <div className="col-md-12 text-end">
            {" "}
            <a className="merchant_dashboard_yellow_text_one">See all</a>
          </div>
          <div className="row ">
            <div className="col-xl-3 col-md-6  mb-3">
              <div className="d-flex">
                <div className="p-1">
                  <img
                    src={merchant_dashboard_review_icon}
                    width="30"
                    height="30"
                    alt="Logo"
                    className="img-fluid"
                  />
                </div>
                <div className="text-start pb-2">
                  <div className="merchant_dashboard_review_white_heading_text">
                    Reviews
                  </div>
                  <div className="merchant_dashboard_review_grey_text">
                    Reviews from Customers
                  </div>
                </div>
              </div>
              <div className="text-start ps-5">
                <img
                  src={merchant_dashboard_review_arrow}
                  width="180"
                  height="150"
                  alt="Logo"
                  className="img-fluid"
                />
              </div>
              {/* <div className=" d-flex justify-content-center "></div> */}
              <div className="avatars mb-5">
                <span className="avatar">
                  <img
                    src="https://picsum.photos/70"
                    width="40"
                    height="40"
                    className="img-fluid avatar_1"
                  />
                </span>
                <span className="avatar">
                  <img
                    src="https://picsum.photos/50"
                    width="40"
                    height="40"
                    className="img-fluid avatar_2"
                  />
                </span>
                <span className="avatar">
                  <img
                    src="https://picsum.photos/20"
                    width="40"
                    height="40"
                    className="img-fluid avatar_3 "
                  />
                </span>
                <span className="avatar">
                  <img
                    src="https://picsum.photos/100"
                    width="40"
                    height="40"
                    className="img-fluid avatar_4"
                  />
                </span>
                <span className="avatar_5 p-2">50</span>
              </div>
            </div>

            <div className="col-md-6 col-xl-3 p-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="row bubble-review bubble-bottom-left-review">
                    <div className="comment_review col-md-12 p-1 px-3">
                      Libero suscipit unde. Labore cumque voluptat accusamus
                      fuqui oluptates corporis dignissi
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 pt-2">
                <div className="d-flex">
                  <div className="pt-3">
                    <img
                      src={"https://picsum.photos/70"}
                      width="45"
                      height="45"
                      className="img-fluid avatar_new"
                    />
                  </div>
                  <div className="text-start ps-2 ">
                    <div className="merchant_dashboard_review_white_heading_text ">
                      John Alex
                    </div>
                    <div className="merchant_dashboard_review_grey_text ">
                      Dubai based Driver
                    </div>
                    <StarFill color={"#F0B64F"} size={17} />
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3 p-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="row bubble-review bubble-bottom-left-review">
                    <div className="comment_review col-md-12 p-1 px-3">
                      Libero suscipit unde. Labore cumque voluptat accusamus
                      fuqui oluptates corporis dignissi
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 pt-2">
                <div className="d-flex">
                  <div className="pt-3">
                    <img
                      src={"https://picsum.photos/50"}
                      width="45"
                      height="45"
                      className="img-fluid avatar_new"
                    />
                  </div>
                  <div className="text-start ps-2 ">
                    <div className="merchant_dashboard_review_white_heading_text ">
                      Thomas K
                    </div>
                    <div className="merchant_dashboard_review_grey_text ">
                      Dubai based Driver
                    </div>
                    <StarFill color={"#F0B64F"} size={17} />
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3 p-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="row bubble-review bubble-bottom-left-review">
                    <div className="comment_review col-md-12 p-1 px-3">
                      Libero suscipit unde. Labore cumque voluptat accusamus
                      fuqui oluptates corporis dignissi
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 pt-2">
                <div className="d-flex">
                  <div className="pt-3">
                    <img
                      src={"https://picsum.photos/20"}
                      width="45"
                      height="45"
                      className="img-fluid avatar_new"
                    />
                  </div>
                  <div className="text-start ps-2 ">
                    <div className="merchant_dashboard_review_white_heading_text ">
                      Arun S
                    </div>
                    <div className="merchant_dashboard_review_grey_text ">
                      Dubai based Driver
                    </div>
                    <StarFill color={"#F0B64F"} size={17} />
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                    <span className="ps-2">
                      <StarFill color={"#F0B64F"} size={17} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantDashboardReviews;

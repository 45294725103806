import React, { useEffect, useState } from "react";
import {
  dashboard_invoice_approve,
  dashboard_invoice_green_tick,
  dashboard_invoice_order_date,
  dashboard_invoice_order_history,
  dashboard_invoice_payment_done,
  dashboard_vertical_three_dots,
  dashboard_logo_merchant,
  calender_icon,
  info_details_sample_car,
  merchant_dashboard_location_icon,
} from "../../../assets/images";
import { MerchantInvoiceApprove } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { FieldValues } from "react-hook-form";
import ErrorModal from "../../ErrorModal";
import SuccessModal from "../../SuccessModal";
import { useLocation, useNavigate } from "react-router-dom";
import ImagePopupModal from "../ImagePopupModal";

const Merchantdepositsdetails = () => {
  return (
    <div className="row">
      <div className="col-md-12 d-flex justify-content-center pt-5 pb-2">
        <p className="info-text mt-1" id="license"></p>
        <div className="invoice-details-btn-div text-end">
          <button className="invoice-approve-btn">
            {" "}
            <img src={dashboard_invoice_approve} height={"14px"} alt="" />
            &nbsp;APPROVE
          </button>
          <button className="invoice-reject-btn">
            {" "}
            <span className="invoice-paid-failed-style"> &#10006;</span>
            &nbsp; REJECT
          </button>
        </div>
      </div>
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details">
          <div className="card-body px-5">
            <div className="row  pt-4 pb-3">
              <div className="col-md-6">
                <table className="invoice-table-width">
                  <tr className="invoice-table-tr-first">
                    <td className="invoice-table-td">Purchase order #</td>
                    <td>Invoice Date</td>
                  </tr>
                  <tr>
                    <td>DB 2024 CE 04</td>
                    <td>08/09/2024</td>
                  </tr>
                </table>
                <div className="col-md-12 pt-3">
                  <div className="invoice-div-head">Invoice #</div>
                  <div className="invoice-text-yellow">DB 6001 2024 </div>
                  <div className="col-md-6 pt-2 pb-2">
                    <img
                      src={info_details_sample_car}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="invoice-text-yellow">Honda-CRV</div>
                </div>
                <div className="pt-4">
                  <table className="invoice-table-width ">
                    <tr className="invoice-table-tr-first">
                      <td>Number of Penalty</td>
                      <td>Actual Deposit</td>
                      <td>Deducted Deposit</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>AED 800</td>
                      <td>AED 500</td>
                    </tr>
                  </table>
                </div>

                <div className="pt-4">
                  <table
                    className="invoice-table-width pt-5 "
                    style={{ borderCollapse: "collapse", width: "95%" }}
                  >
                    <tr className="invoice-table-tr-first">
                      <td>Reasons for Penalty </td>
                      <td>Penalty Amount</td>
                      <td>Date</td>
                      <td>Time</td>
                      <td></td>
                    </tr>
                    <tr style={{ height: "15px" }}></tr>
                    <tr style={{ borderBottom: "2px solid grey" }}>
                      <td>1.Traffic violation...</td>
                      <td>AED 200</td>
                      <td>11 01 2024</td>
                      <td>11:00 AM</td>
                      <td>
                        <img
                          src={dashboard_vertical_three_dots}
                          width="15"
                          height="15"
                        />
                      </td>
                    </tr>
                    <tr style={{ height: "15px" }}></tr>
                    <tr style={{ borderBottom: "2px solid grey" }}>
                      <td>2.Crossed speed limit...</td>
                      <td>AED 200</td>
                      <td>11 01 2024</td>
                      <td>11:00 AM</td>
                      <td>
                        <img
                          src={dashboard_vertical_three_dots}
                          width="15"
                          height="15"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-6 border-start-invoice-details">
                <div className="col-md-12 invoice-div-head">Customer Name</div>
                <div className="col-md-12 invoice-div-data">
                  Mohammad Khalid
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Customer email
                </div>
                <div className="col-md-12 invoice-div-data">
                  mk-dubai@gmail.com
                </div>

                <div className="col-md-12 pt-5">
                  <div className="card invoice-card-inner">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-1  text-center">
                          <img
                            src={dashboard_invoice_order_history}
                            height={"16px"}
                            alt=""
                          />
                        </div>
                        <div className="col invoice-text-yellow">
                          Order History
                        </div>
                      </div>
                      <div className="col-md-11 pt-3">
                        <div className="d-flex ">
                          <div className="col-1  text-center">
                            <img
                              src={dashboard_invoice_order_date}
                              height={"18px"}
                              alt=""
                            />
                          </div>
                          <div className="col">
                            <div className="invoice-div-head">
                              Purchase order Date
                            </div>
                            <div className="invoice-inner-card-text">
                              Invalid Date
                            </div>
                          </div>
                        </div>
                        <div className="d-flex ps-2">
                          <div className="col-1 text-center hor_line"></div>
                        </div>
                        <div className="d-flex pt-2">
                          <div className="col-1  text-center">
                            <img
                              src={dashboard_invoice_green_tick}
                              height={"18px"}
                              alt=""
                            />
                          </div>
                          <div className="col">
                            <div className=" invoice-div-head">Status</div>
                            <div className=" invoice-text-green">
                              Arrived & Handover Done
                            </div>
                          </div>
                        </div>
                        <div className="d-flex ps-2">
                          <div className="col-1 text-center hor_line"></div>
                        </div>
                        <div className="d-flex pt-2">
                          <div className="col-1  text-center">
                            <img
                              src={merchant_dashboard_location_icon}
                              height={"18px"}
                              alt=""
                            />
                          </div>
                          <div className="col">
                            <div className=" invoice-div-head">
                              Departure Date & Location
                            </div>
                            <div className="invoice-inner-card-text">
                              10 01 2024 | EZ 21, Dera, Dubai
                            </div>
                          </div>
                        </div>
                        <div className="d-flex ps-2">
                          <div className="col-1 text-center hor_line"></div>
                        </div>
                        <div className="d-flex pt-2">
                          <div className="col-1  text-center">
                            <img
                              src={merchant_dashboard_location_icon}
                              height={"18px"}
                              alt=""
                            />
                          </div>
                          <div className="col">
                            <div className=" invoice-div-head">
                              Return Date & Location
                            </div>
                            <div className="invoice-inner-card-text">
                              12 01 2024 | EZ 21, Dera, Dubai
                            </div>
                          </div>
                        </div>
                        <div className="d-flex ps-2">
                          <div className="col-1 text-center hor_line"></div>
                        </div>
                        <div className="d-flex pt-2">
                          <div className="col-1  text-center">
                            <img
                              src={dashboard_invoice_payment_done}
                              height={"18px"}
                              alt=""
                            />
                          </div>
                          <div className="col">
                            <div className=" invoice-text-green">
                              Payment Done (Completed)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchantdepositsdetails;

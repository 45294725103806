import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  car_sign_up,
  icon_black,
  icon_normal,
  kirkos_logo,
} from "../../assets/images";
import CustomInput from "../../components/CustomInput";

import { useForm } from "react-hook-form";
import rules from "../../rules";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "../../services/axios.instance";
import { API_URL } from "../../services/apiConfig";
import { UserData, VerifyOtp } from "../../types";
import "./Customer_Otp_ViaForgot.css";
import { useLocation, useNavigate } from "react-router-dom";

import SuccessModal from "../../components/SuccessModal";
import ErrorModal from "../../components/ErrorModal";
import { CustomerOnboardLeftSide } from "../../components";
const Customerotpviaforgot = () => {
  const [showOtpscreen, setShowOtpscreen] = useState<boolean>(false);
  const [otp, setOtp] = useState<any>(["", "", "", ""]);
  const [otpRefs] = useState<any>([useRef(), useRef(), useRef(), useRef()]);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
  const { state } = useLocation();
  const phoneNo = state && state.phoneNo;
  const transactionId = sessionStorage.getItem("transactionId");

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();
  const handleOtpChange = (index: number, e: any) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otpRefs.length - 1) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleOtpKeyDown = (index: number, e: any) => {
    if (e.key === "Backspace" && index > 0) {
      e.target.value = null;
      otpRefs[index - 1].current.focus();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });
  const handleOtpVerify = () => {
    const payLoad: VerifyOtp = {
      phoneNo: phoneNo,
      transactionId,
      otp: "1111",
      userType: "CONSUMER",
      event: "forgot_password",
    };
    verifyOtp.mutate(payLoad);
    console.log("transactionId", transactionId);
  };
  const verifyOtp = useMutation({
    mutationFn: (userData: VerifyOtp) =>
      axiosInstance.post(API_URL.VERIFY_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setIsOtpVerified(true);
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });
  const handleResendOtp = () => {
    const userData: UserData = {
      phoneNo: phoneNo,
      userType: "CONSUMER",
      event: "register",
    };

    resendOtp.mutate(userData);
  };

  const resendOtp = useMutation({
    mutationFn: (userData: UserData) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseResendOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });
  return (
    <div className="outer-container">
      <div className="container-fluid bg-black custom-container">
        {/* Main content row with two columns */}
        <div className="row onboard-garage-main ">
          {/* Left side with yellow color */}

          {/* Logo in the top-left corner of the yellow part */}
          <CustomerOnboardLeftSide />

          {/* Right side with black color */}
          <div className="col-md-6  text-light forgototp_rightbox ">
            <div className="text-start ps-4">
              <div className="customer_icon_forgot_otp d-flex text-start pt-3 ">
                <img
                  src={icon_normal}
                  className="icon-img img-fluid"
                  alt="Icon"
                />
                <p className=" customer_textsign_forgototp text-light mb-3">
                  OTP Verification
                </p>
              </div>
              <p className=" customer_subtextotp_forgot mt-2 text-warning ">
                We will send you a one time password on this phone number
                <br />
                number to +971 {phoneNo}
              </p>
              <p>OTP Default detected from your phone</p>
              <div className="col-5">
                <div className="customer_otpContainerStyle_forgot">
                  <input
                    type="text"
                    className="customer_forgot_otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(0, e)}
                    onKeyDown={(e) => handleOtpKeyDown(0, e)}
                    ref={otpRefs[0]}
                    autoComplete="off"
                  />
                  <input
                    type="text"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(1, e)}
                    onKeyDown={(e) => handleOtpKeyDown(1, e)}
                    ref={otpRefs[1]}
                  />
                  <input
                    type="text"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(2, e)}
                    onKeyDown={(e) => handleOtpKeyDown(2, e)}
                    ref={otpRefs[2]}
                  />
                  <input
                    type="text"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(3, e)}
                    onKeyDown={(e) => handleOtpKeyDown(3, e)}
                    ref={otpRefs[3]}
                  />
                </div>
              </div>

              <button
                className="btn all_button_style text-black mt-4"
                onClick={handleSubmit(handleOtpVerify)}
                disabled={!isValid}
              >
                VERIFY
              </button>
              <div className="d-flex customer_resend_forgototp">
                <p className="p">
                  Didn't receive the code?{" "}
                  <a
                    className="customer_forgototp_resend_link text-yellow"
                    onClick={handleResendOtp}
                  >
                    RESEND
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          if (verifyOtp.isSuccess) {
            navigate("/newpassword", {
              state: { phoneNo: phoneNo, transactionId: transactionId },
            });
          } else {
            setShowSuccessModal(false);
          }
        }}
        message={
          verifyOtp.isSuccess
            ? "Otp Verified Successfully"
            : "Otp Resent Successfully"
        }
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={
          verifyOtp.isError ? "Otp Verification Failed" : "Resent Otp Failed"
        }
      />
    </div>
  );
};

export default Customerotpviaforgot;

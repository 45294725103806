import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../MerchantSignUpFIrstPage/styles.css";
import "./Merchant_Otp_ViaForgot.css";
import {
  car_sign_up,
  icon_black,
  icon_normal,
  kirkos_logo,
  kirkos_merchant_arrow,
  kirkos_merchant_logo,
  kirkos_merchant_profile,
  kirkos_merchant_red_car,
  kirkos_merchant_white_car,
  kirkos_merchant_yellow_car,
} from "../../../assets/images";
import CustomInput from "../../../components/CustomInput";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { useForm } from "react-hook-form";
import rules from "../../../rules";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { UserData, MerchantOtp, MerchantEmail } from "../../../types";
import "../MerchantOtpPage.tsx/MerchantOtp.css";
import { useLocation, useNavigate } from "react-router-dom";

import SuccessModal from "../../../components/SuccessModal";
import ErrorModal from "../../../components/ErrorModal";
import { MerchantOnboardLeftSide } from "../../../components";
const MerchantOtpViaForgot = () => {
  const [showOtpscreen, setShowOtpscreen] = useState<boolean>(false);
  const [otp, setOtp] = useState<any>(["", "", "", ""]);
  const [otpRefs] = useState<any>([useRef(), useRef(), useRef(), useRef()]);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
  const { state } = useLocation();
  const email = state && state.email;
  console.log(email, "mailll");
  const transactionId = sessionStorage.getItem("transactionId");

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();
  const handleOtpChange = (index: number, e: any) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otpRefs.length - 1) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleOtpKeyDown = (index: number, e: any) => {
    if (e.key === "Backspace" && index > 0) {
      e.target.value = null;
      otpRefs[index - 1].current.focus();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  const handleOtpVerify = () => {
    const enteredOtp = otp.join("");
    const payLoad: MerchantOtp = {
      email: email,
      transactionId,
      otp: enteredOtp,
      userType: "MERCHANT",
      event: "forgot_password",
    };
    verifyOtp.mutate(payLoad);
    console.log("transactionId", transactionId);
  };
  const verifyOtp = useMutation({
    mutationFn: (userData: MerchantOtp) =>
      axiosInstance.post(API_URL.VERIFY_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setIsOtpVerified(true);
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        // alert(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const handleResendOtp = () => {
    const userData: MerchantEmail = {
      email: email,
      userType: "MERCHANT",
      event: "forgot_password",
    };

    resendOtp.mutate(userData);
  };

  const resendOtp = useMutation({
    mutationFn: (userData: MerchantEmail) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseResendOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });
  return (
    <div className="outer-container">
      <div className="container-fluid bg-black custom-container">
        <div className="row rounded onboard-garage-main ">
          <div className="col-md-6 custom_merchant_left_container_color">
            <MerchantOnboardLeftSide />
          </div>

          <div className="col-md-6 custom_right_container_color text-light custom-black pt-5 text-start">
            <div className="ps-4 pt-5">
              <div className="otp_forgot_icon_text d-flex pt-3 ">
                <img
                  src={kirkos_merchant_profile}
                  className="icon-img img-fluid"
                  alt="Icon"
                />
                <p className="otp_forgot_textsign text-light mb-3">
                  OTP Verification
                </p>
              </div>
              <p className=" otp_forgot_subtext mt-2 ">
                Please enter the OTP verification code from your email {email}
              </p>

              <p className=" mt-2 otp_forgot_enter_text text-white ">
                Enter the OTP number from your email {email}
              </p>

              <div className="col-md-12">
                <div className="otp_forgot_container_style">
                  <input
                    type="text"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(0, e)}
                    onKeyDown={(e) => handleOtpKeyDown(0, e)}
                    ref={otpRefs[0]}
                  />
                  <input
                    type="text"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(1, e)}
                    onKeyDown={(e) => handleOtpKeyDown(1, e)}
                    ref={otpRefs[1]}
                  />
                  <input
                    type="text"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(2, e)}
                    onKeyDown={(e) => handleOtpKeyDown(2, e)}
                    ref={otpRefs[2]}
                  />
                  <input
                    type="text"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(3, e)}
                    onKeyDown={(e) => handleOtpKeyDown(3, e)}
                    ref={otpRefs[3]}
                  />
                </div>
              </div>
              <div className="pt-3">
                <button
                  className="btn all_button_style text-black "
                  disabled={!isValid}
                  onClick={handleSubmit(handleOtpVerify)}
                >
                  VERIFY
                </button>
              </div>
              <div className="d-flex pb-3">
                <p className="otp_forgot_codetext">
                  Didn't receive the code?
                  <a
                    className="otp_forgot_resend_link ps-2"
                    onClick={handleResendOtp}
                  >
                    RESEND
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          if (verifyOtp.isSuccess) {
            navigate("/merchant/newpassword", { state: { email: email } });
          } else {
            setShowSuccessModal(false);
          }
        }}
        message={
          verifyOtp.isSuccess
            ? "Otp Verified Successfully"
            : "Otp Resent Successfully"
        }
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={
          verifyOtp.isError ? "Otp Verification Failed" : "Resent Otp Failed"
        }
      />
    </div>
  );
};

export default MerchantOtpViaForgot;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select {
  background-color: var(--bg-color-theme-18) !important;
  color: var(--font-colour-theme-45) !important;
  border: 1px solid var(--border-color-theme-3);
}

.custom-select option {
  background-color: var(--bg-color-theme-18) !important;
  color: var(--font-colour-theme-45) !important;
}

.custom-select:focus {
  border-color: white !important;
  box-shadow: none !important;
}

.search-bar {
  background-color: var(--bg-color-theme-18) !important;
  color: var(--font-colour-theme-45) !important;
  border: 1px solid var(--border-color-theme-3);
}

.search-bar::placeholder {
  color: var(--font-colour-theme-45) !important;
}

.search-bar:focus {
  border-color: white !important;
  box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/MerchantDashboard/RecollectedOrder/RecollectedOrder.css"],"names":[],"mappings":"AAAA;EACE,qDAAqD;EACrD,6CAA6C;EAC7C,6CAA6C;AAC/C;;AAEA;EACE,qDAAqD;EACrD,6CAA6C;AAC/C;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;AAC7B;;AAEA;EACE,qDAAqD;EACrD,6CAA6C;EAC7C,6CAA6C;AAC/C;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;AAC7B","sourcesContent":[".custom-select {\n  background-color: var(--bg-color-theme-18) !important;\n  color: var(--font-colour-theme-45) !important;\n  border: 1px solid var(--border-color-theme-3);\n}\n\n.custom-select option {\n  background-color: var(--bg-color-theme-18) !important;\n  color: var(--font-colour-theme-45) !important;\n}\n\n.custom-select:focus {\n  border-color: white !important;\n  box-shadow: none !important;\n}\n\n.search-bar {\n  background-color: var(--bg-color-theme-18) !important;\n  color: var(--font-colour-theme-45) !important;\n  border: 1px solid var(--border-color-theme-3);\n}\n\n.search-bar::placeholder {\n  color: var(--font-colour-theme-45) !important;\n}\n\n.search-bar:focus {\n  border-color: white !important;\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import {
  dashboard_calendar_yellow_icon,
  dashboard_car_yellow_icon,
  dashboard_customer_arrow,
  dashboard_location_yellow_icon,
} from "../../../assets/images";

const CustomerDashBoardHowItWorks = () => {
  return (
    <>
      <div className="row pt-3">
        <div className="col-md-12">
          <h2 className="heading-works">
            How It <span className="heading-works-sub">Works</span>
          </h2>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-12">
          <div className="card card-for-working">
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-md-2 pt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-md-8 mx-auto icon-div-bg px-3 pt-3 pb-3">
                        <img
                          src={dashboard_location_yellow_icon}
                          height={50}
                          alt=""
                          className="img-fluid icon-div-bg-size"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 card-for-working-head pt-2">
                      Choose Location
                    </div>
                    <div className="col-md-12 card-for-working-div">
                      Choose your location and find the best car
                    </div>
                  </div>
                </div>
                <div className="col-md-3 img-arrow-custom-div pt-3">
                  <img
                    alt="dashboard_customer_arrow"
                    src={dashboard_customer_arrow}
                    className="img-arrow-custom img-fluid"
                  />
                </div>
                <div className="col-md-2 pt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-md-8 mx-auto icon-div-bg px-3 pt-3 pb-3">
                        <img
                          src={dashboard_calendar_yellow_icon}
                          height={50}
                          alt=""
                          className="img-fluid icon-div-bg-size"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 card-for-working-head pt-2">
                      Pick Up Date
                    </div>
                    <div className="col-md-12 card-for-working-div">
                      Select your pickup date & time to book your car
                    </div>
                  </div>
                </div>
                <div className="col-md-3 img-arrow-custom-div pt-3">
                  <img
                    alt="dashboard_customer_arrow_2"
                    src={dashboard_customer_arrow}
                    className="img-arrow-custom img-fluid"
                  />
                </div>
                <div className="col-md-2 pt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-md-8 mx-auto icon-div-bg px-3 pt-3 pb-3">
                        <img
                          src={dashboard_car_yellow_icon}
                          height={50}
                          alt=""
                          className="img-fluid icon-div-bg-size"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 card-for-working-head pt-2">
                      Book Your Car
                    </div>
                    <div className="col-md-12 card-for-working-div">
                      Book your car and pickup from the location or we will
                      deliver you
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDashBoardHowItWorks;

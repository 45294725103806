import React, { useState } from "react";
import { Bell, Search } from "react-bootstrap-icons";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import {
  dashboard_search_normal,
  kirkos_merchant_profile,
} from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { log_out } from "../../../services/axios.instance";

const GarageNavBar: React.FC<{ currentPage: string }> = ({ currentPage }) => {
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();

  const handleProfile = () => {
    setShowProfile(!showProfile);
  };

  const handleSetActiveNavigationLogout = (nav: string) => {
    // log_out();
    // dispatch(clearBookingValues());
    navigate("/");
  };

  return (
    <div className="nav-bar-style">
      <Navbar expand="lg" className="nav-pills navbar-light nav-tab-style">
        <Navbar.Collapse>
          <div className="col-4 navheaderdiv">
            <Navbar.Brand href="#" className="navbar-brand-style">
              Garage
            </Navbar.Brand>
          </div>
        </Navbar.Collapse>
        <Nav className="right-side-nav justify-content-end">
          <div className="justify-content-end d-inline-flex">
            <li className="nav-item nav-bar-li">
              <a className="nav-link search-bar-bg p-1">
                <form className="d-flex">
                  <label htmlFor="searchbox">
                    <img src={dashboard_search_normal} width="30" height="30" />
                  </label>
                  <input
                    id="searchbox"
                    type="text"
                    className="search-bar-input-box px-1"
                    placeholder="Search your car activity log"
                  />
                </form>
              </a>
            </li>
            <li
              className="nav-item px-2 nav-bar-li custom-d-none-navbar"
              style={{ width: 55 }}
            >
              <a className="nav-link search-bar-bg" href="#">
                <Bell size={20} color="white" />
              </a>
            </li>
            <Dropdown
              show={showProfile}
              onToggle={handleProfile}
              style={{ width: 100 }}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="profile-hover pt-1 px-2"
              >
                <img
                  src={kirkos_merchant_profile}
                  className="rounded-pill profile-logo"
                  alt="Profile"
                />
                <span className="custom-d-none-navbar">&nbsp;Profile</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="display-drop-down">
                <Dropdown.Item>
                  <Link to="/garage/garageprofile"> Profile</Link>
                </Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/garageonboard"
                    onClick={() => handleSetActiveNavigationLogout("logout")}
                  >
                    Logout
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar>
    </div>
  );
};

export default GarageNavBar;

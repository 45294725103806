const RegexPattern: any = {
  Letters: /^[A-Za-z]*$/,
  LettersAndNumbers: /^[A-Za-z0-9]*$/,
  LettersAndNumbersAndSpaces: /^[A-Za-z0-9 ]*$/,
  LettersAndSpaces: /^[A-Za-z ]*$/,
  LettersAndSpacesAndDashes: /^[A-Za-z -]*$/,
  LettersAndSpacesAndDashesAndNumbers: /^[A-Za-z0-9\-&. ]*$/,
  LettersAndSpacesAndUNDERSCORE: /^[A-Za-z _]*$/,
  Numbers: /^[0-9]*$/,
  NumbersAndSpaces: /^[0-9 ]*$/,
  NumbersAndDot: /^[0-9.]*$/,
  NumbersAndSpacesAndDashes: /^[0-9 -]*$/,
  Email:
    /^(([^<>()[\]\\.+,;:\s@"']+(\.[^<>()[\]\\.,+;:\s@"']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  //Phone: /^5\d{8}$/,
  Phone: /^5\d{8}$/,

  URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  Date: /^\d{4}([./-])\d{2}\1\d{2}$/,

  IP: /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  // PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{6,99}$/,
  // PASSWORD: /^\d{6}$/,
  // PASSWORD: /^\d[0-9]$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/,

  //PASSWORD: /^(?=.*[1-6])\S{6,99}$/,
  IBAN: /^(AE|OM)[A-Za-z0-9]{21}$/,
  TRN: /^100[0-9]{12}$/,
  //REGISTRATION_NUMBER:/^[ABCDEH][0-9]{5}$/,
  REGISTRATION_NUMBER: /^(?:[ABCDEH][0-9]{5}|^\d{5}[A-Z]{3})$/,
  PRODUCT_NUMBER: /^[0-9.]*$/,

  ENGINE_NUMBER: /^\d{1}[A-Z]{2}\d{7}$/,
};

export default RegexPattern;

import React from "react";
import "./SuccessmodalStyle.css";
import { thumb_up } from "../../assets/images";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  onNav: () => void;
  password?: boolean;
  confirmPassword?: boolean;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  message,
  onNav,
  password = false,
  confirmPassword = false,
}) => {
  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div className="modal-dialog p-2 mt-5" role="document">
        <div className="modal-content    custom-modal2 ">
          {password ||
            (confirmPassword && (
              <div className="cross d-flex flex-row-reverse ">
                <button
                  type="button"
                  className="close  btn  rounded-circle "
                  onClick={onClose}
                >
                  <span>&times;</span>
                </button>
              </div>
            ))}
          <div>
            <img
              src={thumb_up}
              width="90"
              height="90"
              alt="Logo"
              className="thumb "
            />
          </div>
          <div className="modal-header  d-flex justify-content-center align-items-center p-0">
            <h5 className="modal-title ">Success!</h5>
          </div>

          <div className="modal-body  ">
            <p
              className="text-light"
              dangerouslySetInnerHTML={{ __html: message }}
            ></p>
          </div>
          <div className="modal-footer  d-flex justify-content-center p-0 mb-2 text-center">
            <button
              type="button"
              className=" btn-lg w-50  ok-button"
              onClick={onNav}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;

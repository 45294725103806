import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BookingValues {
  // selectedOption: string;
  // babySeatSelected: boolean;
  // additionalDriverSelected: boolean;
  // isCarRentalAllowed: boolean;
  // selectedOptions: string;
  bookingValues: Object;
}

const initialState: BookingValues = {
  // selectedOption: "",
  // babySeatSelected: false,
  // additionalDriverSelected: false,
  // isCarRentalAllowed: false,
  // selectedOptions: "",
  bookingValues: {},
};

const BookingDataSlice = createSlice({
  name: "bookingvalues",
  initialState,
  reducers: {
    setBookingValues(state, action: PayloadAction<Partial<BookingValues>>) {
      console.log("acttion_______", action.payload);

      return {
        ...state,
        bookingValues: action.payload,
      };
    },
    clearBookingValues(state) {
      return initialState; // Reset state to initial values
    },
  },
});

export const { setBookingValues, clearBookingValues } =
  BookingDataSlice.actions;

export default BookingDataSlice.reducer;

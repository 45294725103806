import React from "react";
import "./MerchantDashboardCarUpload.css";
import {
  dashboard_red_car,
  dashboard_yellow_car,
} from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";

const MerchantDashboardCarUpload = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/merchant/managecars/carinfo");
  };

  return (
    <div className="col-md-7">
      <div className="p-3 merchant_dashboard_car_upload_box_style shadow-sm rounded-5 text-white ">
        <div className="d-flex ">
          <button className="btn btn-warning me-2" onClick={handleSubmit}>
            +
          </button>
          <h4 className="car_upload_head_text">
            Add cars to your car listing/Upload
          </h4>
        </div>
        <div>
          <img src={dashboard_red_car}></img>
          <img src={dashboard_yellow_car}></img>
        </div>
        <div className="row text-start">
          <h3 className="car_upload_text">Click & Upload</h3>
        </div>
        <div className="row">
          <p className="col text-start car_upload_text">
            View all your listing{" "}
          </p>
          <Link
            to="/merchant/managecars/carinfo"
            className="col ps-5 car_upload_update_text"
          >
            Upload
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MerchantDashboardCarUpload;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WelcomePage.css */

.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.welcome-message {
  text-align: center;
  color: var(--font-colour-theme-29); /* Customize text color */
}

.car-image {
  max-width: 100%;
  width: 300px; /* Adjust to your desired width */
  height: 250px; /* Adjust to your desired height */
  margin-top: 20px; /* Adjust spacing as needed */
}
.image-container {
  width: 100px;
}
.buttonStyle2 {
  background: radial-gradient(--button-background-radial-1);
  color: var(--font-color-secondary);
  padding: 10px 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin: 2% 56% 1% 5%;
  margin-left: 2px;
  box-shadow: 0 4px 8px var(--shadow-color-theme-1),
    0 6px 20px var(--shadow-color-theme-2);
  transition: background 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/welcome.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kCAAkC,EAAE,yBAAyB;AAC/D;;AAEA;EACE,eAAe;EACf,YAAY,EAAE,iCAAiC;EAC/C,aAAa,EAAE,kCAAkC;EACjD,gBAAgB,EAAE,6BAA6B;AACjD;AACA;EACE,YAAY;AACd;AACA;EACE,yDAAyD;EACzD,kCAAkC;EAClC,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;EAChB;0CACwC;EACxC,gCAAgC;AAClC","sourcesContent":["/* WelcomePage.css */\n\n.welcome-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n\n.welcome-message {\n  text-align: center;\n  color: var(--font-colour-theme-29); /* Customize text color */\n}\n\n.car-image {\n  max-width: 100%;\n  width: 300px; /* Adjust to your desired width */\n  height: 250px; /* Adjust to your desired height */\n  margin-top: 20px; /* Adjust spacing as needed */\n}\n.image-container {\n  width: 100px;\n}\n.buttonStyle2 {\n  background: radial-gradient(--button-background-radial-1);\n  color: var(--font-color-secondary);\n  padding: 10px 40px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: bold;\n  margin: 2% 56% 1% 5%;\n  margin-left: 2px;\n  box-shadow: 0 4px 8px var(--shadow-color-theme-1),\n    0 6px 20px var(--shadow-color-theme-2);\n  transition: background 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

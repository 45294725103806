import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { renderToStaticMarkup } from 'react-dom/server';
// import { divIcon } from 'leaflet';
// import './leaf.scss';
 
interface MapTestProps {
  latitude: number;
  longitude: number;
}
 
const MapBox: React.FC<MapTestProps> = ({ latitude, longitude }) => {
  
  return (
   
    
      <div className="pt-4">
       
          <MapContainer center={[latitude, longitude]} zoom={13} style={{ width: '100%', height: '300px', borderRadius:'20px'}}>
            <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {/* <Marker position={[latitude, longitude]}  /> */}
          </MapContainer>
        
     </div>
    
    
  );
};
 
export default MapBox;
import React, { useEffect } from "react";
import "./orderdetails.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axios.instance";
import {
  dashboard_invoice_green_tick,
  manage_my_profile_incomplete_logo,
} from "../../../assets/images";

const GarageInsuranceOrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  //const OrderId = state && state.OrderId;
  console.log("new", OrderId);

  const {
    data: garageOrderDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["garageOrderDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.GARAGE_ORDER_DETAILS}/${OrderId}`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });

  useEffect(() => {
    if (garageOrderDetails) {
      console.log("Garage Order Details:", garageOrderDetails);
    }
  }, [garageOrderDetails]);

  //----------------------------------------------------------------------

  const mutation = useMutation({
    mutationFn: (status: string) =>
      axiosInstance.put(
        `${API_URL.GARAGE_STATUS_UPDATE}/${OrderId}`,
        {
          order_status: status,
        },
        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      ),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);

      navigate("/garage/insuranceorder");
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        //setErrorMessage(error?.data?.message);
        // setShowErrorModal(true);
      }
    },
  });

  const handleStatusChange = (status: string) => {
    console.log(`${status} button clicked`);
    mutation.mutate(status);
  };

  //--------------------------------------------------------------------------

  return (
    <div>
      <div className="col-12">
        <div className="row">
          <div className="invoice-details-btn-div text-end pt-4">
            {garageOrderDetails?.garage_status === "BOOKED" && (
              <>
                <button
                  className="btn btn-success button-order-details"
                  onClick={() => handleStatusChange("RECEIVED")}
                >
                  RECEIVED
                </button>
                &nbsp;
                <button
                  className="btn btn-info button-order-details"
                  onClick={() => handleStatusChange("WORKING")}
                >
                  WORKING
                </button>
                &nbsp;
                <button
                  className="btn btn-success button-order-details"
                  onClick={() => handleStatusChange("COMPLETED")}
                >
                  COMPLETED
                </button>
                &nbsp;
                <button
                  className="btn btn-danger button-order-details"
                  onClick={() => handleStatusChange("REJECTED")}
                >
                  REJECTED
                </button>
              </>
            )}
            {garageOrderDetails?.garage_status === "RECEIVED" && (
              <>
                <button
                  className="btn btn-info button-order-details"
                  onClick={() => handleStatusChange("WORKING")}
                >
                  WORKING
                </button>
                &nbsp;
                <button
                  className="btn btn-success button-order-details"
                  onClick={() => handleStatusChange("COMPLETED")}
                >
                  COMPLETED
                </button>
                &nbsp;
              </>
            )}
            {garageOrderDetails?.garage_status === "WORKING" && (
              <>
                <button
                  className="btn btn-success button-order-details"
                  onClick={() => handleStatusChange("COMPLETED")}
                >
                  COMPLETED
                </button>
                &nbsp;
              </>
            )}
          </div>
          <div className="col-md-12 text-start div-bg-invoice-details pt-3">
            <div className="card card-bg-invoice-details">
              <div className="card-body px-5">
                <div className="row  pt-4 pb-3">
                  <div className="col-12 pt-3">
                    <div className="invoice-text-yellow">Order Details</div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Customer Name</div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.customer_name}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">
                      Customer Phone Number
                    </div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.customer_details?.phoneNumber}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Customer Email</div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.customer_details?.email}
                    </div>
                  </div>

                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Insurance Claim</div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.insurance_claim}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Policynumber</div>
                    <div className="invoice-div-data">
                      {" "}
                      {garageOrderDetails?.insurance_policy}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Platenumber</div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.plate_number}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Chasis number</div>
                    <div className="invoice-div-data">
                      {" "}
                      {garageOrderDetails?.chassis_number}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head"></div>
                    <div className="invoice-div-data"></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-6">
                      <div className="col-md-6 pt-3">
                        <div className="invoice-div-head">Invoice No</div>
                        <div className="invoice-div-data">
                          {garageOrderDetails?.orderId}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card invoice-card-inner">
                        <div className="card-body">
                          {/* <div className="col-md-12">
                            <div className="invoice-div-head">Order Date</div>
                            <div className="invoice-div-data">#date</div>
                          </div> */}
                          <div className="d-flex pt-2">
                            <div className="col-1  text-center">
                              <img
                                src={dashboard_invoice_green_tick}
                                height={"18px"}
                                alt=""
                              />
                            </div>
                            <div className="col">
                              <div className=" invoice-div-head">Status</div>
                              <div className=" invoice-text-green">
                                Arrived & Handover Done
                              </div>
                            </div>
                          </div>
                          <div className="d-flex ps-2">
                            <div className="col-1 text-center hor_line"></div>
                          </div>
                          <div className="d-flex pt-2">
                            <div className="col-1  text-center">
                              <img
                                src={manage_my_profile_incomplete_logo}
                                height={"18px"}
                                alt=""
                              />
                            </div>
                            <div className="col">
                              <div className=" invoice-text-green">pending</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GarageInsuranceOrderDetails;

import React, { useEffect, useState } from "react";

import {
  dashboard_icon,
  dashboard_logout,
  sidebar_collapse_icon,
  dashboard_logo_kirkos_collapse,
  dashboard_settings,
  dashboard_notification,
  garage_booked_orders_icon,
  kirkos_logo_white_yellow,
} from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface GarageSideBarProps {
  currentPage: string;
  setCurrentPage: (page: string) => void;
}

const GarageSideBar: React.FC<GarageSideBarProps> = ({
  currentPage,
  setCurrentPage,
}) => {
  const [selectedNav, setSelectedNav] = useState("dashboard");
  const [selectedNavToggle, setSelectedNavToggle] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setSelectedNavToggle(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSetNavToggle = () => {
    setSelectedNavToggle(!selectedNavToggle);
  };

  const handleSetActiveNavigation = (nav: string) => {
    setSelectedNav(nav);
    setCurrentPage(nav);
  };
  const dispatch = useDispatch();
  const handleSetActiveNavigationLogout = (nav: string) => {
    // log_out();
    // dispatch(clearBookingValues());
    // navigate("/");
  };

  const dataNavTop = [
    {
      label: "Dashboard",
      icon: dashboard_icon,
      value: "dashboard",
      navlink: "/garage",
    },
    // {
    //   label: "Kirkos Commision",
    //   icon: garage_commission_icon,
    //   value: "Kirkoscommision",
    //   navlink: "/Garage",
    // },
    // {
    //   label: "Categories",
    //   icon: garage_category_icon,
    //   value: "categories",
    //   navlink: "/Garage",
    // },
    // {
    //   label: "Sub Categories",
    //   icon: garage_sub_category_icon,
    //   value: "subcategories",
    //   navlink: "/Garage",
    // },
    {
      label: "Notifications",
      icon: dashboard_notification,
      value: "notification",
      navlink: "/garage",
    },
    // {
    //   label: "Merchant Assigned Orders",
    //   icon: garage_assigned_orders_icon,
    //   value: "merchantassignedorders",
    //   navlink: "/Garage",
    // },
    {
      label: "Orders",
      icon: garage_booked_orders_icon,
      value: "bookedorders",
      navlink: "/garage/insuranceorder",
    },
    {
      label: "Settings",
      icon: dashboard_settings,
      value: "settings",
      navlink: "/garage",
    },
  ];

  return (
    <div
      className={` px-0 bg-black align-items-center sidebar-min-width ${
        selectedNavToggle
          ? "col-1"
          : "col-auto col-md-3 col-xl-2 responsive-sidebar-ab"
      }`}
    >
      <div className="sidebar-dash d-flex flex-column align-items-center  px-3 pt-2 text-white min-vh-100 ">
        <div className="  text-end toggle-sidebar-btn-div">
          <button className="toggle-sidebar-btn" onClick={handleSetNavToggle}>
            <img
              src={sidebar_collapse_icon}
              alt=""
              className="toggle-sidebar-btn-icon"
            />
          </button>
        </div>
        <div className="text-center mb-3 mt-2 div-logo-sidebar">
          <Link
            to="#"
            className={` align-items-center ${
              selectedNavToggle
                ? "sidebar-kirkos-icon-toggle"
                : "sidebar-kirkos-icon"
            }`}
          >
            <img
              src={
                selectedNavToggle
                  ? dashboard_logo_kirkos_collapse
                  : kirkos_logo_white_yellow
              }
              width="80%"
              height="80%"
              alt="Logo"
              className="img-fluid ps-2 img-logo-dashboard"
            />
          </Link>
        </div>
        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style pt-"
          id="side-bar-menu"
        >
          {dataNavTop.map((item) => (
            <li className="nav-item sidebar-list ">
              <div className="dashboard-side-bar-link-div">
                <Link
                  to={item.navlink}
                  onClick={() => handleSetActiveNavigation(item.value)}
                  className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                    selectedNav === item.value ? "active-class" : ""
                  }`}
                >
                  <i className="">
                    <img src={item.icon} width="15" height="15" />
                  </i>
                  <span
                    className={`ms-1  label-sidebar label-sidebar-customer ${
                      selectedNavToggle ? "d-none-custom" : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </div>
            </li>
          ))}
        </ul>

        <ul
          className="nav nav-tabs flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-tab-style"
          id="menu2"
        >
          <li className="nav-item p-1 sidebar-list">
            <div className="dashboard-side-bar-link-div">
              <Link
                to="/garageonboard"
                onClick={() => handleSetActiveNavigationLogout("logout")}
                className={`nav-link align-middle px-1 dashboard-side-bar-link  ${
                  selectedNav === "logout" ? "active-class" : ""
                }`}
              >
                <i className="">
                  <img src={dashboard_logout} width="15" height="15" />
                </i>
                <span
                  className={`ms-1  label-sidebar label-sidebar-customer ${
                    selectedNavToggle ? "d-none-custom" : ""
                  }`}
                >
                  Logout
                </span>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GarageSideBar;

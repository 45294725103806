//
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./merchantdashboard.css";
import { Gear, Plus, Trash, ThreeDots } from "react-bootstrap-icons";

import { kirkos_home } from "../../../assets/images";
import {
  MerchantDashboardSidebar,
  MerchantDashboardnavbar,
} from "../../../components";
import CreateProduct from "../../../components/MerchantDashboard/CreateProduct";
import { Outlet } from "react-router";
import MerchantDashboardFullComponents from "../../../components/MerchantDashboard/MerchantDashboardFullComponents";
import { notificationSound } from "../../../assets/sounds";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const MerchantDashboard = () => {
  const [currentPage, setCurrentPage] = useState("dashboard");
  const [notifications, setNotifications] = useState<string[]>([]);
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Example WebSocket connection setup
    const establishWebSocketConnection = (token: string) => {
      const socket = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/hello?authorization=${token}`
      );

      socket.onopen = () => {
        console.log("WebSocket connection established");
        socket.send(JSON.stringify({ event: "login", data: { token: token } }));
      };

      socket.onmessage = (event) => {
        console.log("Message from server", event.data);
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          event.data,
        ]);
        setNewNotificationsCount((prevCount) => prevCount + 1);
        playNotificationSound();
        showToastNotification(event.data);
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
      };

      socket.onerror = (error) => {
        console.log("WebSocket error", error);
      };
    };

    const token = localStorage.getItem("access_token");
    if (token) {
      establishWebSocketConnection(token);
    }

    // Clean up WebSocket connection on component unmount
    return () => {
      // Perform any necessary cleanup
    };
  }, []);
  //======================================================================

  const handleNotificationClick = (notification: string) => {
    let targetPath = "";

    if (notification.includes("new order")) {
      targetPath = "/merchant/invoice";
      // } else if (notification.includes("successfully updated ")) {
      //   targetPath = "/merchant/ApprovedOrders";
      // }
      // } else if (notification.includes("Product")) {
      //   targetPath = "/merchant/ProductList";
    } else {
      targetPath = "/merchant/approvedorders";
    }

    navigate(targetPath, { state: { notification } });

    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n !== notification)
    );
  };

  //===========================================================================

  // const playNotificationSound = () => {
  //   const audio = new Audio(notificationSound);
  //   audio.play();
  // };
  const playNotificationSound = () => {
    try {
      const audio = new Audio(notificationSound);
      audio
        .play()
        .then(() => {
          console.log("Notification sound played successfully");
        })
        .catch((error) => {
          console.error("Failed to play notification sound:", error);
        });
    } catch (error) {
      console.error("Error initializing audio:", error);
    }
  };

  const showToastNotification = (message: string) => {
    //toast(message, { autoClose: 3000 });
    toast(message);
  };
  return (
    <div className="col-md-12 col-xl-12 container-fluid dashboardcontainer  ">
      <div className="row flex-nowrap ">
        <MerchantDashboardSidebar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          //notifications={notifications}
          //newNotificationsCount={newNotificationsCount}
          ///setNewNotificationsCount={setNewNotificationsCount}
        />
        <div className="col dashboardright d-flex justify-content-center ">
          <div className="dashboardright-width">
            <MerchantDashboardnavbar
              currentPage={currentPage}
              notifications={notifications}
              handleNotificationClick={handleNotificationClick}
              newNotificationsCount={newNotificationsCount}
              setNewNotificationsCount={setNewNotificationsCount}
            />
            {/* <MerchantDashboardCarList/>
             */}

            <div className="dashboardright-scroll">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="custom-toast">
        <ToastContainer limit={1} />
      </div> */}
    </div>
  );
};

export default MerchantDashboard;

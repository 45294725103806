import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Form } from 'react-bootstrap';
import {
  kirkos_merchant_logo,
  kirkos_merchant_red_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_white_car,
  kirkos_merchant_profile,
  kirkos_merchant_arrow,
} from "../../../assets/images";
import {
  CustomInput,
  SuccessModal,
  ErrorModal,
  MerchantOnboardLeftSide,
} from "../../../components";
import { Form, useForm } from "react-hook-form";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { LoginData, MerchantEmail, UserData, VerifyOtp } from "../../../types";
import rules from "../../../rules";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
const GarageSignUpViaPhone = () => {
  //   const [email, setEmail] = useState<string>("")
  const [phoneNum, setPhoneNum] = useState<any>("");

  const [transactionId, setTransactionId] = useState<number | undefined>(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  //   const handleSignup = () => {
  //     const userData: MerchantEmail = {
  //       email,
  //       userType: "MERCHANT",
  //       event: "register"
  //     };
  //     handleLogin.mutate(userData);
  //   };

  const handleSignup = async (data: UserData) => {
    setPhoneNum(data.phoneNo);

    const userData = {
      phoneNo: data.phoneNo,
      userType: "GARAGE_USER",
      event: "register",
    };
    await mutation.mutateAsync(userData);
  };

  const mutation = useMutation({
    mutationFn: (userData: UserData) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse: any) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setTransactionId(successResponse?.data?.transactionId);
        sessionStorage.setItem(
          "transactionId",
          successResponse?.data?.transactionId
        );
      }
      if (successResponse?.data && successResponse?.data?.status === true) {
        setShowSuccessModal(true);
      } else {
        setErrorMessage(successResponse?.data?.message);
        setShowErrorModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  return (
    <>
      <div className="progressbar-custom">
        <MerchantProgressbar />
      </div>
      <div className="row text-start pt-5">
        <div className="row mt-4 pt-4 align-items-start">
          <div className="row">
            <div className="col-md-12 text-end">
              <a
                href="/"
                className="text-end"
                style={{
                  color: "#f4b652",
                  textDecoration: "underline",
                  fontFamily: "Poppins, sans-serif",
                  // fontStyle: "initial",
                  // display: "",
                }}
              >
                Home
              </a>
            </div>
          </div>
          <div className="col-md-9">
            <img
              src={kirkos_merchant_profile}
              className="icon-img img-fluid mb-3"
              alt="Icon"
            />
            <span className="text-light custom-font-signup">
              {" "}
              &nbsp;Garage Sign Up
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10">
            <p className="merchant_subtextsignx fw-light ">
              We will send you a one-time password to your phone number
            </p>
          </div>
        </div>

        <div className="row mt-5 ">
          <div className="  col-md-12 ">
            <label className="headingStylex">
              Enter Your Phone Number
              <span className="merchantStarx">*</span>
            </label>
            <div className="inputContainerStylex">
              <span className="">+971</span>
              <CustomInput
                inputName="phoneNo"
                inputType="text"
                className="inputStyle-viaphone ms-1"
                placeholder="enter your number"
                editable={true}
                onInputChange={(value: string) => {}}
                control={control}
                maxLength={9}
                rules={rules.AuthRules.phone_number}
              />
            </div>
          </div>
        </div>

        <div className="row CustomButtonx">
          <div className="col-3 btn-merchantButtonStylex">
            <button
              className="btn all_button_style text-black"
              onClick={handleSubmit(handleSignup)}
              disabled={!isValid}
            >
              CONTINUE
            </button>
          </div>
          <div className="TryBoxx col-9  d-md-flex mt-3">
            <p className="merchantBSubTextStylex">Try Another Way &nbsp;</p>
            <div className="Line1 d-md-flex">
              <div className="Arrow1">
                <img
                  src={kirkos_merchant_arrow}
                  alt="Logo"
                  className="merchantArrowx"
                />
                &nbsp;&nbsp;
              </div>
              <div className="OtpLinex">
                <Link
                  to="/garageonboard/email"
                  className="merchantOtpTextStylex"
                >
                  Get OTP via Email{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center TextPadx pt-4">
          <div className="  col-md-12   ">
            <p className="otp-textx fst-italic fw-light">
              You will receive an OTP sent to your phone number for
              verification. Please enter the verification code in the
              registration app.
            </p>
          </div>
        </div>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/garageonboard/otpviaphone", {
            state: { phoneNum, transactionId },
          });
        }}
        message="Otp Send Success"
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />
    </>
  );
};

export default GarageSignUpViaPhone;

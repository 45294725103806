import React, { useEffect, useState } from "react";
import {
  dashboard_invoice_approve,
  dashboard_invoice_green_tick,
  dashboard_invoice_order_date,
  dashboard_invoice_order_history,
  dashboard_invoice_payment_done,
  dashboard_logo_merchant,
  calender_icon,
  info_details_sample_car,
  merchant_dashboard_location_icon,
  pdf,
} from "../../../assets/images";
import { MerchantInvoiceApprove } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { FieldValues } from "react-hook-form";
import ErrorModal from "../../ErrorModal";
import SuccessModal from "../../SuccessModal";
import ConfirmationModal from "../../ConfirmationModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImagePopupModal from "../ImagePopupModal";
import { Modal, ModalHeader, ModalBody, Button } from "react-bootstrap";

const TrackOrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  console.log("new", OrderId);
  const {
    data: invoiceDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["invoiceDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.ORDER_DETAILS}/${OrderId}/details`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] =
    useState(false);
  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");
  const [rejectConfirmationMessage, setRejectConfirmationMessage] =
    useState("");

  const handleOpenConfirmationModal = (message: string) => {
    setAcceptConfirmationMessage(message);
    setShowAcceptConfirmationModal(true);
  };

  // const handleSubmit = () => {
  //   handleApproval.mutate();
  // };

  const handleSubmit = () => {
    handleOpenConfirmationModal("Are you sure you want to approve?");
  };

  const handleApproval = useMutation({
    mutationFn: () =>
      axiosInstance.put(
        `${API_URL.APPROVE_REJECT}/${OrderId}`,
        {
          order_status: "MERCHANT_ORDER_ACCEPT",
        },
        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      ),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);

      navigate("/merchant/invoice");
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  // const handleReject = () => {
  //   handleRejection.mutate();
  // };

  const handleOpenRejectConfirmationModal = (message: string) => {
    setRejectConfirmationMessage(message);
    setShowRejectConfirmationModal(true);
  };

  const handleReject = () => {
    handleOpenRejectConfirmationModal("Are you sure you want to reject?");
  };
  const handleAssign = () => {
    console.log("Assign button clicked");
    //navigate("/merchant/ApprovedDeliveryBoysList");
  };

  const handleRejection = useMutation({
    mutationFn: () =>
      axiosInstance.put(
        `${API_URL.APPROVE_REJECT}/${OrderId}`,
        {
          order_status: "MERCHANT_ORDER_REJECTED",
        },
        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      ),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);

      navigate("/merchant/invoice");
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const [showImagePopupModal, setShowImagePopupModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleimagepopover = () => {
    setShowImagePopupModal(true);
  };

  const handleClose = () => {
    setLoadModal(false);
  };

  const [loadModal, setLoadModal] = useState(false);
  const [imageSource, setImageSource] = useState("");

  return (
    <div className="row">
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details">
          <div className="card-body px-4">
            <div className="row  pt-4 pb-3">
              <div className="invoice-text-yellow">TRACK ORDER </div>
              <div className="col-md-12 mt-4">
                <table className="invoice-table-width">
                  <tr className="invoice-table-tr-first">
                    <td className="invoice-table-td">Purchase order #</td>
                    <td>Invoice Date</td>
                  </tr>
                  <tr>
                    <td>{invoiceDetails?.orderId}</td>
                    <td>{formatDate(invoiceDetails?.createdAt)}</td>
                  </tr>
                </table>
                <div className="row">
                  <div className="col-md-12 pt-3">
                    <div className="invoice-div-head">Invoice #</div>
                    <div className="invoice-text-yellow">DB 6001 2024 </div>
                    <div className="col-md-6 pt-2 pb-2">
                      <img
                        src={invoiceDetails?.products[0]?.productImageUrls}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="invoice-text-yellow">
                      {invoiceDetails?.products[0]?.productName}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className=" invoice-div-head pt-3">Number of Days</div>
                    <div className="invoice-div-data">
                      {invoiceDetails?.rental_days}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className=" invoice-div-head pt-3">License Number</div>
                    <div className=" invoice-div-data">
                      {invoiceDetails?.customer_details?.drivingLicenseNumber}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className=" invoice-div-head pt-3">
                      License Expiry Date
                    </div>
                    <div className=" invoice-div-data">
                      {invoiceDetails?.customer_details?.licenceExpiryDate}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="invoice-text-white">
                      Delivered Car Details
                    </div>
                    <div className="row">
                      <div className="col-md-12 invoice-div-head pt-3">
                        Location
                      </div>
                      <div className="col-md-12 invoice-div-data">
                        {" "}
                        {invoiceDetails?.delivery_address?.address}{" "}
                        {invoiceDetails?.delivery_address?.state},{" "}
                        {invoiceDetails?.delivery_address?.country}
                      </div>
                    </div>
                    <div className="row mt-4">
                      {console.log("invoiceDetails:", invoiceDetails)}
                      {/* {invoiceDetails?.customer_details?.customerDocs &&
                    invoiceDetails?.customer_details?.customerDocs
                  ?.EMIRITIES && (  */}
                      <div id="emirites_container" className="col-md-6 mb-2">
                        <p>Car Image: </p>

                        <img
                          id="EMIRITIES_IMAGE"
                          style={{ width: 75, height: 75 }}
                        />
                        <p id="EMIRITIES"></p>

                        <a
                          className="download-img"
                          id="EMIRITIESDownloadLink"
                          title="Download EMIRITIES PDF"
                          style={{ display: "none" }}
                        >
                          Download EMIRATES PDF
                        </a>
                      </div>
                      {/* )} */}
                      {/* {invoiceDetails?.customer_details?.customerDocs &&
                    invoiceDetails?.customer_details?.customerDocs?.LICENSE && ( */}
                      <div id="license_container" className="col-md-6 mb-2">
                        <p>CkeckList Image: </p>

                        <img
                          id="LICENSE_IMAGE"
                          style={{ width: 75, height: 75 }}
                        />
                        <p id="LICENSE"></p>
                        <a
                          className="download-img"
                          id="LICENSEDownloadLink"
                          style={{ display: "none" }}
                          title="Download LICENSE PDF"
                        >
                          Download LICENSE PDF
                        </a>
                      </div>
                      {/* )} */}
                      {invoiceDetails?.customer_details?.customerDocs &&
                        invoiceDetails?.customer_details?.customerDocs
                          ?.VisitVisa && (
                          <div
                            id="visitvisa_container"
                            className="col-md-6 mb-2"
                          >
                            <p>VISA: </p>

                            <img
                              id="VisitVisa_IMAGE"
                              style={{ width: 75, height: 75 }}
                            />
                            <p id="VisitVisa"></p>
                            <a
                              className="download-img"
                              id="VisitVisaDownloadLink"
                              style={{ display: "none" }}
                              title="Download VisitVisa PDF"
                            >
                              Download VISA PDF
                            </a>
                          </div>
                        )}
                      {invoiceDetails?.customer_details?.customerDocs &&
                        invoiceDetails?.customer_details?.customerDocs
                          ?.Passport && (
                          <div
                            id="passport_container"
                            className="col-md-6 mb-2"
                          >
                            <p>PASSPORT: </p>

                            <img
                              id="Passport_IMAGE"
                              style={{ width: 75, height: 75 }}
                            />
                            <p id="Passport"></p>
                            <a
                              className="download-img"
                              id="PassportDownloadLink"
                              title="Download Passport PDF"
                              style={{ display: "none" }}
                            >
                              Download PASSPORT PDF
                            </a>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 border-start-invoice-details">
                    <div className="invoice-text-white">
                      Recollected Car Details
                    </div>
                    <div
                      className="row "
                      style={{ height: "100%", alignItems: "center" }}
                    >
                      <div className="col-md-12">
                        <div className="card invoice-card-inner">
                          <div
                            className="card-body"
                            style={{ fontSize: "1.5rem", textAlign: "center" }}
                          >
                            <div className="invoice-text-yellow">
                              Not Recollected Yet!
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="Oops! Something went wrong"
      />

      <ImagePopupModal
        isOpen={showImagePopupModal}
        //  onClose={onCloseImagePopupModal}
        src={imageSource}
        onClose={() => setShowImagePopupModal(false)}
        message={""}
      />

      <ConfirmationModal
        isOpen={showAcceptConfirmationModal}
        onClose={() => setShowAcceptConfirmationModal(false)}
        onConfirm={() => {
          handleApproval.mutate();
          setShowAcceptConfirmationModal(false);
        }}
        message={acceptConfirmationMessage}
      />

      <ConfirmationModal
        isOpen={showRejectConfirmationModal}
        onClose={() => setShowRejectConfirmationModal(false)}
        onConfirm={() => {
          handleRejection.mutate();
          setShowRejectConfirmationModal(false);
        }}
        message={rejectConfirmationMessage}
      />
    </div>
  );
};

export default TrackOrderDetails;

import React from "react";
import "./MerchantDashboardCarLocationList.css";
import {
  active_car_logo,
  merchant_dashboard_location_icon,
  merchant_dashboard_running_in_route_icon,
} from "../../../assets/images";
import { ArrowsAngleExpand } from "react-bootstrap-icons";

const MerchantDashboardCarLocationList = () => {
  return (
    <>
      <div className="col-md-12">
        <div className="row p-3 ms-1 list_status_box_color shadow-sm rounded-5 text-white me-1">
          <div className="d-flex ps-3 ">
            <img
              src={active_car_logo}
              width="30"
              height="30"
              alt="Logo"
              className="img-fluid "
            />
            <h4 className="text-start ps-3 pt-2 merchant_dashboard_font">
              My Active Car Location List / Status
            </h4>
            <div className="col-md-7 text-end pe-3 ">
              <div>
                <ArrowsAngleExpand size={"12px"} />
              </div>

              <a className="merchant_dashboard_yellow_text_one">See all</a>
            </div>
          </div>

          <div className="row div-data-scroll">
            <div className="col-md-12 p-4 min-width-div-dash">
              <div className="row car_listing_row">
                <div className="col-2 text-start">
                  <div className="car_listing_text">
                    1 Honda <span className="list_status_grey_text">CR-V</span>
                  </div>
                  <div className="list_status_grey_text">
                    Reg No: H 98235 DUBAI
                  </div>
                </div>
                <div className="col-3 d-flex text-start">
                  <img
                    src={merchant_dashboard_location_icon}
                    width="20"
                    height="20"
                    alt="Logo"
                    className="img-fluid "
                  />
                  <div className="ps-1">
                    <div className="list_status_grey_text">
                      Departure Date & Location
                    </div>
                    <div className="car_listing_text">
                      10 Jan 2024 | EZ 21, Dera, Dubai
                    </div>
                  </div>
                </div>
                <div className="col-3 d-flex text-start">
                  <img
                    src={merchant_dashboard_location_icon}
                    width="20"
                    height="20"
                    alt="Logo"
                    className="img-fluid "
                  />
                  <div className="ps-1">
                    <div className="list_status_grey_text">
                      Return Date & Location
                    </div>
                    <div className="car_listing_text">
                      10 Jan 2024 | EZ 21, Dera, Dubai
                    </div>
                  </div>
                </div>
                <div className="col-2  list_status_green_text">
                  &#10004;Arrived & Handover Done
                </div>
                <div className="col-2 p-2">
                  <a className="merchant_dashboard_yellow_text_two">More</a>
                </div>
              </div>

              <div className="row car_listing_row ">
                <div className="col-2 text-start">
                  <div className="car_listing_text">
                    2 Peugeot{" "}
                    <span className="list_status_grey_text">5008</span>
                  </div>
                  <div className="list_status_grey_text">
                    Reg No: G 79232 DUBAI
                  </div>
                </div>
                <div className="col-3 d-flex text-start">
                  <img src={merchant_dashboard_location_icon} width="20" />
                  <div className="ps-1">
                    <div className="list_status_grey_text">
                      Departure Date & Location
                    </div>
                    <div className="car_listing_text">
                      11 Jan 2024 | EZ 21, Dera, Dubai
                    </div>
                  </div>
                </div>
                <div className="col-3 d-flex text-start">
                  <img src={merchant_dashboard_location_icon} width="20" />
                  <div className="ps-1">
                    <div className=" list_status_grey_text">
                      Return Date & Location
                    </div>
                    <div className="car_listing_text">
                      14 Jan 2024 | Need to be updated
                    </div>
                  </div>
                </div>
                <div className="col-2  list_status_green_text ps-4 d-flex">
                  <img
                    src={merchant_dashboard_running_in_route_icon}
                    width="20"
                  />
                  <div className="ps-2 list_status_second_green_text ">
                    Running In-Route
                  </div>
                </div>
                <div className="col-2 p-2">
                  <a className="merchant_dashboard_yellow_text_two">More</a>
                </div>
              </div>

              <div className="row car_listing_row ">
                <div className="col-2 text-start">
                  <div className="car_listing_text">
                    3 Mitsubishit{" "}
                    <span className="list_status_grey_text">Eclipse Cr...</span>
                  </div>
                  <div className="list_status_grey_text">
                    Reg No: H 83777 DUBAI
                  </div>
                </div>
                <div className="col-3 d-flex text-start">
                  <img src={merchant_dashboard_location_icon} width="20" />
                  <div className="ps-1">
                    <div className="list_status_grey_text">
                      Departure Date & Location
                    </div>
                    <div className="car_listing_text">
                      9 Jan 2024 | EZ 21, Dera, Dubai
                    </div>
                  </div>
                </div>
                <div className="col-3 d-flex text-start">
                  <img src={merchant_dashboard_location_icon} width="20" />
                  <div className="ps-1">
                    <div className="list_status_grey_text">
                      Return Date & Location
                    </div>
                    <div className="car_listing_text">
                      16 Jan 2024 | Need to be updated
                    </div>
                  </div>
                </div>
                <div className="col-2  list_status_green_text ps-4 d-flex">
                  <img
                    src={merchant_dashboard_running_in_route_icon}
                    width="20"
                  />
                  <div className="ps-2 list_status_second_green_text ">
                    Running In-Route
                  </div>
                </div>
                <div className="col-2 p-2">
                  <a className="merchant_dashboard_yellow_text_two">More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantDashboardCarLocationList;

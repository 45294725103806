import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

const initialState: any = {
  isLoggedIn: false,
};

const AuthSlice = createSlice({
  name: "authvalues",
  initialState,
  reducers: {
    logout(state: any) {
      return {
        ...state,
        isLoggedIn: false,
      };
    },
    loginSuccess(state: any) {
      return {
        ...state,
        isLoggedIn: true,
      };
    },
  },
});

export const { logout, loginSuccess } = AuthSlice.actions;

export default AuthSlice.reducer;

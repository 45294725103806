import React from "react";
import "./MerchantDashboardHireAndCancel.css";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart } from "chart.js";
import { dashboard_hire_and_cancel } from "../../../assets/images";
import { ArrowsAngleExpand } from "react-bootstrap-icons";

const MerchantDashboardHireAndCancel = () => {
  Chart.register(ArcElement);
  const data = {
    labels: ["Total Completed", "Total Canceled", "Total Pending "],
    datasets: [
      {
        label: "Attendance for Week 1",
        data: [35, 15, 10],
        borderColor: ["rgba(255,206,86,0.2)"],
        backgroundColor: ["#2DC24E", "#EA2D2D", "#FFCA00"],
        pointBackgroundColor: "rgba(255,206,86,0.2)",
      },
    ],
  };

  const options = {
    plugins: {},
  };

  return (
    <div className="col-md-5 ">
      <div className="row ms-1 me-2 row-height-hireandcancel">
        <div className="col-md-12">
          <div className="row pb-2 ps-3">
            <div className="col-md-12 p-2 pt-4">
              <table className="hireandcancel-table">
                <tr className="hireandcancel-f-row">
                  <td rowSpan={2}>
                    <div className="Dashboard_hireandcancel_logo mx-auto">
                      <img
                        src={dashboard_hire_and_cancel}
                        alt=""
                        className="ashboard_hireandcancel_logo"
                      />
                    </div>
                  </td>
                  <td>Hire & Cancel</td>
                  <td className="td-end-hireandcancel ">
                    <ArrowsAngleExpand
                      size={"12px"}
                      className="ashboard_hireandcancel_logo me-2"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="hireandcancel-today">Today</td>
                  <td className="hireandcancel-see-all">
                    <u>see all</u>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="row ps-3">
            <div className="col-md-12 text-center">
              <div className="row">
                <div className="col-md-6">
                  <Doughnut
                    data={data}
                    options={options}
                    className="doughnut-custom"
                  />
                </div>
                <div className="col-md-6">
                  <div className="row hireandcancel-legend px-2">
                    <table>
                      <tr>
                        <td>
                          <div className="legend-color-green mx-1" />
                        </td>
                        <td>Total Completed</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="legend-color-red mx-1" />
                        </td>
                        <td>Total Canceled</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="legend-color-yellow mx-1" />
                        </td>
                        <td>Total Pending</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantDashboardHireAndCancel;

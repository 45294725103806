import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import "./Pages/Css/variable.css";
import RouterComponent from "./routes";
import { ToastContainer, toast } from "react-toastify";
const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <RouterComponent />
        <div className="custom-toast">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
          />
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default App;

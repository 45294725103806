// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  display: none;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}
.modal .modal-header {
  border: none;
}
.modal .modal-footer {
  border: none;
}

.modal-title {
  font-size: 1.5em;
  color: var(--font-color-primary);
  margin: 0;
}

.modal.show {
  display: flex;
}

.modal-dialog {
  max-height: 60vh;
  border: none;
}

.modal-content {
  background: linear-gradient(0deg, var(--radial-background-theme-1)),
    linear-gradient(0deg, var(--radial-background-theme-2));
  padding: 20px;
  border-radius: 25px !important;
  text-align: center;
  color: var(--font-color-theme-3);
}
.custom-modal2 {
  border-radius: 60px;
  padding: 5px;
  text-align: center;
  box-shadow: rgba(244, 182, 82, 34%);
}

.close {
  cursor: pointer;
  font-weight: bold;
  font-size: 30px;
  color: var(--font-color-primary);
}

.modal-header {
  background-color: transparent;
  border: none;
}

.modal-body {
  border: none;
}
.modal-footer {
  background-color: transparent;
}

.ok-button {
  background: var(--bg-color-theme-3);
  height: 40px;
  border-radius: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SuccessModal/SuccessmodalStyle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;EAChC,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE;2DACyD;EACzD,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,gCAAgC;AAClC;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,YAAY;AACd;AACA;EACE,6BAA6B;AAC/B;;AAEA;EACE,mCAAmC;EACnC,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".modal {\n  display: none;\n  width: 100%;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.5);\n  justify-content: center;\n  align-items: center;\n}\n.modal .modal-header {\n  border: none;\n}\n.modal .modal-footer {\n  border: none;\n}\n\n.modal-title {\n  font-size: 1.5em;\n  color: var(--font-color-primary);\n  margin: 0;\n}\n\n.modal.show {\n  display: flex;\n}\n\n.modal-dialog {\n  max-height: 60vh;\n  border: none;\n}\n\n.modal-content {\n  background: linear-gradient(0deg, var(--radial-background-theme-1)),\n    linear-gradient(0deg, var(--radial-background-theme-2));\n  padding: 20px;\n  border-radius: 25px !important;\n  text-align: center;\n  color: var(--font-color-theme-3);\n}\n.custom-modal2 {\n  border-radius: 60px;\n  padding: 5px;\n  text-align: center;\n  box-shadow: rgba(244, 182, 82, 34%);\n}\n\n.close {\n  cursor: pointer;\n  font-weight: bold;\n  font-size: 30px;\n  color: var(--font-color-primary);\n}\n\n.modal-header {\n  background-color: transparent;\n  border: none;\n}\n\n.modal-body {\n  border: none;\n}\n.modal-footer {\n  background-color: transparent;\n}\n\n.ok-button {\n  background: var(--bg-color-theme-3);\n  height: 40px;\n  border-radius: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

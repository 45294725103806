import React from "react";
import iconcus from "../assets/images/icon_normal.svg";
import { useState } from "react";
import "./RightComponentStyle.css";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../services/axios.instance";
import { API_URL } from "../services/apiConfig";
import { RegistrationData, UserData } from "../types";
import CustomInput from "./CustomInput";
import { useForm } from "react-hook-form";
import rules from "../rules";
import RegistrationSuccessComponent from "./RegistrationSuccessComponent";

const CustomerSignUp = ({ phoneNo }: { phoneNo: string }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // New state variable
  const [error, setError] = useState<string | null>(null); // New state variable for error

  const handleInputChange = (name: string, value: string) => {
    switch (name) {
      case "password":
        setPassword(value);
        break;
      case "email":
        setEmail(value);
        break;

      default:
        break;
    }
  };

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setError(null);

    const userData = {
      password,
      confirmPassword,
      email,
      firstName,
      lastName,
      country,
      state,
      address,
    };
    handleRegistration.mutate({
      phone_no: phoneNo ?? "",
      password,
      user_type: "CONSUMER",
      email,
      firstname: firstName,
      lastname: lastName,
      last_level: 1,
      address,
      state,
      country,
    });
    console.log("dtatttttt", userData);
    setIsFormSubmitted(true);
    sessionStorage.setItem("isFormSubmitted", "true");
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: RegistrationData) =>
      axiosInstance.post(API_URL.REGISTER, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        alert(successResponse?.data?.message);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
      }
    },
  });

  return (
    <div>
      {isFormSubmitted ? (
        <RegistrationSuccessComponent />
      ) : (
        <>
          <div className="whiteHalf">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                marginTop: "60px",
              }}
            >
              <img
                src={iconcus}
                style={{
                  width: "51px",
                  minHeight: "10%",
                  marginRight: "15px",
                }}
              />
              <p className="signUpTextStyle">Customer Sign Up</p>
            </div>
            <p
              style={{
                color: "#F4B550",
              }}
            >
              Password Creation & Basic contact information with minimum <br />
              fields needs to be filled below
            </p>

            <div
              className="scrollableContainer"
              style={{ maxHeight: "100vh", overflowY: "auto" }}
            >
              <p className="headingStyle">Choose a Password</p>
              <div className="inputContainerStyle">
                <CustomInput
                  inputName="password"
                  inputType="password"
                  className="inputStyle"
                  placeholder="xxxxxx"
                  editable={true}
                  onInputChange={(value: string) =>
                    handleInputChange("password", value)
                  }
                  control={control}
                  rules={rules.AuthRules.password}
                />
              </div>

              <p className="headingStyle">Confirm Password</p>
              <div className="inputContainerStyle">
                <CustomInput
                  inputName="confirmpassword"
                  inputType="password"
                  className="customer_newpassword_inputfield "
                  placeholder="xxxxxx"
                  editable={true}
                  onInputChange={(value: string) => setConfirmPassword(value)}
                  control={control}
                  // rules={rules.AuthRules.password}
                />
                {error && (
                  <p
                    className="error-message"
                    style={{
                      color: "red",
                      fontSize: "10px",
                      textAlign: "end",
                    }}
                  >
                    {error}
                  </p>
                )}
              </div>

              <p className="headingStyle">Email Address</p>
              <div className="inputContainerStyle">
                <CustomInput
                  inputName="email"
                  inputType="email"
                  className="inputStyle"
                  placeholder="XXXXXX"
                  editable={true}
                  onInputChange={(value: string) =>
                    handleInputChange("email", value)
                  }
                  control={control}
                  rules={rules.AuthRules.email}
                />
              </div>

              <p className="headingStyle">First Name</p>
              <div className="inputContainerStyle">
                <input
                  type="text"
                  className="inputStyle "
                  placeholder="Abc"
                  contentEditable="true"
                  autoComplete="off"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <p className="headingStyle">Last Name</p>
              <div className="inputContainerStyle">
                <input
                  type="text"
                  className="inputStyle "
                  placeholder="Xyz"
                  contentEditable="true"
                  autoComplete="off"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="countryStateContainer">
                <div className="countryContainer">
                  <div className="inputWrapper">
                    <p className="headingStyle">Country</p>
                    <select
                      className="inputStyle countryInput"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Country
                      </option>
                      <option value="country1">Country 1</option>
                      <option value="country2">Country 2</option>
                    </select>
                  </div>
                </div>
                <div className="stateContainer">
                  <div className="inputWrapper">
                    <p className="headingStyle">State</p>
                    <select
                      className="inputStyle stateInput"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select State
                      </option>
                      <option value="state1">State 1</option>
                      <option value="state2">State 2</option>
                    </select>
                  </div>
                </div>
              </div>
              <p className="headingStyle">Contact Address</p>
              <div className="inputContainerStyle">
                <input
                  type="text"
                  className="inputStyle "
                  placeholder="MNO house ...."
                  contentEditable="true"
                  autoComplete="off"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>

              <div className="search-button-container">
                <button
                  className="search-button"
                  disabled={!isValid}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerSignUp;
